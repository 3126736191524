<template>
  <div v-bind="$attrs" class="container">
    <button v-for="(item, key) in model" :key="key" @click.stop="handleClick($event, key)" class="btn">
      {{ item[modelKey] }}
    </button>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    model: Array,
    modelKey: String,
  },
  methods: {
    handleClick(event, key) {
      this.$emit("click", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 50vh;
  overflow-y: scroll;
  text-align: center;
  margin: 10px 0;
  .link {
    display: inline-block;
    .btn {
      pointer-events: none;
    }
  }

  &.small {
    margin: 0;
    display: inline;
    .btn {
      display: inline;
      padding: 6px 8px;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    background-color: $colour-light-grey;
    padding: 12px 28px;
    font-size: 14px;
    color: var(--colour-primary);
    border: 0;
    font-weight: 400;
    width: 100%;
    border: 1px solid var(--colour-white);

    &:active,
    &:focus {
      background-color: lighten($colour-light-grey, 5%);
      outline: 0;
    }

    &.white {
      background-color: $colour-light-grey;
      color: $colour-grey;
    }
  }
}
</style>
