import HopService from "@/services/HopService.js";

export const mutations = {
  UPDATE_USER(state, payload) {
    if (payload != null) {
      state.user.displayName = payload.user.displayName;
      state.user.email = payload.user.email;
      state.user.photoURL = payload.user.photoURL;
    } else {
      state.user = {};
    }
  },
  UPDATE_PROPERTIES(state, payload) {
    if (payload.pagination.page > 1) {
      state.user.properties = state.user.properties.concat(payload.properties);
    } else {
      state.user.properties = payload.properties;
    }
    state.user.pagination = payload.pagination;
    if (payload.pagination.page >= payload.pagination.total_pages) {
      state.loadedAllProperties = true;
    }
  },
  UPDATE_USER_META(state, payload) {
    state.user.role = payload.role;
    state.user.accepted_tcs = payload.accepted_tcs;
    state.user.organisation = payload.organisation;
    state.user.displayName = payload.displayName;
  },
};

const defaultFilters = {
  deleted: false,
  page_size: 30,
};

export const actions = {
  updateUser({ commit }, payload) {
    commit("UPDATE_LOADING", true);
    commit("UPDATE_USER", payload);
    if (payload.token != "") {
      return new Promise((resolve, reject) => {
        // Default view can be set here
        HopService.getProperties(defaultFilters)
          .then(function (response) {
            commit("UPDATE_PROPERTIES", response.data);
            commit("UPDATE_USER_META", response.data.user);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_CONNECTION_PROBLEM", false);
            resolve(response);
          })
          .catch((error) => {
            // console.log("error", error);
            commit("UPDATE_LOADING", false);
            commit("UPDATE_CONNECTION_PROBLEM", true);
            reject(error);
          });
      });
    } else {
      commit("UPDATE_LOADING", false);
      return Promise.resolve();
    }
  },
  loadMoreProperties({ commit }) {
    commit("UPDATE_LOADING", true);
    return new Promise((resolve, reject) => {
      if (state.loadedAllProperties) {
        commit("UPDATE_LOADING", false);
        resolve();
        return;
      }
      const pagination = state.user.pagination;
      pagination.page += 1;
      HopService.getProperties({...defaultFilters, ...pagination})
        .then(function (response) {
          commit("UPDATE_PROPERTIES", response.data);
          commit("UPDATE_LOADING", false);
          commit("UPDATE_CONNECTION_PROBLEM", false);
          resolve(response);
        })
        .catch((error) => {
          // console.log("error", error);
          commit("UPDATE_LOADING", false);
          commit("UPDATE_CONNECTION_PROBLEM", true);
          reject(error);
        });
    });
  },
  addProperty({ commit }, payload) {
    commit("UPDATE_LOADING", true);

    return new Promise((resolve, reject) => {
      HopService.addProperty(payload.titleNumber, payload.requestId, payload.address, payload.parentId, payload.altoId)
        .then(function (response) {
          commit("UPDATE_PROPERTIES", response.data);
          commit("UPDATE_LOADING", false);
          commit("UPDATE_CONNECTION_PROBLEM", false);
          resolve(response);
        })
        .catch(function (error) {
          commit("UPDATE_LOADING", false);
          if (error.response) {
            if (error.response.data.message) {
              // A message was returned from the back end, so it's not a connection problem
              commit("UPDATE_CONNECTION_PROBLEM", true);
            }
          }
          reject(error);
        });
    });
  },
};

export const getters = {
  getUserRole(state) {
    // Used by permissions
    return state.user.role;
  },
  getLoadedAllProperties(state) {
    return state.loadedAllProperties;
  },
};

export const state = {
  user: {
    displayName: "",
    email: "",
    photoURL: "",
    properties: [],
    accepted_tcs: 0,
    role: -1,
    loadedAllProperties: false,
    pagination: {}
  },
};
