<template>
  <div>
    <h1>Invite solicitor</h1>
    <p class="note important">
      Please note: by adding a solicitor their firm will be able to view all of the documentation contained in HOP about
      <span class="propName">{{ property.name ? property.name.toLowerCase() : "" }}</span> and the seller. Please ensure that you
      have your client’s authority to proceed before allowing access, and check that the solicitor’s details inputted are correct.
    </p>
    <p class="note failure" v-if="property.solicitors_names && property.solicitors_names.length > 0">
      This property has already been made available to {{ property.solicitors_names }}, no more solicitors' firms can be added.
    </p>
    <div v-if="!property.solicitors_names">
      <p class="note">Choose a firm from the list below:</p>
      <BaseSelect
        ref="firmSelect"
        initial="Choose a solicitor's firm"
        :placeHolderGrey="false"
        class="select"
        :options="firms"
        :value="selectedFirm"
        @input="updateSelectedFirm($event)"
      />
      <div v-if="selectedFirm == -1">
        <p class="note">Please enter the name of the solicitor's firm below:</p>
        <BaseInput type="text" placeholder="Firm name" v-model="newSolicitorsFirmName" />
        <BaseButton @click="addSolicitorsFirm">Add firm</BaseButton>
      </div>
      <BaseSelect
        v-if="selectedFirm != 0 && selectedFirm != -1"
        ref="solicitorSelect"
        initial="Choose a solicitor"
        :placeHolderGrey="false"
        class="select"
        :options="solicitors"
        :value="selectedSolicitor"
        @input="updateSelectedSolicitor($event)"
      />
      <div v-if="selectedFirm != -1 && selectedSolicitor == -1">
        <p class="note">Please enter the solicitor's details below:</p>
        <BaseInput type="text" placeholder="Solicitor's name" v-model="newSolicitorsName" />
        <BaseInput type="email" placeholder="Solicitor's email" v-model="newSolicitorsEmail" />
        <BaseButton @click="addSolicitor">Add new solicitor</BaseButton>
      </div>
      <!-- {{ firms }} -->
      <!-- <BaseInput v-if="!showSuccess" type="email" class="input" placeholder="Email address" v-model="email" /> -->
      <div v-if="showSuccess" class="note success">{{ successMessage }}</div>
      <div v-if="showFailure" class="note failure">{{ failureMessage }}</div>
      <BaseButton
        v-if="!showSuccess && selectedSolicitor != 0 && selectedSolicitor != -1 && selectedFirm != -1"
        @click="sendInvite"
      >
        Send invite
      </BaseButton>
    </div>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to Control Panel </BaseButton>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";

export default {
  computed: {
    property() {
      return this.$store.state["property"].property;
    },
  },
  data() {
    return {
      email: "",
      newSolicitorsFirmName: "",
      newSolicitorsName: "",
      newSolicitorsEmail: "",
      firms: [],
      solicitors: [],
      selectedFirm: 0,
      selectedSolicitor: 0,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.getFirms();
  },
  methods: {
    addSolicitor() {
      HopService.addSolicitor(this.newSolicitorsName, this.newSolicitorsEmail, this.selectedFirm)
        .then((response) => {
          this.updateSelectedFirm(this.selectedFirm, response.data);
          this.showFailure = false;
          this.selectedSolicitor = 0;
          this.newSolicitorsName = "";
          this.newSolicitorsEmail = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to add solicitor.";
          this.$store.dispatch("updateLoading", false);
        });
    },
    addSolicitorsFirm() {
      HopService.addSolicitorsFirm(this.newSolicitorsFirmName)
        .then((response) => {
          this.$store.dispatch("updateLoading", false);
          this.getFirms(response.data);

          this.selectedSolicitor = 0;
          this.newSolicitorsFirmName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to add firm.";
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateSelectedSolicitor(solicitor) {
      this.selectedSolicitor = solicitor;
      this.$nextTick(function () {
        this.$refs.solicitorSelect.receiveUpdatedValue();
      });
    },
    updateSelectedFirm(firm, preselectedSolicitor = 0) {
      this.selectedFirm = firm;
      this.selectedSolicitor = 0;
      this.$nextTick(function () {
        this.$refs.firmSelect.receiveUpdatedValue();
      });

      if (firm != -1) {
        HopService.getSolicitorsByFirm(this.selectedFirm)
          .then((response) => {
            this.solicitors = [];
            response.data.forEach((element) => {
              this.solicitors.push({
                value: element.id,
                label: element.name || element.email,
              });
            });
            this.solicitors.push({
              value: -1,
              label: "The solicitor I want is not here",
            });
            if (preselectedSolicitor) {
              this.updateSelectedSolicitor(preselectedSolicitor);
            }
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.showSuccess = false;
            this.failureMessage = "Failed to fetch solicitors.";
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
    sendInvite() {
      this.$store.dispatch("updateLoading", true);
      HopService.inviteSolicitorToProperty(this.property.id, this.selectedSolicitor, this.$store.state["ui"].theme)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.successMessage = "Solicitor invited successfully";
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          this.showFailure = true;
          this.showSuccess = false;
          if (error.response.data.message) {
            this.failureMessage = error.response.data.message;
          } else {
            this.failureMessage =
              "Inviting solicitor failed. Our technical team has been informed, but if you continue to have problems, please get contact us.";
          }
          this.$store.dispatch("updateLoading", false);
        });
    },
    getFirms(preSelectedFirm = 0) {
      this.$store.dispatch("updateLoading", true);
      HopService.getSolicitorsFirms()
        .then((response) => {
          this.firms = [];
          response.data.forEach((element) => {
            this.firms.push({
              value: element.id,
              label: element.name,
            });
          });
          this.firms.push({
            value: -1,
            label: "The firm I want is not here",
          });
          if (preSelectedFirm) {
            this.updateSelectedFirm(preSelectedFirm);
          }
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch solicitors' firms.";
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped>
.propName {
  text-transform: capitalize;
}

.note {
  padding: 10px;
}
</style>
