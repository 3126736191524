<template>
  <div>
    <h1>hop Explainer</h1>
    <p>Text to come!</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  margin: 10px;
}
</style>
