<template>
  <div>
    <BaseModal ref="completeModal" @open="modalOpen = true" @close="modalOpen = false">
      <h1>Sign the form</h1>
      <br />
      <h3>Please enter your full name:</h3>
      <BaseInput type="text" class="input" placeholder="Your name" v-model="sellerName" />
      <br />
      <div class="hidden" :class="{ shown: sellerName.length > 1 }">
        <br />
        <h3>Now you can complete the form:</h3>
        <p>The date will be recorded as {{ currentDate }}</p>
        <br />
        <BaseButton @click="$emit('completeForm', sellerName)"> Sign </BaseButton>
        <BaseButton @click="hideModal($event)"> Cancel </BaseButton>
      </div>
    </BaseModal>
    <div :class="{ shifted: connection }" class="save-banner-content" v-if="canSign">
      <h1>Ready to sign?</h1>
      <p v-if="!formCompletion.some_signed">
        If you have finished, press 'Sign form'. Once you sign the form, you can't make any alterations and if you need to
        alter any of your answers, you'll need to let your estate agent or solicitor know directly.
      </p>
      <p v-if="formCompletion.some_signed">
        This form has been completed by another seller, and now requires your signature. Download the draft to check the answers,
        then complete the form. If you need to make any alterations to the form then contact your estate agent or solicitor.
      </p>
      <BaseButton
        @click="showModal($event)"
        v-if="complete"
        :small="true"
        :class="{ hidden: modalOpen }"
        :secondaryColour="true"
        >Sign form</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/utils";

// if (process.env.VUE_APP_THEME && process.env.VUE_APP_THEME == "lawsociety") {
//   import("@/styles/themes/" + process.env.VUE_APP_THEME + "/_save_prompt.scss");
// }

export default {
  props: {
    formCompletion: {
      type: Object,
    },
    isSeller: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    complete() {
      if (this.formCompletion) {
        return this.formCompletion.completion == 100;
      }
      return false;
    },
    touched() {
      return this.$store.state["ui"].touched;
    },
    connection() {
      return this.$store.state["ui"].connectionProblem;
    },
    currentDate() {
      return formatDate(new Date());
    },
    canSign() {
      return this.isSeller && this.complete && !this.formCompletion.signed;
    }
  },
  data() {
    return {
      sellerName: "",
      modalOpen: false,
    };
  },
  methods: {
    showModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs['completeModal'].open('completeModal');
    },
    hideModal(event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs['completeModal'].close('completeModal');
    },
  },
  mounted() {
    if (this.canSign) {
      this.showModal();
    }
  },
  watch: {
    formCompletion(newValue, oldValue) {
      if (oldValue !== null && oldValue.id) {
        // Only auto show modal if we are loading the form completion for the first time
        return;
      }
      if (this.canSign) {
        this.showModal();
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.save-banner,
.save-banner-content {
  &.shifted {
    bottom: 80.5px;
  }
}

.modal {
  .hidden {
    transition: opacity 0.6s;
  }
}

.hidden {
  opacity: 0;

  &.shown {
    opacity: 1;
  }
}

.save-banner-content {
  text-align: center;
  padding: 15px 10px 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  // background-color: rgba(70, 70, 70, 0.6);
  // background-color: rgba(var(--colour-primary), 0.9);
  background-color: #030303;
  border-top: 3px solid var(--colour-secondary);

  h1 {
    color: var(--colour-white);
    margin: 0 0 5px;
  }

  p {
    display: block;
    color: var(--colour-white);
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
  }

  .submit-options {
    border: 2px solidvar(--colour-primary);
  }

  :deep(.container) {
    display: inline-block;

    .btn {
      margin-right: 10px;
    }
  }
}
</style>
