<template>
  <div>
    <FormSection title="12. Charges" :complete="model.preliminary_information.progress[12]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">12.1</span>
              <span> {{ options.preliminary_information.questions["12_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="12_1"
              @input="updateModelLevel2('12_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['12_1']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['12_1']['yes'] == 1">
              <span>Select all which apply:</span>
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['12_1']['yes'] == 1">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['12_1']['items']"
                :options="options.preliminary_information.options['12_1']"
                @update:modelValue="updateModelLevel2('12_1', 'items', $event)"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['12_1']['yes'] != 1 }">
            <div v-for="(item, key) in model.preliminary_information.questions['12_1']['items']" :key="key">
              <p class="question-title compact">
                <span class="important">12.1.{{ key + 1 }}</span>
                Please give details about "{{ options.preliminary_information.options["12_1"][item] }}"
              </p>
              <div class="question-content">
                <BaseTextArea
                  rows="4"
                  :maxlength="5000"
                  placeholder="Details"
                  :modelValue="model.preliminary_information.questions['12_1_i'][item]['details']"
                  @update:modelValue="$emit('updateModelLevel3', '12_1_i', item, 'details', $event)"
                />
              </div>
              <p class="question-title">
                Please indicate the average annual cost for "{{ options.preliminary_information.options["12_1"][item] }}".
              </p>
              <div class="question-content">
                <BaseInput
                  :modelValue="model.preliminary_information.questions['12_1_i'][item]['cost']"
                  @update:modelValue="$emit('updateModelLevel3', '12_1_i', item, 'cost', $event)"
                  type="number"
                  contentBefore="£"
                  placeholder="0"
                />
              </div>
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import _find from "lodash/find";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
  data() {
    return {
      passport: this.$store.state["property"].property.data.passport,
      slideHiders: {
        "12_1": "",
      },
    };
  },
  methods: {
    updateModelLevel2(questionKey: string, propKey: string, val: string): void {
      this.$emit("updateModelLevel2", questionKey, propKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.question-title {
  &.compact {
    margin-bottom: 0;
  }
}
</style>
