<template>
  <div class="yes-no-question">
    <div class="yes-no-question-content">
      <BaseRadio
        v-for="(option, key) in options"
        :key="key"
        :label="option"
        :name="questionKey + '_radio'"
        :checked="model && model.yes == key"
        :value="model.yes"
        @input="updateModel('yes', key)"
      />
    </div>
    <BaseTextArea
      v-if="detailsTextArea && showInputs()"
      rows="6"
      placeholder="Enter details here"
      :modelValue="model['details']"
      @update:modelValue="updateModel('details', $event)"
    />
    <BaseInput
      v-if="detailsInput && showInputs()"
      :type="inputType"
      :modelValue="model[inputModel]"
      :placeholder="inputLabel"
      @update:modelValue="updateModel(inputModel, $event)"
    />
    <BaseSelect
      v-if="detailsYear && showInputs()"
      initial="Year"
      class="select"
      :options="yearsFrom1970"
      @input="updateModel('year', $event)"
      :value="model.year"
    />
  </div>
</template>

<script>
import OptionsService from "@/services/OptionsService";

export default {
  props: {
    model: Object,
    options: Object,
    questionKey: String,
    customShowCriteria: {
      type: Boolean,
      default: false,
    },
    showCriteria: {
      type: Boolean,
      default: false,
    },
    alwaysShowAllFields: {
      type: Boolean,
      default: false,
    },
    detailsYear: {
      type: Boolean,
      default: false,
    },
    detailsInput: {
      type: Boolean,
      default: false,
    },
    detailsTextArea: {
      type: Boolean,
      default: true,
    },
    inputModel: String,
    inputLabel: String,
    inputType: String,
  },
  methods: {
    updateModel(key, val) {
      this.$emit("input", {
        key: key,
        val: val,
      });
    },
    showInputs() {
      if (this.customShowCriteria) {
        return this.model.yes && this.model.yes != 0 && this.showCriteria;
      } else {
        return (this.model.yes && this.model.yes != 0 && this.model.yes != 2) || this.alwaysShowAllFields;
      }
    },
  },
  data() {
    return {
      yearsFrom1970: OptionsService.yearsFrom1970(),
    };
  },
};
</script>

<style lang="scss" scoped>
.yes-no-question {
  padding: 0 35px;
}

.yes-no-question-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  div {
    text-align: right;
    margin: 0;
  }
}
</style>
