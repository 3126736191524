<template>
  <div>
    <FormSection title="7. Potential liabilities" :complete="model.preliminary_information.progress[7]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">7.1</span>
              <span> {{ options.preliminary_information.questions["7_1"] }}</span>
            </p>
            <div class="question-content">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['7_1']['items']"
                :options="options.preliminary_information.options['7_1']"
                @update:modelValue="$emit('updateModelLevel2', '7_1', 'items', $event)"
              />
            </div>
            <div class="question-title" v-if="showFurtherDetails">
              Give details, eg a brief description and any relevant dates.
            </div>
            <div class="question-content" v-if="showFurtherDetails">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="Work carried out"
                :modelValue="model.preliminary_information.questions['7_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '7_1', 'details', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
  },
  computed: {
    showFurtherDetails(): boolean {
      if (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).model.preliminary_information.questions["7_1"]["items"].length == 1 &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).model.preliminary_information.questions["7_1"]["items"][0] == 4
      ) {
        return false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } else if ((this as any).model.preliminary_information.questions["7_1"]["items"].length > 0) {
        return true;
      }

      return false;
    },
  },
});
</script>
