<template>
  <div class="checkbox-input">
    <label v-for="(option, key) in answers" :key="key" :for="id + key" :class="{ checked: modelValue.includes(key) }">
      {{ option }}
      <input
        :id="id + key"
        type="checkbox"
        :value="modelValue.includes(key)"
        :checked="modelValue.includes(key)"
        @input.stop="mark(key, $event)"
      />
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import _uniqueId from "lodash/uniqueId";

export default defineComponent({
  props: {
    answers: Array,
    answer: Array as PropType<number[]>,
  },
  data() {
    return {
      modelValue: [] as number[],
      id: _uniqueId(),
    };
  },
  methods: {
    mark(key, event) {
      if (event.target.checked == true) {
        if (!this.modelValue.includes(key)) {
          this.modelValue.push(key);
        }
      } else {
        const index = this.modelValue.indexOf(key);
        if (index > -1) {
          this.modelValue.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
      this.$emit("update:modelValue", this.modelValue);
    },
  },
  mounted() {
    if (this.answer) {
      this.modelValue = this.answer;
    } else {
      this.modelValue = [];
    }
  },
});
</script>

<style lang="scss" scoped>
.checkbox-input {
  label {
    margin-top: 8px;
    display: block;
    color: $colour-grey;
    font-weight: 500;
    font-size: 15px;
    padding: 10px;
    background-color: var(--colour-white);

    &.checked {
      background-color: var(--colour-primary);
      color: var(--colour-white);
    }
  }

  input {
    position: relative;
    top: 2px;
    margin-left: 5px;
  }
}
</style>
