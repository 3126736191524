<template>
  <div>
    <FormSection title="21. Other information" :complete="model.preliminary_information.progress[21]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">21.1</span>
              <span> {{ options.preliminary_information.questions["21_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="21_1"
              @input="$emit('updateModelLevel2', '21_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['21_1']['yes']"
            />
            <p class="question-title">
              <span class="important">21.2</span>
              <span> {{ options.preliminary_information.questions["21_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="21_2"
              @input="$emit('updateModelLevel2', '21_2', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['21_2']['yes']"
            />
            <p class="question-title">
              <span class="important">21.3</span>
              <span> {{ options.preliminary_information.questions["21_3"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="21_3"
              @input="$emit('updateModelLevel2', '21_3', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['21_3']['yes']"
            />
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">21.4</span>
              <span> {{ options.preliminary_information.questions["21_4"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="21_4"
              @input="$emit('updateModelLevel2', '21_4', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['21_4']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['21_4']['yes'] == 1">Please give details.</p>
            <div class="question-content" v-if="model.preliminary_information.questions['21_4']['yes'] == 1">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="Details"
                :modelValue="model.preliminary_information.questions['21_4']['details']"
                @update:modelValue="$emit('updateModelLevel2', '21_4', 'details', $event)"
              />
            </div>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">21.5</span>
              <span> {{ options.preliminary_information.questions["21_5"] }}</span>
            </p>
            <p class="question-title">
              <span> {{ options.preliminary_information.questions["21_5_i"] }}</span>
            </p>
            <div class="question-content">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="Details"
                :modelValue="model.preliminary_information.questions['21_5']"
                @update:modelValue="$emit('updateModelLevel1', '21_5', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
});
</script>
