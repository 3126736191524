<template>
  <div class="textarea">
    <textarea v-bind="$attrs" :maxlength="maxlength" :value="modelValue" @input.stop="updateValue"></textarea>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: [String, Number],
    maxlength: {
      type: Number,
      default: 333,
    },
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  margin: 0 auto;

  textarea {
    width: 100%;
    margin: 10px auto;
    color: $colour-grey;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $colour-red;
    font-weight: bold;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $colour-red;
    font-weight: bold;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $colour-red;
    font-weight: bold;
  }
}
</style>
