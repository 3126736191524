<template>
  <div v-bind="$attrs" class="container">
    <div
      v-for="(item, key) in options"
      @click.prevent="updateModel(key)"
      :key="key"
      class="input"
      :class="{ active: modelValue.includes(key) }"
    >
      <label :for="key">
        {{ item }}
        <input v-bind="$attrs" :id="key" type="checkbox" :value="key" :checked="modelValue.includes(key)" />
        <svg xmlns="http://www.w3.org/2000/svg" class="checkbox" height="24" viewBox="0 0 24 24" width="24" v-if="!modelValue.includes(key)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="checkbox" height="24" viewBox="0 0 24 24" width="24" fill="#000000000000" v-if="modelValue.includes(key)" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    options: Array,
    modelValue: Array,
  },
  methods: {
    updateModel(key): void {
      let newModel = JSON.parse(JSON.stringify(this.modelValue));
      if (this.modelValue && this.modelValue.includes(key)) {
        const index = this.modelValue.indexOf(key);
        if (index > -1) {
          newModel.splice(index, 1);
        }
      } else {
        if (!this.modelValue) {
          newModel = [];
        }
        newModel.push(key);
      }
      this.$emit("update:modelValue", newModel);
    },
  },
});
</script>

<style lang="scss" scoped>
.input {
  margin: 8px 0 0;
  position: relative;
  background-color: var(--colour-white);
  color: $colour-grey;
  font-weight: 500;
  font-size: 15px;
  position: relative;

  &.active {
    background-color: var(--colour-primary);
    color: var(--colour-white);

    svg.checkbox {
      fill: var(--colour-white);
    }
  }

  label {
    padding: 10px 40px;
    display: inline-block;
    text-align: center;
    display: flexbox;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  svg.checkbox {
    fill: var(--colour-primary);
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translatey(-50%);
    pointer-events: none;
  }

  input {
    display: none;
  }
}
</style>
