<template>
  <div class="fixtures-fittings">
    <img src="@/assets/theme/lawsociety/law-society-provider-logo.png" class="ls-logo" alt="The Law Society" />
    <h1>Law Society Fixtures and Fittings form</h1>
    <p>This form is to make clear to the buyer which items are included in the sale.</p>
    <p class="important">
      It is important the form is completed accurately as it may form part of the contract between buyer and seller.
    </p>
    <p v-if="!formCompletion.signed && !formCompletion.some_signed">Your changes will be saved automatically as you go along.</p>
    <p v-if="!formCompletion.signed && !formCompletion.some_signed">
      HOP pre-fills as many of the answers to these questions as possible, but please check all answers are correct before
      completing the form. If there are other sellers they will also need to log in to sign the form. If they would like to review
      the answers then do not sign the form until they have had chance to check.
    </p>
    <BaseButton v-if="formCompletion.completion == 100" @click="downloadDraft"> Download draft </BaseButton>
    <p v-if="formCompletion.signed">
      You have signed this form, but some sellers have not. In the meantime you can download a draft copy of the completed form.
    </p>
    <BaseButton v-if="$store.state.user.user.role == 3" @click="fillIn">Fill in now</BaseButton>
    <div class="forms" v-if="model.questions && !formCompletion.signed && !formCompletion.some_signed">
      <FormSection title="Basic fittings" :complete="model.questions.basic.progress">
        <FittingsQuestions
          :questions="options.basic"
          :options="options.select_options.general"
          :model="model.questions.basic.questions"
          modelKey="basic"
          sectionTitle="basic fittings"
        />
      </FormSection>
      <FormSection title="Kitchen" :complete="model.questions.kitchen.progress">
        <FittingsQuestions
          :questions="options.kitchen"
          :options="options.select_options.kitchen_included"
          :model="model.questions.kitchen.questions"
          modelKey="kitchen"
        />
      </FormSection>
      <FormSection title="Bathroom" :complete="model.questions.bathroom.progress">
        <FittingsQuestions
          :questions="options.bathroom"
          :options="options.select_options.general"
          :model="model.questions.bathroom.questions"
          modelKey="bathroom"
        />
      </FormSection>
      <FormSection title="Carpets" :complete="model.questions.carpets.progress">
        <FittingsQuestions
          :questions="options.carpets"
          :options="options.select_options.general"
          :model="model.questions.carpets.questions"
          modelKey="carpets"
        />
      </FormSection>
      <FormSection title="Curtain rails / poles / pelmets" :complete="model.questions.curtain_rails_poles_pelmets.progress">
        <FittingsQuestions
          :questions="options.curtain_rails_poles_pelmets"
          :options="options.select_options.general"
          :model="model.questions.curtain_rails_poles_pelmets.questions"
          modelKey="curtain_rails_poles_pelmets"
          sectionTitle="curtain rails/poles/pelmets"
        />
      </FormSection>
      <FormSection title="Curtains/blinds" :complete="model.questions.curtains_blinds.progress">
        <FittingsQuestions
          :questions="options.curtains_blinds"
          :options="options.select_options.general"
          :model="model.questions.curtains_blinds.questions"
          modelKey="curtains_blinds"
          sectionTitle="curtains/blinds"
        />
      </FormSection>
      <FormSection title="Light fittings" :complete="model.questions.light_fittings.progress">
        <FittingsQuestions
          :questions="options.light_fittings"
          :options="options.select_options.general"
          :model="model.questions.light_fittings.questions"
          modelKey="light_fittings"
          sectionTitle="light fittings"
        />
      </FormSection>
      <FormSection title="Fitted units" :complete="model.questions.fitted_units.progress">
        <FittingsQuestions
          :questions="options.fitted_units"
          :options="options.select_options.general"
          :model="model.questions.fitted_units.questions"
          modelKey="fitted_units"
          sectionTitle="fitted units"
        />
      </FormSection>
      <FormSection title="Outdoor area" :complete="model.questions.outdoor_area.progress">
        <FittingsQuestions
          :questions="options.outdoor_area"
          :options="options.select_options.general"
          :model="model.questions.outdoor_area.questions"
          modelKey="outdoor_area"
          sectionTitle="outdoor area"
        />
      </FormSection>
      <FormSection title="Television and telephone" :complete="model.questions.television_telephone.progress">
        <FittingsQuestions
          :questions="options.television_telephone"
          :options="options.select_options.general"
          :model="model.questions.television_telephone.questions"
          modelKey="television_telephone"
          sectionTitle="television and telephone"
        />
      </FormSection>
      <FormSection title="Stock of fuel" :complete="model.questions.fuel.progress">
        <FittingsQuestions
          :questions="options.fuel"
          :options="options.select_options.general"
          :model="model.questions.fuel.questions"
          modelKey="fuel"
        />
      </FormSection>
      <FormSection title="Other" :complete="model.questions.other.progress">
        <FittingsQuestions
          :questions="options.other"
          :options="options.select_options.general"
          :model="model.questions.other.questions"
          modelKey="other"
        />
      </FormSection>
    </div>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to control panel </BaseButton>
    <CompletePrompt @completeForm="completeForm" :formCompletion="formCompletion" :taForm="true" :isSeller="isSeller" />
  </div>
</template>

<script>
import FormSection from "@/components/FormSection.vue";
import FittingsQuestions from "@/components/Fittings/FittingsQuestions.vue";
import CompletePrompt from "@/components/Forms/Questionnaires/CompletePrompt.vue";
import HopService from "@/services/HopService";
import ApiUtils from "@/services/ApiUtils";
import permissionsValues from "@/helpers/permissionsValues";
import Permissions from "@/helpers/permissions";

export default {
  components: {
    FormSection,
    FittingsQuestions,
    CompletePrompt,
  },
  data() {
    return {
      options: this.$store.state.options.options.fittings_contents,
      model: {},
      questionnaire: {},
    };
  },
  computed: {
    showFireworks() {
      if (this.model.questions) {
        return [
          this.model.questions.basic.progress == 100,
          this.model.questions.kitchen.progress == 100,
          this.model.questions.bathroom.progress == 100,
          this.model.questions.carpets.progress == 100,
          this.model.questions.curtain_rails_poles_pelmets.progress == 100,
          this.model.questions.curtains_blinds.progress == 100,
          this.model.questions.light_fittings.progress == 100,
          this.model.questions.fitted_units.progress == 100,
          this.model.questions.outdoor_area.progress == 100,
          this.model.questions.television_telephone.progress == 100,
          this.model.questions.fuel.progress == 100,
        ];
      }
      return null;
    },
    theme() {
      return this.$store.state["ui"].theme;
    },
    isSeller() {
      return this.$store.state["property"].property.is_seller;
    },
    formCompletion() {
      return this.$store.state["property"].property.questionnaire_completion &&
        this.$store.state["property"].property.questionnaire_completion[3]
        ? this.$store.state["property"].property.questionnaire_completion[3]
        : {
            accepted: false,
            completion: 0,
          };
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  watch: {
    showFireworks(newValue, oldValue) {
      if (newValue == null || oldValue == null) {
        return;
      }
      for (var i = 0; i < newValue.length; i++) {
        if (!oldValue[i] && newValue[i]) {
          this.$store.dispatch("showAlert", "Section complete!");
          break;
        }
      }
    },
  },
  methods: {
    downloadDraft() {
      HopService.getFittingsContentsReport(this.$store.state["property"].property.id).then((response) => {
        ApiUtils.openDownloadInNewTab(response, "Fittings and Contents");
      });
    },
    completeForm(name) {
      // (this.saveDataDebounced as any).cancel();
      this.$store.commit("UPDATE_BACKGROUND_LOADING", true);

      HopService.signQuestionnaire(this.$store.state["property"].property.questionnaire_completion[3].id, {
        name: name,
      })
        .then((response) => {
          this.$store.dispatch("refreshProperty").then(() => {
            this.$router.push({ name: "dashboard" });
            this.$store.dispatch("showAlert", "Successfully signed");
            this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
            this.$store.commit("UPDATE_CONNECTION_PROBLEM", false);
          });
        })
        .catch(() => {
          this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
          this.$store.commit("UPDATE_CONNECTION_PROBLEM", true);
        });
    },
    submit() {
      this.$store.dispatch("savePropertyData");
    },
    fillIn() {
      this.$store.dispatch("fillInFittingsContents");
    },
    updateModel(modelKey, questionKey, propKey, val) {
      if (this.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS)) {
        return;
      }

      this.$store.dispatch("updateFittingsContentsQuestion", {
        modelKey: modelKey,
        questionKey: questionKey,
        propKey: propKey,
        value: val,
      });
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id).then(() => {
      this.model = this.$store.state["property"].property.data.conveyancing_forms.fittings_contents;
    });
  },
};
</script>

<style lang="scss" scoped>
.fixtures-fittings {
  padding: $container-spacing-vertical 0 $container-spacing-vertical + 10;
}

h1,
p {
  padding: 0 $container-spacing-horizontal;
}

h1 {
  margin-bottom: 12px;
  margin-top: 0;
}

h3 {
  margin: 12px 0;
}

.important {
  margin: 12px 0;
  font-weight: bold;
}

.forms {
  margin: 30px 0;
}

.submit-status {
  color: $colour-red;
  text-align: center;
  margin: 10px 0 40px;
  font-size: 14px;
}

.question {
  background-color: $colour-light-grey;
  text-align: center;
  padding: 20px 30px;

  p.important {
    padding: 0 30px;
  }

  .question-title {
    text-align: left;
    padding: 0;
    margin-bottom: 10px;
    font-weight: bold;

    .important {
      color: var(--colour-primary);
    }
  }

  .count {
    color: var(--colour-primary);
    font-weight: 500;
  }
}
</style>
