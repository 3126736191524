<template>
  <div class="reports">
    <h1>
      <span v-if="hasNoPermissionTo(permissions.ACTION_VIEW_ALL_AGENTS)">{{ user.organisation.name }} </span>Individual Agents
    </h1>

    <table>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Agency/Brand</th>
        <th>Date registered</th>
        <th>Last activity</th>
      </tr>
      <tr
        v-for="agent in agents"
        :key="agent.id"
        :class="{
          brand: agent.role == permissions.USER_TYPE_BRAND,
          simple: agent.role == permissions.USER_TYPE_SIMPLE,
          admin: agent.role == permissions.USER_TYPE_ADMIN,
        }"
      >
        <td>{{ agent.name }}</td>
        <td>{{ agent.email }}</td>
        <td>{{ agent.organisation_name }}</td>
        <td>{{ agent.date_registered }}</td>
        <td>
          {{ agent.last_activity && new Date(agent.last_activity).getFullYear() != -1 ? new Date(agent.last_activity) : "never" }}
        </td>
      </tr>
    </table>

    <div class="note" v-if="hasPermissionTo(permissions.ACTION_VIEW_ALL_AGENTS)">
      Pink = Simple user (Simply Conveyancing)<br />
      Green = brand admin<br />
      Blue = top level admin
    </div>

    <div class="add">
      <BaseInput :small="true" type="Text" placeholder="Name" v-model="newName" />
      <BaseInput :small="true" type="Text" placeholder="Email" v-model="newEmail" />
      <BaseSelect
        initial="Agency"
        class="select"
        :options="agencies"
        :value="selectedAgency"
        @input="updateSelectedAgency($event)"
      />
      <BaseSelect
        v-if="hasPermissionTo(permissions.ACTION_CREATE_SIMPLE_AGENT)"
        initial="Agent type"
        class="select"
        :options="agentTypes"
        :value="selectedAgentType"
        @input="updateSelectedAgentType($event)"
      />
      <div v-if="showSuccess" class="note success">Added successfully.</div>
      <div v-if="showFailure" class="note failure">
        {{ failureMessage }}
      </div>
      <BaseButton @click="addAgent">Add new</BaseButton>
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    agentTypes() {
      var types = [
        {
          value: "2",
          label: "Standard agent",
        },
      ];
      if (this.hasPermissionTo(this.permissions.ACTION_CREATE_SIMPLE_AGENT)) {
        types.push({
          value: "20",
          label: "Simply Conveyancing User",
        });
      }
      return types;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
  },
  data() {
    return {
      newEmail: "",
      newName: "",
      agents: [],
      agencies: [],
      selectedAgency: 0,
      selectedAgentType: 2,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$store.dispatch("updateLoading", true);
      var ctx = this;

      HopService.getAgents()
        .then((response) => {
          this.agents = response.data;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch agencies";
          this.$store.dispatch("updateLoading", false);
        });

      // Get the agencies list
      HopService.getAgencies()
        .then((response) => {
          ctx.agencies = [];
          response.data.forEach((element) => {
            ctx.agencies.push({
              value: element.id,
              label: element.name,
            });
          });
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to fetch agencies";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateSelectedAgency(value) {
      this.selectedAgency = value;
    },
    updateSelectedAgentType(value) {
      this.selectedAgentType = value;
    },
    addAgent() {
      this.$store.dispatch("updateLoading", true);
      HopService.addAgent(this.newName, this.newEmail, this.selectedAgency, this.selectedAgentType)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.updateData();
          this.newEmail = "";
          this.newName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage =
            "Failed to add new agent. Make sure to enter a valid email address, and select an agency to assign the agent to.";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  table {
    tr {
      &.brand {
        background-color: $colour-light-green;
      }

      &.admin {
        background-color: $colour-light-blue;
      }

      &.simple {
        background-color: $colour-error-red-border;
      }
    }
  }
}
</style>
