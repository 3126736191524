<template>
    <SlickCarousel ref="slick" :options="slickOptions" class="slider" v-if="showContent">
      <slot></slot>
    </SlickCarousel>
</template>

<script>
import { defineComponent } from "vue";
import SlickCarousel from "@/components/SlickCarousel.vue";
export default defineComponent({
  components: {
    SlickCarousel,
  },
  mounted() {
    this.updateHiddenSlides();
  },
  data() {
    return {
      slickOptions: {
        // initialSlide: 8,
        slidesToShow: 1,
        arrows: this.arrows,
        infinite: this.infinite,
        speed: 300,
        swipe: this.swipe,
        adaptiveHeight: this.adaptiveHeight,
        draggable: false,
        prevArrow:
          '<button type="button" class="slick-prev"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="9.913px" height="16.897px" viewBox="0 0 9.913 16.897" enable-background="new 0 0 9.913 16.897" xml:space="preserve"><path id="Path_230" fill="#797687" stroke="#797687" stroke-width="1.5" d="M0.849,8.49c0-0.208,0.104-0.312,0.208-0.52l6.868-6.869c0.312-0.312,0.729-0.312,0.937,0c0.312,0.312,0.312,0.729,0,0.937l0,0L2.409,8.49l6.452,6.452c0.312,0.312,0.312,0.729,0,0.937c-0.312,0.312-0.729,0.312-0.937,0l0,0L1.057,9.01C0.952,8.906,0.849,8.698,0.849,8.49z"/></svg><span class="prevLabel">' +
          this.prevLabel +
          "</span></button>",
        nextArrow:
          '<button type="button" class="slick-next"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="9.913px" height="16.897px" viewBox="0 0 9.913 16.897" enable-background="new 0 0 9.913 16.897" xml:space="preserve"><path id="Path_230" fill="#797687" stroke="#797687" stroke-width="1.5" d="M0.849,8.49c0-0.208,0.104-0.312,0.208-0.52l6.868-6.869c0.312-0.312,0.729-0.312,0.937,0c0.312,0.312,0.312,0.729,0,0.937l0,0L2.409,8.49l6.452,6.452c0.312,0.312,0.312,0.729,0,0.937c-0.312,0.312-0.729,0.312-0.937,0l0,0L1.057,9.01C0.952,8.906,0.849,8.698,0.849,8.49z"/></svg><span class="nextLabel">' +
          this.nextLabel +
          "</span></button>",
      },
      showContent: true,
    };
  },
  props: {
    infinite: {
      type: Boolean,
      default: false,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    swipe: {
      type: Boolean,
      default: true,
    },
    adaptiveHeight: {
      type: Boolean,
      default: false,
    },
    nextLabel: {
      type: String,
      default: "",
    },
    prevLabel: {
      type: String,
      default: "",
    },
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    goTo(index) {
      this.$refs.slick.goTo(index);
    },
    updateSlot(updateFunction, context, nextSlide = true) {
      var prevSlide = this.$refs.slick.currentSlide();
      this.showContent = false;
      // this.$refs.slick.destroy();
      updateFunction(context);
      this.$nextTick(() => {
        this.showContent = true;
        this.$nextTick(() => {
          // var carousel = this.$refs.slick;
          this.slickOptions.initialSlide = prevSlide;
          this.goTo(prevSlide + (nextSlide ? 1 : 0));
          // this.$refs.slick.setPosition(prevSlide);
          // carousel.create();
        });
      });
    },
    updateHiddenSlides() {
      // Call this after properties which show/hide slides are changed
      this.$refs.slick.unfilter();
      this.$refs.slick.filter(function (index, element) {
        if (element.children[0].children[0] && element.children[0].children[0].classList.contains("slick-hide")) {
          return false;
        }
        return true;
      });
    },
  },
});
</script>

<style lang="scss" scoped>
:deep(.nextLabel),
:deep(.prevLabel) {
  position: relative;
  background-color: var(--colour-white);
  color: $colour-black;
  font-size: 14px;
}

:deep(.nextLabel) {
  right: 6px;
}

:deep(.prevLabel) {
  left: -2px;
}
:deep(.slick-disabled) {
  display: none;
}
</style>
