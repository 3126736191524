import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "questionnaire-form" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "toolbar" }
const _hoisted_7 = { class: "forms" }
const _hoisted_8 = {
  key: 0,
  class: "group"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "questions" }
const _hoisted_11 = {
  key: 0,
  class: "group"
}
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = {
  key: 0,
  class: "group-title"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { class: "toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Question = _resolveComponent("Question")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_CompletePrompt = _resolveComponent("CompletePrompt")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default"),
      (!_ctx.formCompletion?.signed && !_ctx.formCompletion?.some_signed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Your changes will be saved automatically as you go along. "))
        : _createCommentVNode("", true),
      (!_ctx.formCompletion?.signed && !_ctx.formCompletion?.some_signed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, " HOP pre-fills as many of the answers to these questions as possible, but please check all answers are correct before completing the form. If there are other sellers they will also need to log in to sign the form. If they would like to review the answers then do not sign the form until they have had chance to check. "))
        : _createCommentVNode("", true),
      (_ctx.formCompletion?.signed)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, " You have signed this form, but some sellers have not. In the meantime you can download a draft copy of the completed form. "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.draftAllowed)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            disabled: !!_ctx.canDownloadDraftMessage,
            onClick: _ctx.downloadDraft,
            tooltip: _ctx.canDownloadDraftMessage
          }, {
            default: _withCtx(() => [
              _createTextVNode("Download draft")
            ]),
            _: 1
          }, 8, ["disabled", "onClick", "tooltip"]))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseButton, {
        onClick: _ctx.jumpToNextQuestion,
        disabled: !!_ctx.showJumpToNextQuestionMessage,
        tooltip: _ctx.showJumpToNextQuestionMessage
      }, {
        default: _withCtx(() => [
          _createTextVNode("Next unanswered question")
        ]),
        _: 1
      }, 8, ["onClick", "disabled", "tooltip"]),
      _createVNode(_component_BaseButton, {
        disabled: !!_ctx.canSignFormMessage,
        onClick: _ctx.openCompleteModal,
        tooltip: _ctx.canSignFormMessage
      }, {
        default: _withCtx(() => [
          _createTextVNode("Sign form")
        ]),
        _: 1
      }, 8, ["disabled", "onClick", "tooltip"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionnaire?.sections, (section, key) => {
        return (_openBlock(), _createElementBlock("div", { key: key }, [
          _createVNode(_component_FormSection, {
            shown: _ctx.questionnaire.sections.length === 1,
            title: _ctx.sectionNumber(section, key) + ' ' + section.name,
            bottomBorder: true,
            complete: _ctx.completion[section.id],
            readonly: _ctx.isSectionReadOnly(section),
            ref_for: true,
            ref: 'section' + section.id
          }, {
            default: _withCtx(() => [
              (section.notes && section.notes.length <= 200)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: "section-notes",
                      innerHTML: section.notes
                    }, null, 8, _hoisted_9)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("form", _hoisted_10, [
                _createVNode(_component_Carousel, {
                  ref_for: true,
                  ref: 'carousel' + section.id
                }, {
                  default: _withCtx(() => [
                    (section.notes && section.notes.length > 200)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("div", {
                            class: "section-notes",
                            innerHTML: section.notes
                          }, null, 8, _hoisted_12)
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.groups, (group, groupKey) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["group", { 'slick-hide': _ctx.getIsGroupHidden(group, _ctx.answers) }]),
                        key: groupKey,
                        ref_for: true,
                        ref: 'group' + group.id
                      }, [
                        (group.name)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(group.name), 1))
                          : _createCommentVNode("", true),
                        (group.notes)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                              _createElementVNode("span", {
                                innerHTML: group.notes,
                                class: "group-notes"
                              }, null, 8, _hoisted_15)
                            ]))
                          : _createCommentVNode("", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.questions, (question, questionKey) => {
                          return (_openBlock(), _createBlock(_component_Question, {
                            propertyId: _ctx.propertyId,
                            class: _normalizeClass({ 'slick-hide': _ctx.getIsQuestionHidden(question, _ctx.answers) }),
                            key: questionKey,
                            answer: _ctx.answers[question.id],
                            question: question,
                            onInput: ($event: any) => (_ctx.answerQuestion(question.id, $event, 'carousel' + section.id))
                          }, null, 8, ["propertyId", "class", "answer", "question", "onInput"]))
                        }), 128))
                      ], 2))
                    }), 128))
                  ]),
                  _: 2
                }, 1536)
              ])
            ]),
            _: 2
          }, 1032, ["shown", "title", "complete", "readonly"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_16, [
      (_ctx.draftAllowed)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            disabled: !!_ctx.canDownloadDraftMessage,
            onClick: _ctx.downloadDraft,
            tooltip: _ctx.canDownloadDraftMessage
          }, {
            default: _withCtx(() => [
              _createTextVNode("Download draft")
            ]),
            _: 1
          }, 8, ["disabled", "onClick", "tooltip"]))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseButton, {
        onClick: _ctx.jumpToNextQuestion,
        disabled: !!_ctx.showJumpToNextQuestionMessage,
        tooltip: _ctx.showJumpToNextQuestionMessage
      }, {
        default: _withCtx(() => [
          _createTextVNode("Next unanswered question")
        ]),
        _: 1
      }, 8, ["onClick", "disabled", "tooltip"]),
      _createVNode(_component_BaseButton, {
        disabled: !!_ctx.canSignFormMessage,
        onClick: _ctx.openCompleteModal,
        tooltip: _ctx.canSignFormMessage
      }, {
        default: _withCtx(() => [
          _createTextVNode("Sign form")
        ]),
        _: 1
      }, 8, ["disabled", "onClick", "tooltip"])
    ]),
    _createVNode(_component_CompletePrompt, {
      onCompleteForm: _ctx.completeForm,
      formCompletion: _ctx.formCompletion,
      taForm: true,
      isSeller: _ctx.isSeller,
      ref: "completePrompt"
    }, null, 8, ["onCompleteForm", "formCompletion", "isSeller"])
  ]))
}