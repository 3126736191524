<template>
  <div class="input" :class="{ 'not-required': !required || placeHolderGrey }" :style="style">
    <label v-if="label">{{ label }}</label>
    <div v-if="contentBefore" class="before">{{ contentBefore }}</div>
    <div v-if="contentAfter" class="after">{{ contentAfter }}</div>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#ff0505" v-if="isInvalid" v-bind:class="'invalid'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>
    <input
      v-bind="$attrs"
      :class="{ contentBefore: contentBefore, contentAfter: contentAfter, isInvalid: isInvalid }"
      :value="modelValue"
      @input.stop="updateValue"
      :style="inputStyle"
    />
    <span class="suffix" v-if="suffix">{{ suffix }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isValidEmail } from "@/helpers/utils";

export default defineComponent({
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    contentBefore: String,
    contentAfter: String,
    modelValue: [String, Number],
    required: {
      type: Boolean,
      default: true,
    },
    placeHolderGrey: {
      type: Boolean,
      default: false,
    },
    suffix: String,
    noMargin: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      touched: false,
    };
  },
  computed: {
    isInvalid(): boolean {
      if (!this.touched) {
        return false;
      }
      if (this.$attrs.type == "email") {
        if (typeof this.modelValue == "string") {
          if (!isValidEmail(this.modelValue)) {
            return true;
          }
        }
      }
      return false;
    },
    style(): string {
      return (this.noMargin ? 'padding-right: 5px;' : 'margin-bottom: 20px;');
    },
    inputStyle():  object {
      return this.noMargin ? {} : { '::placeholder': { color: '#ff0000', fontWeight: 'bold' } };
    }
  },
  methods: {
    updateValue(event): void {
      this.touched = true;
      this.$emit("update:modelValue", event.target.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.input {
  position: relative;

  .before,
  .after {
    position: absolute;
    top: 8px;
    font-weight: bold;
    color: $colour-grey;
  }
  .before {
    left: 10px;
  }

  .after {
    right: 10px;
  }

  .suffix {
    position: absolute;
    right: 10px;
    top: 8px;
    font-weight: bold;
    color: $colour-grey;
  }

  input {
    width: 100%;
    height: 32px;
    text-align: center;
    color: $colour-grey;

    &.contentBefore {
      padding-left: 25px;
    }

    &.contentAfter {
      padding-right: 25px;
    }

    &.isInvalid {
      padding: 0 30px;
    }
  }

  .invalid {
    // background-color: red;
    height: 32px;
    // width: 10px;
    position: absolute;
    right: 5px;
  }


  &.not-required ::placeholder {
    color: $colour-grey;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $colour-red;
    font-weight: bold;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $colour-red;
    font-weight: bold;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
