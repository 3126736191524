<template>
  <div>
    <div class="select-input question-content tiles" v-if="Object.keys(answers).length <= 8">
      <BaseRadio
        v-for="(option, key) in answers"
        :key="key"
        :label="option"
        :name="id + '_radio'"
        :checked="answer == key.toString()"
        :value="answer"
        @input="$emit('input', key)"
      />
    </div>
    <div class="select-input question-content" v-else>
      <BaseSelect
        :name="id + '_select'"
        class="select"
        :options="selectAnswers"
        :value="answer"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import _uniqueId from "lodash/uniqueId";

export default defineComponent({
  props: {
    answers: {
      type: Object as PropType<Record<string, string>>,
      required: true,
    },
    answer: {
      type: String,
    },
  },
  data() {
    return {
      id: _uniqueId(),
    };
  },
  computed: {
    selectAnswers(): { label: string; value: string }[] {
      let answers = [] as { label: string; value: string }[];
      for (const [key, answer] of Object.entries(this.answers)) {
        answers.push({ label: answer as string, value: key });
      }
      return answers;
    },
  },
});
</script>

<style lang="scss" scoped>
.select-input {
}
</style>
