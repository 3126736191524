<template>
  <div class="section" :class="{ 'bottom-border': bottomBorder, 'read-only': readonly }">
    <div class="header" v-on:click="toggleContent">
      <h1>{{ title }}</h1>
      <p class="complete" v-if="complete != null" :class="{ incomplete: complete != 100 }">
        {{ complete == 100 ? "Section complete" : "Section incomplete" }}
      </p>
      <p class="complete" v-if="readonly">Read-only</p>
      <div v-if="complete != null" class="percentage">
        <CircleFiller :value="complete" />
      </div>
      <div class="arrow" :class="{ expanded: expanded }">
        <svg xmlns="http://www.w3.org/2000/svg" width="9.913" height="16.897" viewBox="0 0 9.913 16.897" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#797687" stroke="#797687" stroke-width="1.5" d="M.849 8.49c0-.208.104-.312.208-.52l6.868-6.869c.312-.312.729-.312.937 0 .312.312.312.729 0 .937h0L2.409 8.49l6.452 6.452c.312.312.312.729 0 .937-.312.312-.729.312-.937 0h0L1.057 9.01a.79.79 0 01-.208-.52z"/></svg>
      </div>
    </div>
    <div class="content" v-if="expanded">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import CircleFiller from "@/components/CircleFiller.vue";

export default {
  components: {
    CircleFiller,
  },
  name: "FormSection",
  props: {
    complete: Number,
    title: String,
    shown: Boolean,
    bottomBorder: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data: function () {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleContent: function () {
      this.expanded = !this.expanded;
    },
    expand: function () {
      this.expanded = true;
    },
  },
  created() {
    if (this.shown) {
      this.expanded = true;
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 0;
}

h1,
p {
  text-align: left;
}

.section {
  &:not(:last-child),
  &.bottom-border {
    border-bottom: 1px solid $colour-grey;
  }
}

.header {
  position: relative;
  background-color: $colour-light-grey;
  padding: 30px 120px 50px 20px;
  word-break: break-word;

  .percentage {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .arrow svg {
    transform: rotate(270deg);
    position: absolute;
    bottom: 18px;
    left: 35px;
    transition: transform 0.5s;
  }

  .expanded svg {
    transform: rotate(810deg);
  }

  p {
    color: var(--colour-primary);

    &.complete {
      margin-top: 6px;
      font-size: 12px;
    }

    &.incomplete {
      color: $colour-red;
    }
  }
}

.content {
  padding: $container-spacing-vertical 15px;
}
</style>
