<template>
  <div class="reports">
    <div class="top-bar">
      <h1>Properties</h1>
      <BaseButton @click="downloadCsv()">Export CSV (Excel)</BaseButton>
    </div>

    <div class="filter-bar">

      <div v-for="options, filter in filterOptions" :key="filter" class="filter">
        <label>{{ filter.charAt(0).toUpperCase() + filter.slice(1) }}</label>
        <BaseSelect class="select" :options="options" :value="params[filter]" @input="selectFilter(filter, $event)"
          initial="All" :required="false" :unselectedValue="-1" :placeHolderGrey="true" />
      </div>

      <div class="date-picker">
        <label>Date range</label>
        <div class="date-picker-container">
          <DatePicker v-model="initialDates" @date-applied="onApply" :date-input="{ inputClass: 'date-input' }" />
        </div>
      </div>
      <div class="filter">
        <label>Search</label>
        <div class="search">
          <BaseInput v-model="searchTerm" placeholder="Search..." class="search-input" noMargin
            @keyup.enter="selectFilter('search', searchTerm)" />
          <div @click="selectFilter('search', searchTerm)" class="search-button">&#x1F50D;</div>
        </div>
      </div>
    </div>
    <div v-if="showFailure" class="note failure">
      {{ failureMessage }}
    </div>
    <div v-if="pagination" class="pagination">
      <div class="pagination-upper">
        <span>Found {{ pagination.total }} propert{{ pagination.total === 1 ? 'y' : 'ies' }}</span>
      </div>
      <div v-if="pagination.total_pages > 1" class="pagination-lower">
        <a @click="changePage(pagination.page - 1)" :class="{ disabled: pagination.page <= 1 }">&laquo;</a>
        <template v-for="paginationPage, index in paginationPages">
          {{ index > 0 ? "..." : "" }}
          <a v-for="page in paginationPage" :key="page" @click="changePage(page)"
            :class="{ active: page === pagination.page }">{{ page }}</a>
        </template>
        <a @click="changePage(pagination.page + 1)"
          :class="{ disabled: pagination.page >= pagination.total_pages }">&raquo;</a>
      </div>
    </div>
    <table>
      <tr>
        <th>ID</th>
        <th>Address</th>
        <th v-if="user.role != permissions.USER_TYPE_SIMPLE">Completion</th>
        <th v-if="user.role != permissions.USER_TYPE_SIMPLE">Phase</th>
        <th v-if="user.role != permissions.USER_TYPE_SIMPLE">Last activity</th>
        <th>Created</th>
        <th>Title Number</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES) && user.role != permissions.USER_TYPE_SIMPLE">
          Agency
        </th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_SOLICITORS) && user.role != permissions.USER_TYPE_SIMPLE">
          Solicitor</th>
        <th>Sellers invited</th>
        <th>Earliest seller added to HOP</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_PROPERTY_SIGNATURES)">Actions</th>
      </tr>
      <tr v-for="property in this.properties" :key="property.id">
        <td>{{ property.id }}</td>
        <td>
          <router-link :to="{ name: 'dashboard', params: { id: property.id } }">
            {{ property.name.substring(0, 20) + (property.name.length > 20 ? "..." : "") }}</router-link>
        </td>
        <td v-if="user.role != permissions.USER_TYPE_SIMPLE">
          <div class="phase-complete" v-for="phase in property.completed_phases" :key="phase.id">
            <span>{{ phase.label.replace(' Ready', '') }}</span>
            <span>100%</span>
          </div>
          <div class="phase-complete">
            <span>{{ property.phase.label.replace(' Ready', '') }}</span>
            <span>{{ property.complete }}%</span>
          </div>
        </td>
        <td v-if="user.role != permissions.USER_TYPE_SIMPLE">
          {{ property.phase.label }}
        </td>
        <td v-if="user.role != permissions.USER_TYPE_SIMPLE">
          {{ property.last_sign_in > 0 ? new Date(property.last_sign_in * 1000).toLocaleDateString("en-GB") : "never"
          }}
        </td>
        <td>{{ property.created_at }}</td>
        <td>{{ property.title_number }}</td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES) && user.role != permissions.USER_TYPE_SIMPLE">
          {{ property.agencies_names }}
        </td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_SOLICITORS) && user.role != permissions.USER_TYPE_SIMPLE">
          {{ property.solicitors_names }}
        </td>
        <td>
          <div v-if="hasPermissionTo(permissions.ACTION_VIEW_SELLERS)">
            {{ property.num_sellers }}
          </div>
        </td>
        <td>
          {{ property.earliest_seller_added ? new Date(property.earliest_seller_added).toLocaleDateString("en-GB") :
            "never" }}
        </td>
        <td class="buttons" v-if="hasPermissionTo(permissions.ACTION_VIEW_PROPERTY_SIGNATURES)">
          <router-link :to="{
            name: 'propertysignatures',
            params: {
              id: property.id,
            },
          }">
            <BaseButton :small="true" style="width: 100%">Signatures report</BaseButton>
          </router-link>
          <router-link :to="{
            name: 'adminpropertymanage',
            params: {
              id: property.id,
            },
          }">
            <BaseButton :small="true" style="width: 100%">Manage</BaseButton>
          </router-link>
          <BaseButton v-if="canMoveToNextPhase(property)" :small="true" @click="moveToNextPhase(property)"
            style="width: 100%">Move to next phase</BaseButton>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton.vue";
import Permissions from "@/helpers/permissions";
import permissionsValues from "@/helpers/permissionsValues";
import HopService from "@/services/HopService";
import { DatePicker } from 'vue-time-date-range-picker';
import 'vue-time-date-range-picker/dist/style.css';
import moment from 'moment';
import BaseSelect from "@/components/Base/BaseSelect.vue";
import BaseInput from "@/components/Base/BaseInput.vue";

export default {
  components: {
    DatePicker,
    BaseSelect,
    BaseInput,
  },
  data() {
    return {
      // agencies: [],
      showFailure: false,
      failureMessage: "",
      properties: [],
      pagination: {},
      params: {},
      initialDates: [
        this.$route.query.created_after ? new Date(this.$route.query.created_after) : null,
        this.$route.query.created_before ? new Date(this.$route.query.created_before) : null,
      ],
      searchTerm: "",
    };
  },
  created() {
    this.updateData();
    this.processURLparams();
  },
  methods: {
    updateData() {
      // if (this.hasPermissionTo(this.permissions.ACTION_VIEW_AGENCIES)) {
      // this.$store.dispatch("updateLoading", true);
      // var ctx = this;
      // Get the agencies list
      // this.$store.commit("UPDATE_LOADING", false);
      // HopService.getAgencies(true)
      //   .then((response) => {
      //     ctx.agencies = [];
      //     response.data.forEach((element) => {
      //       ctx.agencies.push({
      //         value: element.id,
      //         label: element.name,
      //       });
      //     });
      //     this.showFailure = false;
      //     this.$store.dispatch("updateLoading", false);
      //   })
      //   .catch(() => {
      //     this.showFailure = true;
      //     this.failureMessage = "Failed to fetch agencies";
      //     this.showSuccess = false;
      //     this.$store.dispatch("updateLoading", false);
      //   });
      // }
    },
    processURLparams() {
      this.$store.dispatch("updateLoading", true);
      this.params = this.$route.query;
      this.searchTerm = this.params.search || "";
      HopService.getProperties({
        ...this.$route.query,
        ...{
          created_after: this.$route.query.created_after || moment(this.initialDates[0]).toISOString(),
          created_before: this.$route.query.created_before || moment(this.initialDates[1]).toISOString(),
        }
      }
      )
        .then((response) => {
          this.properties = response.data.properties;
          this.pagination = response.data.pagination;
          this.filterOptions = response.data.filter_options;
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to fetch properties";
          this.$store.dispatch("updateLoading", false);
        });
    },
    async downloadCsv() {
      this.$store.dispatch("updateBackgroundLoading", true);
      let csv = "HOP ID,Address,Completion,Date Completed,Last activity,Created,Title Number,Agency";
      if (this.hasPermissionTo(this.permissions.ACTION_VIEW_SOLICITORS) && this.user.role != this.permissions.USER_TYPE_SIMPLE) {
        csv += ",Solicitor";
      }
      csv += ",Sellers invited,Earliest seller added to HOP\n";

      const fetchCsvRows = async () => {
        let currentPage = 1;
        const lines = [];

        while (true) {
          try {
            const response = await HopService.getProperties({
              ...this.$route.query,
              page: currentPage,
              page_size: 50,
              created_after: moment(this.initialDates[0]).toISOString(),
              created_before: moment(this.initialDates[1]).toISOString(),
            });

            for (let p of response.data.properties) {
              let line = "";
              line += p["id"] + ",";
              line += p["name"].replaceAll(",", "") + ",";
              line += p["complete"] + "%,";
              line += (p["date_completed"] || "") + ",";
              line += (p["last_sign_in"] > 0 ? new Date(p["last_sign_in"] * 1000).toLocaleDateString("en-GB") : "never") + ",";
              line += p["created_at"] + ",";
              line += p["title_number"] + ",";
              line += p["agencies_names"] + ",";
              if (
                this.hasPermissionTo(this.permissions.ACTION_VIEW_SOLICITORS) &&
                this.user.role != this.permissions.USER_TYPE_SIMPLE
              ) {
                line += p["solicitors_names"] + ",";
              }
              line += p["num_sellers"] + ",";
              line +=
                (p["earliest_seller_added"] ? new Date(p["earliest_seller_added"]).toLocaleDateString("en-GB") : "never") + ",";
              lines.push(line);
            }
            if (currentPage >= response.data.pagination.total_pages) {
              break;
            }

            currentPage++;
          } catch (error) {
            console.error("Error fetching properties:", error);
            break;
          }
        }

        return lines;
      };

      const rows = await fetchCsvRows();
      rows.forEach((row) => {
        csv += row;
        csv += "\n";
      });

      const timestamp = new Date().toISOString().replace(/[:.]/g, "").replace(/T/, "-").replace(/Z/, "");
      const filename = `Properties_${timestamp}.csv`;

      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = filename;
      anchor.click();
      this.$store.dispatch("updateBackgroundLoading", false);
    },
    canMoveToNextPhase(property) {
      return property.phase.next_phase !== null;
    },
    moveToNextPhase(property) {
      if (!this.canMoveToNextPhase) {
        this.$store.dispatch("showAlert", "Already at the last phase");
        return;
      }

      this.$store.dispatch("updateLoading", true);
      if (confirm("Are you sure you want to move this property to the next phase?")) {
        HopService.updatePropertyPhase(property.id, property.phase.next_phase.id)
          .then(() => {
            this.$store.dispatch("showAlert", "Phase updated successfully.");
          })
          .catch((error) => {
            this.$store.dispatch("showAlert", "There was a problem moving to next phase: " + error?.response?.data);
          });
      }
      this.$store.dispatch("updateLoading", false);
    },
    onApply(date1, date2) {
      this.$router.push({
        query: {
          ...this.$route.query,
          created_after: moment(date1).toISOString(),
          created_before: moment(date2).toISOString(),
        }
      });
    },
    changePage(page) {
      if (page < 1 || page > this.pagination.total_pages) {
        return;
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          page: page,
        }
      });
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    },
    highlightText(text) {
      const searchTerm = this.$route.query.search;
      if (!searchTerm) return text;
      const escapedTerm = this.escapeRegExp(searchTerm);
      const regex = new RegExp(`(${escapedTerm})`, 'gi');
      // Replace matching text with a span that has the 'highlight' class
      return text.replace(regex, '<span class="highlight">$1</span>');
    },
    selectFilter(filter, value) {
      if (value === "-1" || value === -1) {
        const updatedQuery = { ...this.$route.query };
        delete updatedQuery[filter];
        this.$router.push({ query: updatedQuery });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            [filter]: value,
          }
        });
      }
    },
  },
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
    paginationPages() {
      const offset = 4;
      const flatPages = [];
      flatPages.push(1);
      for (let i = Math.max(2, this.pagination.page - offset); i <= Math.min(this.pagination.total_pages - 1, this.pagination.page + offset); i++) {
        flatPages.push(i);
      }
      flatPages.push(this.pagination.total_pages);

      const pages = [];
      for (let i = 0; i < flatPages.length; i += 1) {
        if (pages.length === 0) {
          pages.push([flatPages[i]]);
          continue;
        }
        if (flatPages[i] - flatPages[i - 1] > 1) {
          pages.push([flatPages[i]]);
          continue;
        }
        pages[pages.length - 1].push(flatPages[i]);
      }
      return pages;
    },
  },
  watch: {
    $route(to, from) {
      this.processURLparams();
    },
  },
};
</script>

<style lang="scss" scoped>
.agencies-select {
  min-width: 170px;
}

.failure {
  color: $colour-error-red-text;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid $colour-error-red-border;
  background-color: $colour-error-red-bg;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: stretch;
}

.phase-complete {
  margin-bottom: 4px;
  display: flex;
  gap: 6px;
  justify-content: space-between;
}

.pagination {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 0;
  padding: 0 10px;

  .pagination-lower {
    display: flex;
    align-items: center;
  }

  a {
    &.active {
      background-color: var(--colour-primary);
      color: var(--colour-white);
      cursor: default;
    }

    &:hover:not(.active):not(.disabled) {
      background-color: var(--colour-primary);
      color: white;
      opacity: 0.7;
    }

    user-select: none;
    text-decoration: none;
    padding: 4px;
    margin: 4px 0;
    cursor: pointer;
    min-width: 2em;
    text-align: center;

    &.disabled {
      color: var(--colour-grey);
      cursor: not-allowed;
    }
  }
}

.filter-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter {
  min-width: 200px;
}

.search {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 10px auto;
}

.date-picker-container {
  margin: 10px auto;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
