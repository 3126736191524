import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import BaseButton from "@/components/Base/BaseButton.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseDateInput from "@/components/Base/BaseDateInput.vue";
import BaseTextArea from "@/components/Base/BaseTextArea.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseRadio from "@/components/Base/BaseRadio.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseListSelect from "@/components/Base/BaseListSelect.vue";
import BaseMultiSelect from "@/components/Base/BaseMultiSelect.vue";
import BaseLoader from "@/components/Base/BaseLoader.vue";
import BaseAlert from "@/components/Base/BaseAlert.vue";
import Question from "@/components/Forms/Questionnaires/Question.vue";
import VueGtag from "vue-gtag";
import { createAuth0 } from "@auth0/auth0-vue";
import Config from "./services/Config";

const app = createApp(App);
app.component("BaseSelect", BaseSelect);
app.component("BaseButton", BaseButton);
app.component("BaseInput", BaseInput);
app.component("BaseDateInput", BaseDateInput);
app.component("BaseTextArea", BaseTextArea);
app.component("BaseCheckbox", BaseCheckbox);
app.component("BaseRadio", BaseRadio);
app.component("BaseModal", BaseModal);
app.component("BaseListSelect", BaseListSelect);
app.component("BaseMultiSelect", BaseMultiSelect);
app.component("BaseLoader", BaseLoader);
app.component("BaseAlert", BaseAlert);
app.component("Question", Question);

app.use(store);
app.use(router);
app.use(
  createAuth0({
    domain: Config.getAuth0Domain(),
    clientId: Config.getAuth0ClientId(),
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.VUE_APP_AUTH0_AUDIENCE_OVERRIDE || process.env.VUE_APP_API_ENDPOINT,
    },
  })
);

if (
  process.env.NODE_ENV == "production" &&
  process.env.VUE_APP_API_ENDPOINT.indexOf("staging") == -1 &&
  process.env.VUE_APP_API_ENDPOINT.indexOf("demos") == -1
) {
  app.use(VueGtag, {
    config: { id: "UA-158119005-1" },
  });
}

app.mount("#app");
