<template>
  <div class="yes-no-question">
    <div class="question-content">
      <BaseRadio
        v-for="(option, key) in options"
        :key="key"
        :label="option"
        :name="id + '_radio'"
        :checked="model && model.yes == key"
        :value="model.yes"
        @input="updateYes(key)"
      />
    </div>
    <div class="slot-container">
      <slot />
    </div>
    <div class="items" v-if="model.yes != '0' && model.yes != '2'">
      <div class="item" v-for="(item, index) in model.items" :key="item.id">
        <h3 class="item-header" :class="{ 'no-delete': model.items.length == 1 }">
          {{ title }} {{ index + 1 }}
          <BaseButton v-if="model.items.length != 1" :small="true" @click="removeItem($event, index)"> Delete </BaseButton>
        </h3>
        <BaseSelect
          initial="Year"
          v-if="year"
          class="select"
          :options="yearsOptions"
          @input="updateItem(index, 'year', $event)"
          :value="item.year"
        />
        <BaseSelect
          initial="Month"
          v-if="month"
          class="select"
          :options="monthsOptions"
          @input="updateItem(index, 'month', $event)"
          :value="item.month"
        />
        <BaseSelect
          initial="Type"
          class="select"
          :options="categories"
          @input="updateItem(index, 'category', $event)"
          :value="item.category"
        />
        <BaseTextArea
          v-if="description"
          rows="5"
          :modelValue="item.description"
          @update:modelValue="updateItem(index, 'description', $event)"
          :placeholder="placeholder"
        />
      </div>
      <BaseButton :small="true" @click="addItem($event)"> Add another </BaseButton>
    </div>
  </div>
</template>

<script>
import OptionsService from "@/services/OptionsService";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    model: Object,
    options: Object,
    year: { type: Boolean, default: true },
    month: { type: Boolean, default: false },
    categories: Array,
    description: { type: Boolean, default: true },
    placeholder: { type: String, default: "Details" },
    title: { type: String, default: "Item" },
  },
  data() {
    return {
      id: this._uid,
      yearsOptions: OptionsService.yearsFrom1970(),
      monthsOptions: OptionsService.allMonths(),
    };
  },
  methods: {
    updateYes(val) {
      this.$emit("updateYes", {
        key: "yes",
        val: val,
      });
      if (val.yes != 0 && val.yes != 2 && this.model.items.length == 0) {
        this.addItem();
      }
    },
    updateItem(index, fieldName, val) {
      this.$emit("updateItem", {
        index: index,
        fieldName: fieldName,
        val: val,
      });
    },
    addItem(event) {
      if (event) {
        event.stopPropagation();
      }
      this.$emit("addItem");
    },
    removeItem(event, index) {
      this.$emit("removeItem", index);
    },
  },
});
</script>

<style lang="scss" scoped>
.items {
  padding: 0 35px;

  .item {
    padding: 10px 10px 6px 10px;
    margin: 5px 0;

    .item-header {
      position: relative;
      padding-right: 56px;
      :deep(.container) .btn {
        position: absolute;
        bottom: -2px;
        right: 0;
      }

      &.no-delete {
        padding-right: 0;
      }
    }
  }
}

.slot-container {
  margin-top: 10px;
  p {
    text-align: left;
  }
}

:deep(.item) .textarea textarea {
  margin: 0;
}

.question-title {
  text-align: left;
  padding: 0;
  margin-bottom: 10px;
}

.question-content {
  padding: 0 35px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  div {
    text-align: right;
    margin: 0;
  }
}
</style>
