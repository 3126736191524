<template>
  <div class="add-property">
    <h1>Add Property</h1>
    <Carousel ref="carousel" :arrows="false" :swipe="false" :adaptiveHeight="false">
      <div class="step1">
        <div v-if="postcodeSearch">
          <p>Start by entering your postcode</p>
          <BaseInput v-model="postcode" placeholder="Postcode" />
          <p class="error" v-if="showError">
            Hmmm, we can't seem to find that. Please try again, or use the alternative search form.
          </p>
          <BaseButton @click="search"> Search </BaseButton>
          <p @click="togglePostcodeSearch" class="alt-search">Switch to title number</p>
        </div>
        <div v-else>
          <p class="error" v-if="showError">
            {{ errorMessage }}
          </p>
          <p>Enter the title number</p>
          <BaseInput v-model="titleNumber" placeholder="Title Number" />

          <div class="agent" v-if="hasPermissionTo(permissions.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT)">
            <BaseSelect
              initial="Select agency"
              class="select"
              :options="agencies"
              :value="selectedAgency"
              @input="updateSelectedAgency($event)"
            />
            <BaseSelect
              initial="Select agent"
              class="select"
              :options="agents"
              :value="selectedAgent"
              @input="updateSelectedAgent($event)"
            />
          </div>
          <div v-else class="agent">
            <BaseSelect
              initial="Select agent"
              class="select"
              :options="agents"
              :value="selectedAgent"
              @input="updateSelectedAgent($event)"
            />
          </div>

          <BaseButton @click="addByTitle">Add property</BaseButton>
        </div>
      </div>
      <div class="step2">
        <p>Select the address of the property below:</p>
        <BaseListSelect class="results" @click="selectAddress" :model="addresses" modelKey="line_1" />
        <BaseButton @click="altForm"> My address isn't listed here </BaseButton>
      </div>
      <div class="step25">
        <p>As your organisation uses Alto, we have searched the portal for this property.</p>
        <p>Select the address which most closely matches the property below:</p>
        <BaseListSelect
          class="results"
          @click="selectAltoProperty(altoProperties[$event])"
          :model="formattedAltoProperties"
          modelKey="name"
        />
        <BaseButton @click="skipAlto"> My address isn't listed here </BaseButton>
      </div>
      <div class="step3">
        <br />
        <p v-if="hasPermissionTo(permissions.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT)">
          First select the estate agency relating to this property, then select the property address you wish to add.
        </p>

        <br />
        <p>
          If your property is not listed below, it may not be registered with the Land Registry, so cannot be used with hop just
          at the moment. If you think it is registered, you can try again using the alternative search form.
        </p>
        <!-- <BaseButton @click="selectUnregisteredProperty">
          My property isn't listed here
        </BaseButton> -->
        <div class="agent" v-if="hasPermissionTo(permissions.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT)">
          <BaseSelect
            initial="Select agency"
            class="select"
            :options="agencies"
            :value="selectedAgency"
            @input="updateSelectedAgency($event)"
          />
        </div>
        <BaseListSelect class="results" @click="selectProperty" :model="formattedProperties" modelKey="name" />
        <p class="error" v-if="showError">
          {{ errorMessage }}
        </p>
        <BaseButton @click="altForm">Alternative search form</BaseButton>
        <BaseButton @click="restartSearch">Search again</BaseButton>
      </div>
      <div class="altform">
        <p>Try inputting the address yourself to help us locate the right property details.</p>
        <p>Please fill out all fields that apply to your property address, leaving any others blank.</p>
        <BaseInput v-model="altAddress.buildingNumber" placeholder="Building Number" />
        <BaseInput v-model="altAddress.buildingName" placeholder="Building Name" />
        <BaseInput v-model="altAddress.streetName" placeholder="Street" />
        <BaseInput v-model="altAddress.cityName" placeholder="City/Town" />
        <BaseInput v-model="altAddress.postCode" placeholder="Postcode" />
        <BaseButton @click="altSearch"> Search </BaseButton>
        <p>The following hints can help find the right address:</p>
        <ul>
          <li>a) If the property is a flat, don't include the flat number</li>
          <li>b) If the property just has a house name and not a number, try completing the Building Name and Postcode only</li>
        </ul>
      </div>
    </Carousel>
  </div>
</template>

<script>
import AddressService from "@/services/AddressService.js";
import HopService from "@/services/HopService.js";
import Carousel from "@/components/Carousel.vue";
import Permissions from "@/helpers/permissions";
import _sortBy from "lodash/sortBy";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  components: {
    Carousel,
  },
  data() {
    return {
      postcode: "",
      addresses: [],
      properties: [],
      altoProperties: null,
      altoInventoryId: "",
      showError: false,
      errorMessage: "",
      lastRequestId: "",
      altAddress: {
        buildingNumber: "",
        buildingName: "",
        streetName: "",
        cityName: "",
        postCode: "",
      },
      finalAddress: {},
      agencies: [],
      agents: [],
      selectedAgency: 0,
      selectedAgent: 0,
      selectedPostcode: null,
      titleNumber: "",
      freehold: false,
      postcodeSearch: true,
    };
  },
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    formattedAltoProperties() {
      let properties = [];
      if (this.altoProperties && this.altoProperties.length) {
        this.altoProperties.forEach((element) => {
          let newElement = { name: "" };
          if (element.address.nameNo) {
            newElement.name += element.address.nameNo + ", ";
          }
          if (element.address.subDwelling) {
            newElement.name += element.address.subDwelling + ", ";
          }
          if (element.address.street) {
            newElement.name += element.address.street + ", ";
          }
          if (element.address.town) {
            newElement.name += element.address.town;
          }
          if (element.address.county) {
            newElement.name += ", " + element.address.county;
          }
          if (element.address.postcode) {
            newElement.name += ", " + element.address.postcode;
          }
          properties.push(newElement);
        });

        _sortBy(properties, "name");
      }
      return properties;
    },
    formattedProperties() {
      var properties = [];
      if (this.properties && this.properties.length) {
        this.properties.forEach((element) => {
          if (element) {
            let newElement = { name: "" };
            if (element.Address.SubBuildingName) {
              newElement.name += element.Address.SubBuildingName + ", ";
            }
            if (element.Address.BuildingName) {
              newElement.name += element.Address.BuildingName + ", ";
            }
            if (element.Address.BuildingNumber) {
              newElement.name += element.Address.BuildingNumber + ", ";
            }
            if (element.Address.StreetName) {
              newElement.name += element.Address.StreetName;
            }
            if (element.Address.PostcodeZone && element.Address.PostcodeZone.Postcode) {
              newElement.name += ", " + element.Address.PostcodeZone.Postcode;
            }
            if (!element.Address.StreetName) {
              newElement.name = newElement.name.substring(0, newElement.name.length - 2);
            }
            var leaseholdTenureTypeCodes = ["20"];
            if (leaseholdTenureTypeCodes.indexOf(element.TenureInformation.TenureTypeCode) != -1) {
              newElement.name += " (leasehold)";
            }
            properties.push(newElement);
          }
        });

        _sortBy(properties, "name");
      }
      return properties;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  created() {
    if (this.hasPermissionTo(this.permissions.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT)) {
      HopService.getAgencies()
        .then((response) => {
          this.agencies = [];
          response.data.forEach((element) => {
            this.agencies.push({
              value: element.id,
              label: element.name,
            });
          });
          this.$nextTick(() => {
            this.selectedAgency = 0;
          });
          this.showError = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showError = true;
          this.errorMessage = "Failed to fetch agencies";
          this.$store.dispatch("updateLoading", false);
        });
    }
  },
  methods: {
    search() {
      if (this.postcode.length != 0) {
        this.$store.dispatch("updateLoading", true);
        AddressService.getAddresses(this.postcode)
          .then((response) => {
            if (response.data && response.data.addresses) {
              this.addresses = response.data.addresses;
              this.$refs.carousel.goTo(1);
              this.showError = false;
            } else {
              this.$store.dispatch("connectionProblem", true);
              this.showError = true;
            }
            this.$store.dispatch("updateLoading", false);
            this.$store.dispatch("connectionProblem", false);
          })
          .catch(() => {
            this.showError = true;
            this.$store.dispatch("updateLoading", false);
            this.$store.dispatch("connectionProblem", true);
          });
      } else if (this.titleNumber.length != 0) {
      }
    },
    updatePostcode(val) {
      this.postcode = val;
    },
    searchLandReg(address) {
      this.finalAddress = address;
      this.$store.dispatch("updateLoading", true);
      this.$refs.carousel.goTo(3);
      this.properties = [];
      HopService.search(address)
        .then((response) => {
          if (Array.isArray(response.data.results)) {
            this.properties = response.data.results;
          } else {
            this.properties = [response.data.results];
          }

          this.lastRequestId = response.data.request_id;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("updateLoading", false);
        });
    },
    addByTitle() {
      const isReadyToAdd = this.selectedAgent != 0 && this.titleNumber.length > 0;

      if (isReadyToAdd) {
        this.$store.dispatch("updateLoading", true);

        HopService.addPropertyByTitleNumber(this.titleNumber, this.selectedAgent, this.selectedAgency)
          .then((response) => {
            if (response.status === 200 && response.data.id) {
              this.showError = false;
              this.$store.dispatch("updateLoading", false);
              this.$router.push({ name: "dashboard", params: { id: response.data.id } });
            } else {
              this.showError = true;
              this.errorMessage = "Please enter a valid title number";
            }
          })
          .catch((error) => {
            this.showError = true;
            this.$store.dispatch("updateLoading", false);
            if (error.response && error.response.status === 500) {
              this.errorMessage = error.response.data.message;
            } else {
              this.errorMessage = "There was a problem adding the property.";
            }
            this.$store.dispatch("showAlert", this.errorMessage);
          });
      } else {
        this.showError = true;
        this.errorMessage = "Please enter a title number and an agent";
      }
    },
    skipAlto() {
      this.searchLandReg(this.landRegSearchAddress);
    },
    searchAlto(postCode) {
      this.$store.dispatch("updateLoading", true);
      this.$refs.carousel.goTo(2);
      this.altoProperties = [];
      HopService.searchAlto(postCode)
        .then((response) => {
          this.altoProperties = response.data.properties;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("updateLoading", false);
        });
    },
    selectAltoProperty(property) {
      this.altoInventoryId = property.id;
      this.searchLandReg(this.landRegSearchAddress);
    },
    selectAddress(val) {
      this.landRegSearchAddress = {
        buildingNumber:
          this.addresses[val].sub_building_number +
          (this.addresses[val].sub_building_number ? " " : "") +
          this.addresses[val].building_number,
        buildingName:
          this.addresses[val].sub_building_name +
          (this.addresses[val].sub_building_name ? " " : "") +
          this.addresses[val].building_name,
        streetName: this.addresses[val].thoroughfare,
        cityName: this.addresses[val].town_or_city,
        postCode: this.addresses[val].hop_post_code,
      };
      if (this.user.organisation && this.user.organisation.alto) {
        this.searchAlto(this.postcode);
      } else {
        this.searchLandReg(this.landRegSearchAddress);
      }
    },
    altSearch() {
      this.searchLandReg(this.altAddress);
    },
    selectProperty(val) {
      var router = this.$router;
      var ctx = this;
      this.$store
        .dispatch("addProperty", {
          titleNumber: this.properties[val].TitleNumber,
          requestId: this.lastRequestId,
          address: this.finalAddress,
          parentId: this.selectedAgency,
          altoId: this.altoInventoryId,
        })
        .then(function () {
          ctx.showError = false;
          router.push({ name: "properties" });
        })
        .catch((error) => {
          if (error.response && error.response.data.message) {
            this.showError = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.showError = true;
            if (this.hasPermissionTo(this.permissions.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT)) {
              this.errorMessage = "Adding a property failed. Did you select an agency and agent to assign it to?";
            } else {
              this.errorMessage = "Adding a property failed.";
            }
          }
        });
    },
    altForm() {
      this.showError = false;
      this.$refs.carousel.goTo(4);
    },
    restartSearch() {
      this.showError = false;
      this.$refs.carousel.goTo(0);
    },
    updateSelectedAgency(value) {
      this.selectedAgency = value;
      HopService.getAgentsByAgency(value)
        .then((response) => {
          this.agents = [];
          response.data.forEach((element) => {
            this.agents.push({
              value: element.id,
              label: element.name + " - " + element.email,
            });
          });
          this.$nextTick(() => {
            this.selectedAgent = 0;
          });
          this.showError = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showError = true;
          this.errorMessage = "Failed to fetch agents";
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateSelectedAgent(value) {
      this.selectedAgent = value;
    },
    togglePostcodeSearch() {
      this.postcodeSearch = !this.postcodeSearch;
      if (!this.postcodeSearch) {
        //get agents for the user's agency
        HopService.getAgentsByAgency(this.user.organisation.id)
          .then((response) => {
            this.agents = [];
            response.data.forEach((element) => {
              this.agents.push({
                value: element.id,
                label: element.name + " - " + element.email,
              });
            });
            this.$nextTick(() => {
              this.selectedAgent = 0;
            });
            this.showError = false;
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showError = true;
            this.errorMessage = "Failed to fetch agents";
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-property {
  padding: 0 30px;
}

.error {
  color: $colour-error-red-text;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid $colour-error-red-border;
  background-color: $colour-error-red-bg;
}

ul {
  padding-left: 0;

  li {
    color: $colour-grey;
    list-style: none;
  }
}
</style>
