<template>
  <QuestionnaireForm :questionnaireId="1" :formPath="'leasehold'">
    <div class="leasehold-header">
      <img src="@/assets/theme/lawsociety/law-society-provider-logo.png" class="ls-logo" alt="The Law Society" />
      <h1>Law Society Leasehold Information form</h1>
      <p>
        This form is completed by the seller to supply the detailed information and documents which may be relied upon for the
        conveyancing process.
      </p>
      <BaseButton @click="$refs.guideModal.open()"
        >Definitions
      </BaseButton>
    </div>
    <BaseModal ref="guideModal">
      <h1>Definitions</h1>
      <ul>
        <li>"building" means the building containing the property.</li>
        <li>"buyer" means all buyers together where the property is being bought by more than one person.</li>
        <li>"headlease" means any lease of the Building held by the landlord (including a superior landlord).</li>
        <li>"landlord" includes any person who has a right under the lease to enforce payment of a service charge.</li>
        <li>
          "landlord's certificate" is the certificate set out in Schedule 1 of
          <a href="https://www.legislation.gov.uk/uksi/2022/711/made" target="_blank"
            >The Building Safety (Leaseholder Protections) (England) Regulations 2022.</a
          >
        </li>
        <li>"leaseholder" means a tenant under a lease of a dwelling in a building.</li>
        <li>
          "leaseholder deed of certificate" means the certificate set out in the Schedule to
          <a href="https://www.legislation.gov.uk/uksi/2022/859/made" target="_blank"
            >The Building Safety (Leaseholder Protections) (Information etc.) (England) Regulations 2022.</a
          >
        </li>
        <li>"neighbour" means those occupying flats in the building.</li>
        <li>"property" means the leasehold property being sold.</li>
        <li>
          "qualifying lease" has the meaning given in
          <a href="https://www.legislation.gov.uk/ukpga/2022/30/section/119" target="blank"
            >section 119 of the Building Safety Act 2022.</a
          >
        </li>
        <li>
          "remediation" means remediation of certain defects in buildings as provided for under sections 116 to 125 of, and
          Schedule 8 to, the Building Safety Act 2022. In particular, those provisions include protections from liability for
          leaseholders in specific circumstances.
        </li>
        <li>
          "right to manage" means a collective right, given by the Commonhold and Leasehold Reform Act 2002, which leaseholders in
          a building containing flats have the right to exercise, allowing them to take over management of their building.
        </li>
        <li>"seller" means all sellers together where the property is owned by more than one person.</li>
      </ul>
    </BaseModal>

    <BaseModal ref="introModal">
      <h1>Law Society Leasehold Information</h1>
      <p v-if="$store.state['property'].property.data.conveyancing_forms.preliminary_information.accepted">
        As you have completed the Law Society Preliminary Information form, HOP has mapped some of the answers through to this
        one. Please check they are correct before moving on.
      </p>
      <p v-if="!$store.state['property'].property.data.conveyancing_forms.preliminary_information.accepted">
        While you can complete this form now, it is recommended to complete the "Law Society Preliminary Information" form first,
        as answers from there map through to this one.
      </p>
      <BaseButton
        v-if="!$store.state['property'].property.data.conveyancing_forms.preliminary_information.accepted"
        @click="$router.push({ name: 'preliminary-information' })"
        >Take me to Preliminary Information</BaseButton
      >
    </BaseModal>
  </QuestionnaireForm>
</template>

<script>
import { defineComponent } from "vue";
import QuestionnaireForm from "@/components/Forms/Questionnaires/QuestionnaireForm.vue";

export default defineComponent({
  components: {
    QuestionnaireForm,
  },
  methods: {
    showModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs[name].open();
    },
  },
  mounted() {
    if (this.$store.state["property"].property.ta6_part1_active == 1) {
        this.$refs.introModal.open();
      }
  },
});
</script>

<style lang="scss"></style>
