<template>
  <div>
    <FormSection title="4. New builds / conversions" :complete="model.preliminary_information.progress[4]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">4.1</span>
              <span> {{ options.preliminary_information.questions["4_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="$emit('updateModelLevel2', '4_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['4_1']['yes']"
            />
            <p class="question-title">When you bought the property, you may have been given the following:</p>
            <ul class="question-title">
              <li>copies or details of the warranties and guarantees, and</li>
              <li>any planning consents or other planning documents.</li>
            </ul>
            <p class="question-title">If available, please upload these.</p>
            <Uploader
              :modelValue="model.preliminary_information.questions['4_1']['files']"
              @update:modelValue="$emit('updateModelLevel2', '4_1', 'files', $event)"
            />
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
    Uploader,
  },
  data() {
    return {
      passport: this.$store.state["property"].property.data.passport,
    };
  },
});
</script>
