<template>
  <div>
    <FormSection title="2. Ownership" :complete="model.preliminary_information.progress[2]" :bottomBorder="true">
      <form class="questions">
        <p class="note">These answers may be pre-filled with information from the title deeds. Please check they are correct.</p>
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">2.1</span>
              <span
                >It seems from the title deeds that you have owned the property since {{ currentProprietorshipDate }}. Please
                check this is correct.</span
              >
            </p>
            <div class="question-content">
              <BaseSelect
                initial="Month"
                class="select"
                :options="months"
                @input="$emit('updateModelLevel1', '2_1_i', $event)"
                :value="model.preliminary_information.questions['2_1_i']"
              />
              <BaseSelect
                initial="Year"
                class="select"
                :options="yearsFrom1970"
                @input="$emit('updateModelLevel1', '2_1_ii', $event)"
                :value="model.preliminary_information.questions['2_1_ii']"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import OptionsService from "@/services/OptionsService";
import { formatDate } from "@/helpers/utils";
import { useStore } from "vuex";

const store = useStore();

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
  },
  data() {
    return {
      yearsFrom1970: OptionsService.yearsFrom1970(),
      months: OptionsService.allMonths(),
    };
  },
  computed: {
    currentProprietorshipDate() {
      return formatDate(new Date(Date.parse(store.state["property"].property.data.passport.current_proprietorship_date)), false);
    },
  },
});
</script>
