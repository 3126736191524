<template>
  <div>
    <h1>Welcome<span v-if="!whiteLabel"> to hop</span></h1>
    <div class="auth">
      <div class="buttons">
        <div class="button-container">
          <h2>Existing Users</h2>
          <p class="helper">Already created an account?</p>
          <BaseButton @click="login">Log in</BaseButton>
        </div>
        <div class="divider"></div>
        <div class="button-container">
          <h2>New Users</h2>
          <p class="helper">Sign up with the email address mentioned in your invitation message.</p>
          <BaseButton @click="signup">Sign up</BaseButton>
        </div>
      </div>
      <h2 class="message" v-if="message?.length">{{ message }}</h2>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  data: () => ({
    message: "",
  }),
  mounted: function () {
    this.$store.dispatch("updateLoading", false);
    this.$store.dispatch("appIsLoaded", true);
    this.message = this.$route.query.msg;
  },
  computed: mapState({
    whiteLabel: (state) => state.ui.whiteLabel,
  }),
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    signup() {
      this.$auth0.loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  margin-bottom: 30px;
}

h2 {
  margin-bottom: 20px;
}

.helper {
  padding: 0;
}

.message {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: $colour-black;
  margin: 20px;
  border: 3px solid $colour-error-red-text;
  padding: 10px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  max-width: 600px;

  .button-container {
    margin: 0 10px;
    flex: 1;
  }

  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1px;
    background-color: $colour-grey;
    color: $colour-grey;
    margin: 0 20px;
    position: relative;
  }

  .divider::before {
    content: "OR";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 0 6px;
  }
}
</style>
