<template>
  <div class="yes-no-upload">
    <div class="question" v-if="!noYesNo">
      <BaseRadio
        v-for="(option, key) in yesNoOptions"
        :key="key"
        :label="option"
        :name="questionKey + '_radio'"
        :checked="model && model.yes == key"
        :value="model.yes"
        @input="updateModel('yes', key)"
      />
    </div>
    <div class="slot-container">
      <slot />
    </div>
    <div class="uploadNote" v-if="showUpload">Please indicate whether you are uploading this now:</div>
    <div class="question" v-if="showUpload">
      <BaseRadio
        v-for="(option, key) in optionsUpload"
        :key="key"
        :label="option"
        :name="questionKey + '_upload_radio'"
        :checked="model.upload == key"
        :value="model.upload"
        @input="updateModel('upload', key)"
      />
    </div>
    <Uploader
      v-if="showUpload && model.upload == 3"
      :modelValue="model.files"
      @update:modelValue="updateModel('files', $event)"
    />
  </div>
</template>

<script>
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";

export default {
  components: {
    Uploader,
  },
  props: {
    model: Object,
    uploadIfNo: {
      type: Boolean,
      default: false,
    },
    optionsYesNo: Object,
    optionsUpload: Object,
    questionKey: String,
    section: {
      type: String,
      default: "property_information",
    },
    noYesNo: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateModel(key, val) {
      this.$emit("input", {
        key: key,
        val: val,
      });
    },
  },
  computed: {
    yesNoOptions() {
      if (this.optionsYesNo) {
        return this.optionsYesNo;
      }
      return this.optionsYesNoDefault;
    },
    showUpload() {
      if (this.uploadIfNo) {
        return this.model.yes == 2 || this.noYesNo;
      } else {
        return this.model.yes == 1 || this.noYesNo;
      }
    },
  },
  data() {
    return {
      optionsYesNoDefault: this.$store.state.options.options.shared.options.yes_no,
    };
  },
};
</script>

<style lang="scss" scoped>
.slot-container,
.question {
  padding: 0 35px;
}

.question {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 8px;
}

.uploadNote {
  font-size: 16px;
  color: $colour-grey;
  padding: 0 35px 10px;
}
</style>
