/*
 * Functions used in more than one location in the app
 */

export function getOrigin() {
  if (
    process.env.NODE_ENV == "development" ||
    process.env.VUE_APP_THEME == "wiggywam" ||
    process.env.VUE_APP_THEME == "lawsociety"
  ) {
    return process.env.VUE_APP_API_ENDPOINT;
  } else {
    return window.location.origin + "/api/";
  }
}

export function isValidEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function openUrl(url) {
  window.open(url, "_blank");
}

export function round(value: number, decimals: number): number {
  return parseFloat(value.toFixed(decimals));
}

export function stringTyped(values: { model: string; maxPoints: number; points: number }) {
  values.maxPoints++;
  if (values.model.length > 0) {
    values.points++;
    return true;
  }
  return false;
}

export function viewEpc(property) {
  if (property.data.epc) {
    window.open(
      "https://find-energy-certificate.service.gov.uk/find-a-certificate/search-by-postcode?postcode=" + property.post_code,
      "_blank"
    );
  }
}

export function formatDate(date: Date, day = true, month = true, year = true): string {
  return (day ? date.getDate() + "/" : "") + (month ? date.getMonth() + 1 + "/" : "") + (year ? date.getFullYear() : "");
}

export function getRemainingLeaseYears(leaseString, currentDate) {
  const lease = leaseString.split(" years from ");
  if (lease[1]) {
    const years = lease[0];
    const startDate = new Date(Date.parse(lease[1]));
    const endDate = startDate;
    endDate.setFullYear(endDate.getFullYear() + parseInt(years));
    const currentYear = new Date(Date.parse("01/01/" + (currentDate.getFullYear() + 1)));
    return endDate.getFullYear() - currentYear.getFullYear();
  } else {
    return false;
  }
}
