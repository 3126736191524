<template>
  <div>
    <FormSection
      title="19. Right to use and enjoy your property"
      :complete="model.preliminary_information.progress[19]"
      :bottomBorder="true"
    >
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">19.1</span>
              <span> {{ options.preliminary_information.questions["19_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="19_1"
              @input="$emit('updateModelLevel2', '19_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['19_1']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['19_1']['yes'] == 1">
              Please give details e.g. what the plans are, and when they are expected to take place.
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['19_1']['yes'] == 1">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="Details"
                :modelValue="model.preliminary_information.questions['19_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '19_1', 'details', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
});
</script>
