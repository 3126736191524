/*
 * This is called both by App.vue and router.js,
 * so cold loads and hot routes are caught.
 */

import Permissions from "@/helpers/permissions";
import permissionsValues from "@/helpers/permissionsValues";

export function checkRoutePermissions(to, role) {
  if (to == null) {
    return true;
  }
  const permissions = new Permissions();
  if (to.path == "/admin") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_VIEW_ADMIN, role)) {
      return true;
    }
  } else if (to.path == "/admin/brands") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_VIEW_BRANDS, role)) {
      return true;
    }
  } else if (to.path == "/admin/agencies") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_CREATE_AGENCY, role)) {
      return true;
    }
  } else if (to.path == "/admin/agents") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_CREATE_AGENT, role)) {
      return true;
    }
  } else if (to.path == "/admin/movers") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_VIEW_MOVERS, role)) {
      return true;
    }
  } else if (to.path == "/properties/new") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_CREATE_PROP, role)) {
      return true;
    }
  } else if (to.path == "/admin/properties") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_CREATE_PROP, role)) {
      return true;
    }
  } else if (to.name == "propattrs") {
    if (permissions.hasPermissionTo(permissionsValues.REPORT_PROP_ATTRIBUTES, role)) {
      return true;
    }
  } else if (to.name == "property-information") {
    if (
      permissions.hasPermissionTo(permissionsValues.EDIT_PROP_INFO, role) ||
      permissions.hasPermissionTo(permissionsValues.EDIT_PROP_INFO_SERVICES, role) ||
      permissions.hasPermissionTo(permissionsValues.EDIT_PROP_INFO_UTILS_SERVICES, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else if (to.name == "invite-seller") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_INVITE_SELLER, role)) {
      return true;
    }
  } else if (to.name == "invite-solicitor") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_INVITE_SOLICITOR, role)) {
      return true;
    }
  } else if (to.name == "invite-agent") {
    if (permissions.hasPermissionTo(permissionsValues.ACTION_INVITE_AGENT, role)) {
      return true;
    }
  } else if (to.name == "fixtures-fittings") {
    if (
      permissions.hasPermissionTo(permissionsValues.ACTION_EDIT_FIXTURES_FITTINGS, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else if (to.name == "conveyancing-enquiries") {
    if (
      permissions.hasPermissionTo(permissionsValues.ACTION_VIEW_CONVEYANCING_ENQUIRIES, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else if (to.name == "about-my-home") {
    if (
      permissions.hasPermissionTo(permissionsValues.ACTION_EDIT_ABOUT_MY_HOME, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else if (to.name == "conveyancing-enquiries") {
    if (
      permissions.hasPermissionTo(permissionsValues.ACTION_EDIT_CONV_ENQUIRIES, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else if (to.name == "leasehold-information") {
    if (
      permissions.hasPermissionTo(permissionsValues.ACTION_EDIT_LEASEHOLD_INFO, role) ||
      permissions.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS, role)
    ) {
      return true;
    }
  } else {
    return true;
  }

  return false;
}
