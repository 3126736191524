<template>
  <div class="yes-no-question">
    <div class="yes-no-question-content">
      <BaseRadio
        v-for="(option, key) in options"
        :key="key"
        :label="option"
        :name="questionKey + '_radio'"
        :value="modelValue?.yes"
        :checked="modelValue && modelValue.yes?.toString() == key"
        @input="updateModel('yes', key)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { YesNoObject } from "@/types/Base/YesNoObject";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    modelValue: Object as PropType<YesNoObject>,
    options: Object,
    questionKey: String,
    customShowCriteria: {
      type: Boolean,
      default: false,
    },
    showCriteria: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateModel(key: string, val: string): void {
      this.$emit("update:modelValue", {
        key: key,
        val: val,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.yes-no-question {
  padding: 10px 35px;

  .yes-no-question-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;

    div {
      text-align: right;
      margin: 0;
    }
  }
}
</style>
