<template>
  <!-- <transition name="fade"> -->
  <div class="modal" v-if="isVisible">
    <div class="window" v-click-outside="closeOutside">
      <slot></slot>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script setup lang="ts">
import clickOutside from "click-outside-vue3";
import { ref, watch } from "vue";

const props = defineProps({
  clickOutsideClose: {
    type: Boolean,
    default: true,
  },
  shown: {
    type: Boolean,
    default: false,
  },
});

const isVisible = ref(false);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const vClickOutside = clickOutside.directive;

const emit = defineEmits(["close"]);

function open() {
  isVisible.value = true;
}
function close() {
  emit("close");
  isVisible.value = false;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function closeOutside() {
  if (props.clickOutsideClose) {
    close();
  }
}

watch(
  () => props.shown,
  (newValue) => {
    if (newValue) {
      open();
    } else {
      close();
    }
  }
);

defineExpose({
  open,
  close,
});
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  z-index: 3;

  .window {
    background: #fff;
    position: relative;
    border-radius: 15px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: calc(100vw - 100px);
    margin-left: auto;
    margin-right: auto;
    padding: 35px 35px 25px 35px;
    font-weight: 400;
    overflow-y: auto;
    max-height: calc(100vh - 30px);

    .close-btn {
      position: absolute;
      right: 4px;
      top: 3px;
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;

      .formulate-input {
        margin: 20px 0 0;
      }

      .cancel-btn {
        &.formulate-input[data-classification="button"] button {
          background-color: transparent;
          color: #000;
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .modal {
    .window {
      max-width: calc(100vw - 25px);
      padding: 25px 25px 15px 25px;
    }
  }
}

@media screen and (max-width: 500px) {
  .modal {
    .window {
      padding: 15px 15px 05px 15px;
    }
  }
}
</style>
