import axios from "axios";
import store from "@/store";
import permissionsValues from "@/helpers/permissionsValues";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // True for accessing basic auth protected back end on different subdomain
  // withCredentials: process.env.VUE_APP_THEME == "xxx" && process.env.VUE_APP_API_ENDPOINT.indexOf("staging") != -1,
});

apiClient.interceptors.request.use(async function (options) {
  // For accessing basic auth protected back end on different subdomain
  // if (process.env.VUE_APP_THEME == "lawsociety" && process.env.VUE_APP_API_ENDPOINT.indexOf("staging") != -1) {
  //   // TODO: Remove me when this goes live
  //   options.auth = {
  //     username: process.env.VUE_APP_BASIC_USER,
  //     password: process.env.VUE_APP_BASIC_PASS,
  //   };
  // }
  return options;
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 440 || error.response.status === 401) {
      logOut(error.response.status === 401 ? error.response.data.message : "");
    }
    return Promise.reject(error);
  }
);

let auth0Client = {};

// This is how we get auth0 from component to service
export const addAuth0 = (auth0) => {
  auth0Client = auth0;
  apiClient.interceptors.request.use(async (options) => {
    if (auth0Client.isAuthenticated.value) {
      const token = await auth0Client.getAccessTokenSilently();

      if (options && options.headers) {
        options.headers["AuthorizationJWT"] = token;
      }
    }
    return options;
  });
};

export function getAuth0IsLoading() {
  if (auth0Client) {
    return auth0Client.isLoading.value;
  } else {
    return true;
  }
}

export function getAuth0IsAuthenticated() {
  if (auth0Client) {
    return auth0Client.isAuthenticated.value;
  } else {
    return false;
  }
}

export const logOut = function (message = "") {
  if (auth0Client.isAuthenticated.value) {
    let returnTo = window.location.origin + "/auth";
    if (message) {
      returnTo += "?msg=" + encodeURIComponent(message);
    }
    auth0Client.logout({
      logoutParams: {
        returnTo: returnTo,
      },
    });
  }
};

export default {
  uploadFile(data, destination, hideLoader = false) {
    return apiClient.post("/upload/" + destination, data, {
      headers: {
        "Content-Type": `'multipart/form-data';`,
      },
      hideLoader: hideLoader,
    });
  },
  removeUpload(id, payload) {
    return apiClient.post("/removeupload/" + id, payload);
  },
  search(address) {
    return apiClient.post("/search", address);
  },
  addProperty(titleNumber, requestId, address, parentId, altoId = "") {
    return apiClient.post("/property", {
      TitleNumber: titleNumber,
      RequestId: requestId,
      // data: JSON.stringify(OptionsService.blankProperty()),
      address: JSON.stringify(address),
      parentId: parentId || "0",
      altoId: altoId,
    });
  },
  getProperties(filters = {}) {
    let queryParams = [];

    for (let key in filters) {
      let value = filters[key];

      if (value === null || value === undefined) {
        continue;
      }

      if (value === false) {
        value = 0;
      } else if (value === true) {
        value = 1;
      }
      queryParams.push(key + "=" + value);
    }

    const queryString = queryParams.length ? "?" + queryParams.join("&") : "";
    return apiClient.get("/properties" + queryString);
  },
  getProperty(id) {
    return apiClient.get("/property/" + id);
  },
  updateProperty(id, data) {
    return apiClient.put("/property/" + id, {
      data: JSON.stringify(data),
    });
  },
  acceptTcs() {
    return apiClient.put("/accept");
  },
  inviteSellerToProperty(id, email, theme) {
    return apiClient.post("/inviteseller/" + id, {
      email: email,
      theme: theme,
    });
  },
  inviteSolicitorToProperty(propertyId, solicitorId, theme) {
    return apiClient.post("/invitesolicitor/" + propertyId, {
      solicitor_id: solicitorId,
      theme: theme,
    });
  },
  inviteAgentToProperty(propertyId, agentId, theme) {
    return apiClient.post("/inviteagent/" + propertyId, {
      agent_id: agentId,
      theme: theme,
    });
  },
  reportsClientSpend(dateString) {
    return apiClient.get("/reports/clientspend/" + dateString);
  },
  reportsPropAttributes() {
    return apiClient.get("/reports/propertyattributes");
  },
  reportsPropAttributesRefresh() {
    return apiClient.post("/reports/propertyattributes");
  },
  sendEnquiry(id, name, message) {
    return apiClient.post("/enquiry/" + id, {
      name: name,
      message: message,
    });
  },
  notifyAgentComplete(id, theme) {
    return apiClient.post("/notifyagent/" + theme + "/" + id);
  },
  getAgencies() {
    return apiClient.get("/agencies");
  },
  getSolicitorsFirms() {
    return apiClient.get("/solicitorsfirm");
  },
  getSolicitorsByFirm(firmId) {
    return apiClient.get("/solicitors/" + firmId);
  },
  getSolicitors() {
    return apiClient.get("/solicitors");
  },
  getBrands() {
    return apiClient.get("/brands");
  },
  getAgents() {
    return apiClient.get("/agents");
  },
  getAgentsByAgency(agencyId) {
    return apiClient.get("/agents/" + agencyId);
  },
  getMovers() {
    return apiClient.get("/movers");
  },
  addSolicitorsFirm(name, brand) {
    return apiClient.post("/solicitorsfirm", {
      name: name,
      brand: brand,
    });
  },
  addSolicitor(name, email, firm) {
    return apiClient.post("/solicitor", {
      name: name,
      email: email,
      firm: firm,
    });
  },
  addAgency(name, brand, default_phase) {
    return apiClient.post(
      "/agency",
      Object.assign(
        {
          name: name,
        },
        brand ? { brand: brand } : null,
        default_phase ? { default_phase: default_phase } : null
      )
    );
  },
  saveAgency(id, newTa6Trial) {
    return apiClient.put("/agency/" + id, {
      new_ta6_trial: newTa6Trial,
    });
  },
  saveSolicitorsFirm(id, newTa6Trial) {
    return apiClient.put("/solicitorsfirm/" + id, {
      new_ta6_trial: newTa6Trial,
    });
  },
  addAgent(name, email, agency, agentType = permissionsValues.USER_TYPE_AGENT) {
    return apiClient.post("/agent", {
      name: name,
      email: email,
      agency: agency,
      agentType: agentType,
    });
  },
  getBrand() {
    return apiClient.get("/brand");
  },
  getOrganisation(id) {
    return apiClient.get("/organisation/" + id);
  },
  getReport(propertyId, reportPath, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/" + reportPath,
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getReportTemplate(propertyId, reportPath, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/" + reportPath + "/template",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getDeedEntriesReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/deed-entries",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getConveyancingEnquiriesReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/conveyancing-enquiries",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getMaterialInfoReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/material-info",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getPropertyInfoReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/info",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getFittingsContentsReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/fittings",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getLeaseholdReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/leasehold",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getSellersCertificateReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/sellers-certificate",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getAboutMyHomeReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/about",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getPreliminaryInfoReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/preliminary-info",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getMaterialInfoSummaryReport(propertyId, themeId = store.state.ui.themeId) {
    return apiClient.post(
      "/docs/" + propertyId + "/" + themeId + "/material-info-summary",
      {},
      {
        responseType: "arraybuffer",
        hideLoader: true,
      }
    );
  },
  getQuestionnaire(propertyId, questionnaireId) {
    return apiClient.get("/questionnaire/" + propertyId + "/" + questionnaireId);
  },
  saveQuestionnaire(propertyId, questionnaireId, answers) {
    return apiClient.post("/questionnaire/" + propertyId + "/" + questionnaireId, {
      answers: answers,
    });
  },
  completeQuestionnaire(propertyId, questionnaireId) {
    return apiClient.post("/questionnaire/" + propertyId + "/" + questionnaireId + "/complete");
  },
  signQuestionnaire(attemptId, data) {
    return apiClient.post("/questionnaire/" + attemptId + "/sign", data);
  },
  searchAlto(postCode) {
    return apiClient.post("/alto/search/" + postCode);
  },
  getPropertySignatures(id) {
    return apiClient.get("questionnaire/" + id + "/signatures");
  },
  orderSearches(id) {
    return apiClient.post("/property/" + id + "/request-searches/");
  },
  getMonthlyHopLimit() {
    return apiClient.get("/admin/monthly-limit");
  },
  setMonthlyHopLimit(limit) {
    return apiClient.post("/admin/monthly-limit", {
      value: limit,
    });
  },
  // updateAgency(id, agency) {
  //   return apiClient.put("/propertyagency/" + id + "/" + agency);
  // },
  getInvitedSellers(id) {
    return apiClient.get("/property/" + id + "/invitedsellers");
  },
  deleteInvitedSeller(sellerId, propertyId) {
    return apiClient.delete("/property/" + propertyId + "/seller/" + sellerId);
  },
  resendSellerInvite(sellerId, propertyId) {
    return apiClient.post("/property/" + propertyId + "/seller/" + sellerId + "/resend");
  },
  deleteProperty(id) {
    return apiClient.delete("/property/" + id);
  },
  getPropertyDetails(id) {
    return apiClient.get("/property/" + id + "/details");
  },
  getOwners(id) {
    return apiClient.get("/property/" + id + "/owners");
  },
  getCreators(id) {
    return apiClient.get("/property/" + id + "/creators");
  },
  updatePropertyOwner(id, owner_id) {
    return apiClient.put("/property/" + id + "/owner", {
      owner_id: owner_id,
    });
  },
  updatePropertyPhase(id, phase_id) {
    return apiClient.post("/property/" + id + "/phase/" + phase_id);
  },
  updatePropertyCreator(id, creator_id) {
    return apiClient.put("/property/" + id + "/creator", {
      creator_id: creator_id,
    });
  },
  transferAgentAndProperties(id, organisation_id) {
    return apiClient.put("/agent/" + id + "/organisation", {
      organisation_id: organisation_id,
    });
  },
  transferAgentLeaveProperties(id, organisation_id, take_over_agent_id) {
    return apiClient.put("/agent/" + id + "/organisation", {
      organisation_id: organisation_id,
      take_over_agent_id: take_over_agent_id,
    });
  },
  deleteAgent(id) {
    return apiClient.delete("/agent/" + id);
  },
  deleteAgentTransferProperties(id, new_agent_id) {
    return apiClient.put("/agent/" + id + "/transfer-remove", {
      new_agent_id: new_agent_id,
    });
  },
  addPropertyByTitleNumber(titleNumber, agentId, agencyId) {
    const data = {
      title_number: titleNumber,
      agent_id: agentId,
    };
    if (agencyId > 0) data.agency_id = agencyId;
    return apiClient.put("/property/title/add", data);
  },
  deleteSignature(id) {
    return apiClient.delete("/signature/" + id + "/unsign");
  },
  getPhases() {
    return apiClient.get("/phases");
  },
};
