import axios from "axios";

const params = "?api-key=PXfZc2mHkEaG3sJA80D20g26450&expand=true";

const apiClient = axios.create({
  baseURL: "https://api.getAddress.io/",
  withCredentials: false,
});

export default {
  getAddresses(postcode) {
    return apiClient.get("/find/" + postcode + params);
  },
};
