<template>
  <div class="question-content tiles">
    <BaseRadio
      v-for="(option, key) in options"
      :key="key"
      :label="option"
      :name="id + '_radio'"
      :checked="model == key"
      :value="model"
      @input="updateModel(key)"
    />
  </div>
</template>

<script>
export default {
  props: {
    options: Object,
    model: [String, Number],
  },
  methods: {
    updateModel(val) {
      this.$emit("input", val);
    },
  },
  data() {
    return {
      id: this._uid,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-content {
  margin: 0 25px;
}
</style>
