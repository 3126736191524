<template>
  <div class="preliminary-information">
    <div class="preliminary-information-header">
      <img src="@/assets/theme/lawsociety/law-society-provider-logo.png" class="ls-logo" alt="The Law Society" />
      <h1>Law Society Preliminary Information form</h1>
      <p>
        This form is completed by the seller to supply the detailed information and documents which may be relied upon for the
        conveyancing process.
      </p>
      <p>Your changes will be saved automatically as you go along.</p>
      <p>
        HOP pre-fills as many of the answers to these questions as possible, but please check all answers are correct before
        completing the form.
      </p>
    </div>
    <div class="forms" v-if="model">
      <PreliminaryPropertyBeingSold @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryOwnership @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryTypeOfOwnership @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryNewBuildsConversions @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryTiming @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryPropertyAlterations
        @updateModelLevel3="updateModelLevel3"
        @updateModelLevel2="updateModelLevel2"
        @updateModelLevel1="updateModelLevel1"
      />
      <PreliminaryPotentialLiabilities @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminarySolarPanels @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryProtectedBuildings @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryProtectedTrees @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryConsent @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryCharges
        @updateModelLevel3="updateModelLevel3"
        @updateModelLevel2="updateModelLevel2"
        @updateModelLevel1="updateModelLevel1"
      />
      <PreliminaryAccessRoadsFootPaths
        @updateModelLevel3="updateModelLevel3"
        @updateModelLevel2="updateModelLevel2"
        @updateModelLevel1="updateModelLevel1"
      />
      <PreliminaryServices @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminarySharedFacilities @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryParking @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryOccupiers @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryFlooding @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryRightToUse @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryDisputes @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
      <PreliminaryOtherInformation @updateModelLevel2="updateModelLevel2" @updateModelLevel1="updateModelLevel1" />
    </div>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to control panel </BaseButton>
    <CompletePrompt :complete="model && model.complete" completeAction="completePreliminaryInformationForm" :taForm="true" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompletePrompt from "@/components/CompletePrompt.vue";
import PreliminaryPropertyBeingSold from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryPropertyBeingSold.vue";
import PreliminaryOwnership from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryOwnership.vue";
import PreliminaryTypeOfOwnership from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryTypeOfOwnership.vue";
import PreliminaryNewBuildsConversions from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryNewBuildsConversions.vue";
import PreliminaryTiming from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryTiming.vue";
import PreliminaryPropertyAlterations from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryPropertyAlterations.vue";
import PreliminaryPotentialLiabilities from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryPotentialLiabilities.vue";
import PreliminarySolarPanels from "@/views/ConveyancingForms/PreliminaryInformation/PreliminarySolarPanels.vue";
import PreliminaryProtectedBuildings from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryProtectedBuildings.vue";
import PreliminaryProtectedTrees from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryProtectedTrees.vue";
import PreliminaryConsent from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryConsent.vue";
import PreliminaryCharges from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryCharges.vue";
import PreliminaryAccessRoadsFootPaths from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryAccessRoadsFootPaths.vue";
import PreliminaryServices from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryServices.vue";
import PreliminarySharedFacilities from "@/views/ConveyancingForms/PreliminaryInformation/PreliminarySharedFacilities.vue";
import PreliminaryParking from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryParking.vue";
import PreliminaryOccupiers from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryOccupiers.vue";
import PreliminaryFlooding from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryFlooding.vue";
import PreliminaryRightToUse from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryRightToUse.vue";
import PreliminaryDisputes from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryDisputes.vue";
import PreliminaryOtherInformation from "@/views/ConveyancingForms/PreliminaryInformation/PreliminaryOtherInformation.vue";
import { getRemainingLeaseYears } from "@/helpers/utils";

export default defineComponent({
  components: {
    PreliminaryPropertyBeingSold,
    PreliminaryOwnership,
    CompletePrompt,
    PreliminaryTypeOfOwnership,
    PreliminaryNewBuildsConversions,
    PreliminaryTiming,
    PreliminaryPropertyAlterations,
    PreliminaryPotentialLiabilities,
    PreliminarySolarPanels,
    PreliminaryProtectedBuildings,
    PreliminaryProtectedTrees,
    PreliminaryConsent,
    PreliminaryCharges,
    PreliminaryAccessRoadsFootPaths,
    PreliminaryServices,
    PreliminarySharedFacilities,
    PreliminaryParking,
    PreliminaryOccupiers,
    PreliminaryFlooding,
    PreliminaryRightToUse,
    PreliminaryDisputes,
    PreliminaryOtherInformation,
  },
  data() {
    return {
      options: this.$store.state["options"].options.property_information,
      // eslint-disable-next-line
      model: null as any,
      preventFireworks: false,
    };
  },
  mounted(): void {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id).then(() => {
      this.model = this.$store.state["property"].property.data.conveyancing_forms.preliminary_information;

      if (this.model.accepted == true) {
        this.$router.push({ name: "dashboard" });
      }

      var formBlank = true;
      for (var i = 1; i < 21; i++) {
        if (this.model.progress[i] != 0) {
          formBlank = false;
          break;
        }
      }
      if (formBlank) {
        this.getDeedsAnswers();
      }
      window.scrollTo(0, 0);
    });
  },
  computed: {
    showFireworks(): boolean[] | null {
      if (this.model && this.model.progress) {
        return [
          this.model.progress[1] == 100,
          this.model.progress[2] == 100,
          this.model.progress[3] == 100,
          // this.model.progress[4] == 100,
          this.model.progress[5] == 100,
          this.model.progress[6] == 100,
          this.model.progress[7] == 100,
          this.model.progress[8] == 100,
          this.model.progress[9] == 100,
          this.model.progress[10] == 100,
          this.model.progress[11] == 100,
          this.model.progress[12] == 100,
          this.model.progress[13] == 100,
          this.model.progress[14] == 100,
          this.model.progress[15] == 100,
          this.model.progress[16] == 100,
          this.model.progress[17] == 100,
          this.model.progress[18] == 100,
          this.model.progress[19] == 100,
          this.model.progress[20] == 100,
          this.model.progress[21] == 100,
        ];
      }
      return null;
    },
    theme(): string {
      return this.$store.state["ui"].theme;
    },
  },
  watch: {
    showFireworks(newValue, oldValue) {
      if (!this.preventFireworks) {
        if (newValue == null || oldValue == null) {
          return;
        }
        for (var i = 0; i < newValue.length; i++) {
          if (!oldValue[i] && newValue[i]) {
            this.$store.dispatch("showAlert", "Section complete!");
            break;
          }
        }
      }
    },
  },
  methods: {
    updateModelLevel3(questionKey: string, index: string, propKey: string, val: string): void {
      this.$nextTick(function () {
        this.$store.dispatch("updatePreliminaryInformationQuestion", {
          questionKey: questionKey,
          index: index,
          propKey: propKey,
          value: val,
        });
      });
    },
    updateModelLevel2(questionKey: string, propKey: string, val: string): void {
      this.$nextTick(function () {
        this.$store.dispatch("updatePreliminaryInformationQuestion", {
          questionKey: questionKey,
          propKey: propKey,
          value: val,
        });
      });
    },
    updateModelLevel1(questionKey: string, val: string, updateProgressImmediately = false): void {
      this.$store.dispatch("updatePreliminaryInformationQuestion", {
        questionKey: questionKey,
        value: val,
        updateProgressImmediately: updateProgressImmediately,
      });
    },
    getDeedsAnswers() {
      this.preventFireworks = true;
      // Address
      const property = this.$store.state["property"].property;
      const questions = property.data.conveyancing_forms.preliminary_information.questions;
      if (!questions["1_1"]) {
        if (property.data.passport.property.address[1]) {
          this.updateModelLevel1(
            "1_1",
            property.data.passport.property.address[1] +
              "\n" +
              property.data.passport.property.address[2] +
              "\n" +
              property.data.passport.property.address[3],
            true
          );
        }
      }

      if (!questions["1_1_i"]) {
        if (property.data.passport.property.address[1]) {
          this.updateModelLevel1("1_1_i", property.data.passport.property.address[0], true);
        }
      }

      if (property.data.passport.current_proprietorship_date) {
        let date = property.data.passport.current_proprietorship_date.split("-");
        if (!questions["2_1_i"]) {
          this.updateModelLevel1("2_1_i", date[1], true);
        }
        if (!questions["2_1_ii"]) {
          this.updateModelLevel1("2_1_ii", date[0], true);
        }
      }

      if (!questions["3_1"]) {
        if (property.data.passport.tenure_title_code == 10) {
          this.updateModelLevel1("3_1", "1", true);
        } else if (property.data.passport.tenure_title_code == 60) {
          this.updateModelLevel1("3_1", "2", true);
        }
      }

      if (!questions["3_2_1"]) {
        if (property.data.passport.leaseTerm) {
          const leaseYears = getRemainingLeaseYears(property.data.passport.leaseTerm, new Date());
          if (leaseYears) {
            this.updateModelLevel1("3_2_1", leaseYears.toString(), true);
          }
        }
      }

      this.$nextTick(function () {
        this.preventFireworks = false;
      });
    },
  },
});
</script>

<style lang="scss">
@import "@/styles/_conveyancing_form.scss";

.preliminary-information {
  padding: $container-spacing-vertical 0 $container-spacing-vertical + 10;
  .preliminary-information-header {
    h1 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}
</style>
