<template>
  <QuestionnaireForm :questionnaireId="2" :formPath="'info'">
    <div class="">
      <img src="@/assets/theme/lawsociety/law-society-provider-logo.png" class="ls-logo" alt="The Law Society" />
      <h1>Law Society Property Information form</h1>
      <p>
        This form is completed by the seller to supply the detailed information and documents which may be relied upon for the
        conveyancing process.
      </p>
      <p>Unsure about anything?</p>
      <BaseButton @click="showModal('guideModal')">Read our guide</BaseButton>
    </div>
    <BaseModal ref="guideModal">
      <h1>Definitions</h1>
      <ul>
        <li>‘Seller’ means all sellers together where the property is owned by more than one person.</li>
        <li>‘Buyer’ means all buyers together where the property is being bought by more than one person.</li>
        <li>‘Property’ includes all buildings and land within its boundaries.</li>
      </ul>
      <h1>Instructions to the seller</h1>
      <ul>
        <li>
          The answers should be prepared by the person or persons who are named as owner on the deeds or Land Registry title or by
          the owner’s legal representative(s) if selling under a power of attorney or grant of probate or representation. If there
          is more than one seller, you should prepare the answers together or, if only one seller prepares the form, the other(s)
          should check the answers given and all sellers should sign the form.
        </li>
        <li>
          If you do not know the answer to any question, you must say so. If you are unsure of the meaning of any questions or
          answers, please ask your solicitor. Completing this form is not mandatory, but omissions or delay in providing some
          information may delay the sale.
        </li>
        <li>
          If you later become aware of any information which would alter any replies you have given, you must inform your
          solicitor immediately. This is as important as giving the right answers in the first place. Do not change any
          arrangements concerning the property with anyone (such as a tenant or neighbour) without first consulting your
          solicitor.
        </li>
        <li>
          It is very important that your answers are accurate. If you give incorrect or incomplete information to the buyer (on
          this form or otherwise in writing or in conversation, whether through your estate agent or solicitor or directly to the
          buyer), the buyer may make a claim for compensation from you or refuse to complete the purchase.
        </li>
        <li>
          You should answer the questions based upon information known to you (or, in the case of legal representatives, you or
          the owner). You are not expected to have expert knowledge of legal or technical matters, or matters that occurred prior
          to your ownership of the property.
        </li>
        <li>
          Please give your solicitor any letters, agreements or other papers which help answer the questions. If you are aware of
          any which you are not supplying with the answers, tell your solicitor. If you do not have any documentation you may need
          to obtain copies at your own expense. Also pass to your solicitor any notices you have received concerning the property
          and any which arrive at any time before completion of the sale.
        </li>
      </ul>
    </BaseModal>
  </QuestionnaireForm>
</template>

<script>
import { defineComponent } from "vue";
import QuestionnaireForm from "@/components/Forms/Questionnaires/QuestionnaireForm.vue";

export default defineComponent({
  components: {
    QuestionnaireForm,
  },
  methods: {
    showModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs[name].open();
    },
  },
});
</script>

<style lang="scss"></style>
