<template>
  <div>
    <FormSection
      title="13. Access roads and footpaths"
      :complete="model.preliminary_information.progress[13]"
      :bottomBorder="true"
    >
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">13.1</span>
              <span> {{ options.preliminary_information.questions["13_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="13_1"
              @input="updateModelLevel2('13_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['13_1']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['13_1']['yes'] == 1">
              Please select which payments are required.
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['13_1']['yes'] == 1">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['13_1']['items']"
                :options="options.preliminary_information.options['13_1']"
                @update:modelValue="updateModelLevel2('13_1', 'items', $event)"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['13_1']['items'].length == 0 }">
            <div v-for="(item, key) in model.preliminary_information.questions['13_1']['items']" :key="key">
              <p class="question-title">
                <span>Please give details about payments for "{{ options.preliminary_information.options["13_1"][item] }}".</span>
              </p>

              <div class="question-content">
                <BaseInput
                  :modelValue="
                    model.preliminary_information.questions['13_1_i'][
                      model.preliminary_information.questions['13_1']['items'][key]
                    ]['cost']
                  "
                  @update:modelValue="
                    $emit(
                      'updateModelLevel3',
                      '13_1_i',
                      model.preliminary_information.questions['13_1']['items'][key],
                      'cost',
                      $event
                    )
                  "
                  type="number"
                  contentBefore="£"
                  placeholder="Cost"
                />
                <BaseInput
                  :modelValue="
                    model.preliminary_information.questions['13_1_i'][
                      model.preliminary_information.questions['13_1']['items'][key]
                    ]['payee']
                  "
                  @update:modelValue="
                    $emit(
                      'updateModelLevel3',
                      '13_1_i',
                      model.preliminary_information.questions['13_1']['items'][key],
                      'payee',
                      $event
                    )
                  "
                  type="text"
                  placeholder="Who payments are made to"
                />
              </div>
            </div>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">13.2</span>
              <span> {{ options.preliminary_information.questions["13_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="13_2"
              @input="updateModelLevel2('13_2', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['13_2']['yes']"
            />
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">13.3</span>
              <span> {{ options.preliminary_information.questions["13_3"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="13_3"
              @input="updateModelLevel2('13_3', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['13_3']['yes']"
            />
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import _find from "lodash/find";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
  data() {
    return {
      slideHiders: {
        "13_1": "",
      },
    };
  },
  methods: {
    updateModelLevel2(questionKey: string, propKey: string, val: string): void {
      this.$emit("updateModelLevel2", questionKey, propKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
  },
});
</script>
