export default {
  USER_TYPE_ADMIN: "100",
  USER_TYPE_SIMPLE: "20",
  USER_TYPE_SOLICITOR: "4",
  USER_TYPE_BRAND: "3",
  USER_TYPE_AGENT: "2",
  USER_TYPE_MOVER: "1",

  ACTION_CREATE_PROP: "create_prop",
  ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT: "create_prop_with_other_parent",
  ACTION_LIST_PROPS: "list_props",
  ACTION_VIEW_ADMIN: "view_admin",
  ACTION_EDIT_FIXTURES_FITTINGS: "view_fixtures_fittings",
  ACTION_CREATE_AGENCY: "create_agency",
  ACTION_VIEW_ALL_AGENCIES: "view_all_agencies",
  ACTION_VIEW_AGENCIES: "view_agencies",
  EDIT_TA6_TRIAL: "edit_ta6_trial",
  ACTION_VIEW_BRANDS: "list_brands",
  ACTION_VIEW_BRAND: "view_brand",
  ACTION_CREATE_AGENT: "create_agent",
  ACTION_CREATE_SIMPLE_AGENT: "create_simple_agent",
  ACTION_VIEW_SOLICITORS: "view_solicitors",
  ACTION_VIEW_MOVERS: "view_movers",
  ACTION_VIEW_ALL_MOVERS: "view_all_movers",
  ACTION_VIEW_STATS: "view_stats",
  ACTION_VIEW_ALL_AGENTS: "view_all_agents",
  ACTION_VIEW_ALL_SOLICITORS: "view_all_solicitors",
  ACTION_VIEW_PROP_META: "view_prop_meta",
  ACTION_VIEW_PROP_COMPLETION: "view_prop_completion",
  ACTION_VIEW_AGENCIES_UNDER_BRAND: "view_agencies_under_brand",
  ACTION_VIEW_CONVEYANCING_ENQUIRIES: "view_conveyancing_enquiries",
  ACTION_INVITE_SELLER: "invite_seller",
  ACTION_INVITE_SOLICITOR: "invite_solicitor",
  ACTION_INVITE_AGENT: "invite_agent",
  ACTION_INVITE_TO_OTHER_ORGANISATIONS_PROPERTY: "invite_to_other_organisations_property",
  EDIT_PROP_INFO: "edit_prop_info",
  ACTION_EDIT_LEASEHOLD_INFO: "edit_leasehold_info",
  ACTION_EDIT_PRELIMINARY_INFO: "edit_preliminary_info",
  EDIT_PROP_INFO_SERVICES: "edit_prop_info_services",
  EDIT_PROP_INFO_UTILS_SERVICES: "edit_prop_info_utils_services",
  REPORT_PROP_ATTRIBUTES: "report_prop_attr",
  ACTION_VIEW_MATERIAL_INFO: "view_material_info",
  ACTION_VIEW_MOVEREADY_SCORE: "view_moveready_score",
  ACTION_VIEW_ACTION_PLAN: "view_action_plan",
  ACTION_VIEW_SELLERS_CERTIFICATE: "view_sellers_certificate",
  ACTION_VIEW_CONV_ENQUIRIES: "view_conv_enquiries",
  ACTION_VIEW_ABOUT_MY_HOME: "view_about_my_home",
  ACTION_EDIT_CONV_ENQUIRIES: "edit_conv_enquiries",
  ACTION_EDIT_ABOUT_MY_HOME: "edit_about_my_home",
  ACTION_VIEW_TA6: "view_ta6",
  ACTION_VIEW_TA7: "view_ta7",
  ACTION_VIEW_TA10: "view_ta10",
  ACTION_VIEW_DEED_ENTRIES_DETAILS: "view_deed_entries_details",
  ACTION_VIEW_AGENCIES_CREDITS: "view_agencies_credits",
  ACTION_VIEW_PROPERTY_SIGNATURES: "view_property_signatures",
  ACTION_REQUEST_SEARCHES: "request_searches",
  ADMIN_ONLY: "admin_only",
  ACTION_UPLOAD_FILES_TO_PROPERTY: "upload_files_to_property",
  ACTION_SET_GLOBAL_ADMIN_OPTIONS: "set_global_admin_options",
  ACTION_READ_ONLY_FORM_ACCESS: "read_only_form_access",
};
