<template>
  <div>
    <FormSection title="5. Timing" :complete="model.preliminary_information.progress[5]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">5.1</span>
              <span> {{ options.preliminary_information.questions["5_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="$emit('updateModelLevel2', '5_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['5_1']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['5_1']['yes'] == 1">
              {{ options.preliminary_information.questions["5_1_i"] }}
            </p>
            <MultipleChoiceQuestion
              v-if="model.preliminary_information.questions['5_1']['yes'] == 1"
              @input="$emit('updateModelLevel2', '5_1', 'option', $event)"
              :options="options.preliminary_information.options['5_1']"
              :model="model.preliminary_information.questions['5_1']['option']"
            />
            <div class="question-content" v-if="model.preliminary_information.questions['5_1']['yes'] == 1">
              <BaseTextArea
                v-if="model.preliminary_information.questions['5_1']['option'] == 5"
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['5_1']['other']"
                @update:modelValue="$emit('updateModelLevel2', '5_1', 'other', $event)"
                type="text"
                placeholder="Enter details"
              />
            </div>
            <p class="question-title" v-if="model.preliminary_information.questions['5_1']['yes'] == 1">
              {{ options.preliminary_information.questions["5_1_ii"] }}
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['5_1']['yes'] == 1">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['5_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '5_1', 'details', $event)"
                type="text"
                placeholder="Enter details"
              />
            </div>
            <p
              class="question-title"
              v-if="
                model.preliminary_information.questions['5_1']['yes'] == 1 &&
                (model.preliminary_information.questions['5_1']['option'] == 3 ||
                  model.preliminary_information.questions['5_1']['option'] == 4)
              "
            >
              {{ options.preliminary_information.questions["5_1_iii"] }}
            </p>
            <div
              class="question-content"
              v-if="
                model.preliminary_information.questions['5_1']['yes'] == 1 &&
                (model.preliminary_information.questions['5_1']['option'] == 3 ||
                  model.preliminary_information.questions['5_1']['option'] == 4)
              "
            >
              <BaseSelect
                initial="Month"
                class="select"
                :options="months"
                @input="$emit('updateModelLevel2', '5_1', 'month', $event)"
                :value="model.preliminary_information.questions['5_1']['month']"
              />
              <BaseSelect
                initial="Year"
                class="select"
                :options="yearsFrom1970"
                @input="$emit('updateModelLevel2', '5_1', 'year', $event)"
                :value="model.preliminary_information.questions['5_1']['year']"
              />
            </div>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">5.2</span>
              <span> {{ options.preliminary_information.questions["5_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="$emit('updateModelLevel2', '5_2', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['5_2']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['5_2']['yes'] == 1">
              <span> {{ options.preliminary_information.questions["5_2_i"] }}</span>
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['5_2']['yes'] == 1">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['5_2'].items"
                :options="options.preliminary_information.options['5_2']"
                @update:modelValue="$emit('updateModelLevel2', '5_2', 'items', $event)"
              />
              <BaseTextArea
                v-if="model.preliminary_information.questions['5_2']['items'].includes(8)"
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['5_2']['details']"
                @update:modelValue="$emit('updateModelLevel2', '5_2', 'details', $event)"
                type="text"
                placeholder="Enter details"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import OptionsService from "@/services/OptionsService";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
  data() {
    return {
      passport: this.$store.state["property"].property.data.passport,
      yearsFrom1970: OptionsService.yearsFrom1970(),
      months: OptionsService.allMonths(),
    };
  },
});
</script>
