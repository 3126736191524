<template>
  <div class="reports">
    <h1>Property Attributes Insights</h1>
    <!-- <BaseButton @click="downloadCsv()">Export CSV (Excel)</BaseButton> -->
    <p>These stats may take a few minutes to compile, so they only update when the button below is clicked.</p>
    <BaseButton @click="refresh()">Refresh statistics</BaseButton>
    <div v-if="showFailure" class="note failure">
      {{ failureMessage }}
    </div>
    <div v-if="showSuccess" class="note success">Updated successfully</div>
    <BaseCheckbox label="Show number of properties" :value="showNumbers" @input="showNumbers = $event" />
    <h2>Averages</h2>
    <table>
      <tr>
        <td>Complexity at start</td>
        <td>{{ propAttributes.averages.currentComplexity }}%</td>
      </tr>
      <tr>
        <td>Predicted timescale</td>
        <td>{{ propAttributes.averages.predictedTimescale }} weeks</td>
      </tr>
      <tr>
        <td>Issues affecting conveyancing complexity</td>
        <td>{{ propAttributes.averages.complexityIssues }}</td>
      </tr>
    </table>
    <h2>Physical structural characteristics</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.propTypePropCount }} p</span><span v-if="!showNumbers">P</span>roperties
      which have About My Home completed.
    </p>
    <table>
      <tr>
        <th>Property Type</th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>House terraced</td>
        <td>{{ propAttributes.percentages.type.terraced }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.terraced }}</td>
      </tr>
      <tr>
        <td>House semi</td>
        <td>{{ propAttributes.percentages.type.semi }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.semi }}</td>
      </tr>
      <tr>
        <td>House detached</td>
        <td>{{ propAttributes.percentages.type.detached }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.detached }}</td>
      </tr>
      <tr>
        <td>Bungalow</td>
        <td>{{ propAttributes.percentages.type.bungalow }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.bungalow }}</td>
      </tr>
      <tr>
        <td>Flat purpose built block less than 4 storeys high</td>
        <td>{{ propAttributes.percentages.type.flat1 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.flat1 }}</td>
      </tr>
      <tr>
        <td>Flat purpose built block 4 storeys or higher</td>
        <td>{{ propAttributes.percentages.type.flat2 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.flat2 }}</td>
      </tr>
      <tr>
        <td>Flat converted building with at least 4 flats (including mine)</td>
        <td>{{ propAttributes.percentages.type.flat3 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.flat3 }}</td>
      </tr>
      <tr>
        <td>Flat converted building with less than 4 flats (including mine)</td>
        <td>{{ propAttributes.percentages.type.flat4 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.flat4 }}</td>
      </tr>
      <tr>
        <td>Other</td>
        <td>{{ propAttributes.percentages.type.other }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.type.other }}</td>
      </tr>
      <tr>
        <td>Element of non standard construction (eg Thatched Roof, concrete prefab, single brick etc)</td>
        <td>{{ propAttributes.percentages.nonstandard }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.nonstandard }}</td>
      </tr>
    </table>

    <h2>Availability of Services</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.ta6CompleteCount }} p</span><span v-if="!showNumbers">P</span>roperties
      which have TA6 completed.
    </p>
    <table>
      <tr>
        <th>Service</th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>Mains Electricity</td>
        <td>{{ propAttributes.percentages.services.electricity }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.electricity }}</td>
      </tr>
      <tr>
        <td>Mains Gas</td>
        <td>{{ propAttributes.percentages.services.gas }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.gas }}</td>
      </tr>
      <tr>
        <td>Mains Water</td>
        <td>{{ propAttributes.percentages.services.water }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.water }}</td>
      </tr>
      <tr>
        <td>Mains Sewerage</td>
        <td>{{ propAttributes.percentages.services.sewerage }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.sewerage }}</td>
      </tr>
      <tr>
        <td>Telephone land line</td>
        <td>{{ propAttributes.percentages.services.telephone }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.telephone }}</td>
      </tr>
      <tr>
        <td>Cable</td>
        <td>{{ propAttributes.percentages.services.cable }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.cable }}</td>
      </tr>
      <tr>
        <td>Broadband</td>
        <td>{{ propAttributes.percentages.services.broadband }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.services.broadband }}</td>
      </tr>
    </table>

    <h2>Broadband type</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.counts.services.broadband }} p</span
      ><span v-if="!showNumbers">P</span>roperties which have broadband.
    </p>
    <table v-if="propAttributes.counts.broadband">
      <tr>
        <th>Type</th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>ADSL</td>
        <td>{{ propAttributes.percentages.broadband.adsl }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.broadband.adsl }}</td>
      </tr>
      <tr>
        <td>Cable</td>
        <td>{{ propAttributes.percentages.broadband.cable }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.broadband.cable }}</td>
      </tr>
      <tr>
        <td>Fibre to the premises</td>
        <td>{{ propAttributes.percentages.broadband.fttp }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.broadband.fttp }}</td>
      </tr>
      <tr>
        <td>Fibre to the cabinet</td>
        <td>{{ propAttributes.percentages.broadband.fttc }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.broadband.fttc }}</td>
      </tr>
      <tr>
        <td>Don't know</td>
        <td>{{ propAttributes.percentages.broadband.unknown }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.broadband.unknown }}</td>
      </tr>
    </table>

    <h2>Tenure type</h2>
    <p>
      <span v-if="!showNumbers">All </span><span v-if="showNumbers">Of all {{ propAttributes.totalProps }}</span> properties in
      HOP
    </p>
    <table>
      <tr>
        <th>Tenure</th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>Freehold</td>
        <td>{{ propAttributes.percentages.tenure.freehold }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.freehold }}</td>
      </tr>
      <tr>
        <td>Leasehold</td>
        <td>{{ propAttributes.percentages.tenure.leasehold }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.leasehold }}</td>
      </tr>
      <tr>
        <td>Other</td>
        <td>{{ propAttributes.percentages.tenure.other }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.other }}</td>
      </tr>
      <tr>
        <td>Commonhold</td>
        <td>{{ propAttributes.percentages.tenure.commonhold }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.commonhold }}</td>
      </tr>
    </table>

    <h2>Freehold obligations</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.ta6CompleteFreeholdCount }} p</span
      ><span v-if="!showNumbers">P</span>roperties which have TA6 completed and are freehold.
    </p>
    <table>
      <tr>
        <th></th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>Where property is freehold obligation to pay costs for using property (eg estate charges / shared services)</td>
        <td>{{ propAttributes.percentages.tenure.freeholdpaycosts }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.freeholdpaycosts }}</td>
      </tr>
    </table>

    <h2>Lease remaining</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.counts.tenure.leasehold }} p</span
      ><span v-if="!showNumbers">P</span>roperties which are leasehold.
    </p>
    <table>
      <tr>
        <th></th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>Where property is leasehold, properties having less than 80 years remaining on term of lease</td>
        <td>{{ propAttributes.percentages.tenure.leaseholdlessthan80 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.leaseholdlessthan80 }}</td>
      </tr>
    </table>
    <h2>Leasehold Ground Rent</h2>
    <p>
      <span v-if="showNumbers">Of the {{ propAttributes.counts.tenure.leaseholdWithPricePaidIndAndAboutMyHome }} p</span
      ><span v-if="!showNumbers">P</span>roperties which are leasehold, having a price paid recorded in the deeds, and About My
      Home completed.
    </p>
    <table>
      <tr>
        <th></th>
        <th>Percentage</th>
        <th v-if="showNumbers">Properties</th>
      </tr>
      <tr>
        <td>Properties where ground rent is more than 0.1% of the last sale price listed in the deeds</td>
        <td>{{ propAttributes.percentages.tenure.groundRentMoreThan01 }}%</td>
        <td v-if="showNumbers">{{ propAttributes.counts.tenure.groundRentMoreThan01 }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  data() {
    return {
      showFailure: false,
      showSuccess: false,
      failureMessage: "",
      showNumbers: true,
      propAttributes: {
        percentages: {
          type: {},
          services: {},
          tenure: {},
          broadband: {},
        },
        counts: {
          type: {},
          services: {},
          tenure: {},
          broadband: {},
        },
        averages: {},
      },
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      if (this.hasPermissionTo(this.permissions.REPORT_PROP_ATTRIBUTES)) {
        this.$store.dispatch("updateLoading", true);
        // Get data
        HopService.reportsPropAttributes()
          .then((response) => {
            this.propAttributes = response.data;
            this.showFailure = false;
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.failureMessage = "Failed to fetch report";
            this.showSuccess = false;
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
    refresh() {
      if (this.hasPermissionTo(this.permissions.REPORT_PROP_ATTRIBUTES)) {
        this.$store.dispatch("updateLoading", true);
        HopService.reportsPropAttributesRefresh()
          .then((response) => {
            if (response) {
              this.showSuccess = true;
              this.showFailure = false;
              this.updateData();
            } else {
              this.showSuccess = false;
              this.showFailure = true;
            }
          })
          .catch(() => {
            this.showFailure = true;
            this.failureMessage = "Failed to fetch report";
            this.showSuccess = false;
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
    downloadCsv() {
      //   let csv = "HOP ID,Address,Completion,Date Completed,Last activity,Created,Title Number,Agency,Movers\n";
      //   this.user.properties.forEach(row => {
      //     csv += row["id"] + ",";
      //     csv += row["name"].replaceAll(",", "") + ",";
      //     csv += row["complete"] + "%,";
      //     csv += (row["date_completed"] || "") + ",";
      //     csv += (row["last_sign_in"] > 0 ? new Date(row["last_sign_in"] * 1000).toLocaleDateString("en-GB") : "never") + ",";
      //     csv += row["created"] + ",";
      //     csv += row["title_number"] + ",";
      //     csv += row["agency"] + ",";
      //     csv += (row["user_emails"] || "none assigned") + ",";
      //     csv += "\n";
      //   });
      //   const anchor = document.createElement("a");
      //   anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      //   anchor.target = "_blank";
      //   anchor.download = "Properties.csv";
      //   anchor.click();
    },
  },
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
  margin-top: 40px;
}
.failure {
  color: $colour-error-red-text;
  text-align: center;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid $colour-error-red-border;
  background-color: $colour-error-red-bg;
}
</style>
