<template>
  <div>
    <FormSection title="18. Flooding" :complete="model.preliminary_information.progress[18]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              Flooding may take a variety of forms: it may be seasonal, irregular or simply a one-off event. Your property does
              not need to be near the sea or to a river for flooding to happen.
            </p>
            <h3>More info</h3>
            <BaseButton url="http://www.gov.uk/government/organisations/department-for-environment-food-rural-affairs"
              >Flooding</BaseButton
            >
            <BaseButton url="http://www.gov.uk/check-flood-risk">Flood risk check</BaseButton>
            <BaseButton url="http://www.gov.uk/government/organisations/environment-agency"
              >Types of flooding & Flood Risk Reports</BaseButton
            >
            <h3>Read the Law Society's updated Flood Risk Practice Note:</h3>
            <BaseButton url="https://www.lawsociety.org.uk/support-services/advice/practice-notes/flood-risk/"
              >Flood Risk Practice Note</BaseButton
            >
          </div>
          <!-- <div class="question">
            <p class="question-title">
              <span class="important">18.1</span>
              <span> {{ options.preliminary_information.questions["18_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="18_1"
              @input="$emit('$emit('updateModelLevel2', , '18_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['18_1']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['18_1']['yes'] == 1">
              <span> {{ options.preliminary_information.questions["18_1_1"] }}</span>
            </p>
            <div v-if="model.preliminary_information.questions['18_1']['yes'] == 1" class="question-content">
              <BaseSelect
                initial="Month"
                class="select"
                :options="months"
                @input="$emit('updateModelLevel1', '18_1_1', $event)"
                :value="model.preliminary_information.questions['18_1_1']"
              />
              <BaseSelect
                initial="Year"
                class="select"
                :options="yearsFrom1970"
                @input="$emit('updateModelLevel1', '18_1_1_i', $event)"
                :value="model.preliminary_information.questions['18_1_1_i']"
              />
            </div>
          </div> -->
          <div class="question">
            <p class="question-title">
              <span class="important">18.1</span>
              <span> {{ options.preliminary_information.questions["18_1"] }}</span>
            </p>
            <YesNoItemsQuestion
              @updateYes="$emit('updateModelLevel2', '18_1', $event.key, $event.val)"
              @updateItem="updateModelItem('18_1', $event)"
              @removeItem="removeModelItem('18_1', $event)"
              @addItem="addModelItem('18_1')"
              :model="model.preliminary_information.questions['18_1']"
              :month="true"
              :options="shared_options.yes_no"
              title="Flooding incident"
              :categories="flooding"
              placeholder="Which parts flooded, and any other details"
            >
              <p class v-if="model.preliminary_information.questions['18_1'].yes == 1">
                <span class="important">18.1.1</span>
                <span>{{ options.preliminary_information.questions["18_1_1"] }}</span>
              </p>
            </YesNoItemsQuestion>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">18.2</span>
              <span>{{ options.preliminary_information.questions["18_2"] }}</span>
            </p>
            <YesNoUpload
              questionKey="18_2"
              @input="$emit('updateModelLevel2', '18_2', $event.key, $event.val)"
              :model="model.preliminary_information.questions['18_2']"
              :optionsUpload="shared_options.upload_follow"
              section="preliminary_information"
            />
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import YesNoItemsQuestion from "@/components/Forms/YesNoItemsQuestion.vue";
import YesNoUpload from "@/components/Forms/YesNoUpload.vue";
import OptionsService from "@/services/OptionsService";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    YesNoItemsQuestion,
    YesNoUpload,
  },
  data() {
    return {
      yearsFrom1970: OptionsService.yearsFrom1970(),
      months: OptionsService.allMonths(),
      flooding: this.$store.state["options"].options.property_information.options.flooding,
    };
  },
  methods: {
    addModelItem(questionKey) {
      this.$store.dispatch("addPreliminaryInformationItem", questionKey);
    },
    removeModelItem(questionKey, index) {
      this.$store.dispatch("removePreliminaryInformationItem", { questionKey: questionKey, index: index });
    },
    updateModelItem(questionKey, event) {
      this.$store.dispatch("updatePreliminaryInformationItem", { questionKey: questionKey, event: event });
    },
  },
});
</script>
