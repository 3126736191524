<template>
  <div>
    <FormSection title="17. Occupiers" :complete="model.preliminary_information.progress[17]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">17.1</span>
              <span>{{ options.preliminary_information.questions["17_1"] }}</span>
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_1"
              @input="updateModelLevel2('17_1', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_1']"
              :options="shared_options.yes_no"
            />
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">17.2</span>
              <span>{{ options.preliminary_information.questions["17_2"] }}</span>
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_2"
              @input="updateModelLevel2('17_2', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_2']"
              :options="shared_options.yes_no"
            />
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['17_2'].yes != 1 }">
            <p class="question-title">
              <span class="important">17.2.1</span>
              <span>{{ options.preliminary_information.questions["17_2_1"] }}</span>
            </p>
            <div class="question-content">
              <BaseTextArea
                rows="5"
                @update:modelValue="updateModelLevel1('17_2_1', $event)"
                :modelValue="model.preliminary_information.questions['17_2_1']"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['17_2'].yes != 1 }">
            <p class="question-title">
              <span class="important">17.2.2</span>
              <span>{{ options.preliminary_information.questions["17_2_2"] }}</span>
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_2_2"
              @input="updateModelLevel2('17_2_2', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_2_2']"
              :options="shared_options.yes_no"
            />
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">17.3</span>
              <span>{{ options.preliminary_information.questions["17_3"] }}</span>
            </p>
            <p class="question-title">
              {{ options.preliminary_information.questions["17_3_i"] }}
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_3"
              @input="updateModelLevel2('17_3', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_3']"
              :options="shared_options.yes_no"
            />
          </div>
          <div
            class="question"
            :class="{
              'slick-hide':
                model.preliminary_information.questions['17_2'].yes != 1 ||
                model.preliminary_information.questions['17_3'].yes != 1,
            }"
          >
            <p class="question-title">
              <span class="important">17.3.1</span>
              <span>{{ options.preliminary_information.questions["17_3_1"] }}</span>
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_3_1"
              @input="updateModelLevel2('17_3_1', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_3_1']"
              :options="shared_options.yes_no"
            />
          </div>
          <div
            class="question"
            :class="{
              'slick-hide':
                model.preliminary_information.questions['17_2'].yes != 1 ||
                model.preliminary_information.questions['17_3'].yes != 1,
            }"
          >
            <p class="question-title">
              <span class="important">17.3.2</span>
              <span>{{ options.preliminary_information.questions["17_3_2"] }}</span>
            </p>
            <YesNoTextQuestion
              :detailsTextArea="false"
              questionKey="17_3_2"
              @input="updateModelLevel2('17_3_2', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_3_2']"
              :options="shared_options.yes_no"
            />
          </div>
          <div
            class="question"
            :class="{
              'slick-hide': model.preliminary_information.questions['17_3'].yes != 2,
            }"
          >
            <p class="question-title">
              <span class="important">17.4</span>
              <span>{{ options.preliminary_information.questions["17_4"] }}</span>
            </p>
            <YesNoUpload
              questionKey="17_4"
              :uploadIfNo="true"
              :noYesNo="true"
              @input="updateModelLevel2('17_4', $event.key, $event.val)"
              :model="model.preliminary_information.questions['17_4']"
              :optionsUpload="shared_options.upload_follow"
              section="preliminary_information"
            />
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import YesNoUpload from "@/components/Forms/YesNoUpload.vue";
import YesNoTextQuestion from "@/components/Forms/YesNoTextQuestion.vue";
import _find from "lodash/find";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    YesNoUpload,
    YesNoTextQuestion,
  },
  data() {
    return {
      slideHiders: {
        "17_2": "yes",
        "17_3": "yes",
        "17_4": "yes",
      },
    };
  },
  methods: {
    updateModelLevel1(questionKey: string, val: string): void {
      this.$emit("updateModelLevel1", questionKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
    updateModelLevel2(questionKey: string, propKey: string, val: string): void {
      this.$emit("updateModelLevel2", questionKey, propKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
  },
});
</script>
