<template>
  <div class="circleFiller">
    <div class="circle outer">
      <svg :width="diameter" :height="diameter" xmlns="http://www.w3.org/2000/svg">
        <circle :cx="diameter / 2" :cy="diameter / 2" fill="transparent" stroke-width="3" :stroke="colour" :r="diameter - 55" />
      </svg>
    </div>
    <RadialProgress
      class="circle inner"
      :diameter="diameter"
      :startColor="colour"
      :stopColor="colour"
      :strokeWidth="5"
      innerStrokeColor="transparent"
      :completed-steps="circleValue"
      :total-steps="100"
      :animateSpeed="animateSpeed"
    >
      <div class="percentage">{{ circleValue }}%</div>
    </RadialProgress>
  </div>
</template>

<script>
import RadialProgress from "vue3-radial-progress";
// import styleVariables from "@/styles/_exports.scss";
import { defineComponent } from "vue";

export default defineComponent({
  components: { RadialProgress },
  props: {
    value: Number,
    diameter: {
      type: Number,
      default: 95,
    },
  },
  data() {
    return {
      circleValue: 0,
      animateSpeed: 200,
      finishedFirstAnimation: false,
      // colour: styleVariables["colour-primary"],
      colour: "#00a2a5",
      animateUpInterval: null,
    };
  },
  mounted: function () {
    // Animate the value up from 0
    this.animateUp();
    if (this.$store.state["ui"].theme == "canopy") {
      this.colour = "#be7151";
    } else if (this.$store.state["ui"].theme == "knightfrank") {
      this.colour = "#D0103A";
    }
  },
  methods: {
    animateUp() {
      var oldAnimateSpeed = this.animateSpeed;
      if (this.value > 0) {
        this.animateSpeed = 30;
        var i = 0;
        this.animateUpInterval = setInterval(() => {
          if (i >= this.value) {
            clearInterval(this.animateUpInterval);
            this.finishedFirstAnimation = true;
            this.animateSpeed = oldAnimateSpeed;
          } else {
            this.circleValue++;
            i++;
          }
        }, 50);
      } else {
        this.finishedFirstAnimation = true;
      }
    },
  },
  watch: {
    value(newValue) {
      if (this.finishedFirstAnimation) {
        this.circleValue = newValue;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.circleFiller {
  position: relative;

  .percentage {
    color: var(--colour-primary);
    font-weight: bold;
    font-size: 20px;
  }
  .circle {
    position: absolute;
    top: -48px;
    right: 0;
  }

  //   Because this doesn't work properly in the component
  :deep(.circle.inner) {
    svg {
      circle {
        stroke-linecap: square;
      }
    }
  }
}
</style>
