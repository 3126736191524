<template>
  <div>
    <FormSection title="9. Protected buildings" :complete="model.preliminary_information.progress[9]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">9.1</span>
              <span> {{ options.preliminary_information.questions["9_1"] }}</span>
            </p>
            <p class="question-title">You can check if your property is listed at Historic England:</p>
            <BaseButton @click.prevent="openUrl('https://historicengland.org.uk/listing/the-list/')">Search the list</BaseButton>
            <MultipleChoiceQuestion
              questionKey="9_1"
              @input="$emit('updateModelLevel2', '9_1', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['9_1']['yes']"
            />
            <div v-if="model.preliminary_information.questions['9_1']['yes'] == 1">
              <p class="question-title">
                <span> {{ options.preliminary_information.questions["9_1_i"] }}</span>
              </p>
              <MultipleChoiceQuestion
                questionKey="9_1"
                @input="$emit('updateModelLevel2', '9_1', 'upload', $event)"
                :options="shared_options.upload_follow"
                :model="model.preliminary_information.questions['9_1']['upload']"
              />
              <Uploader
                v-if="model.preliminary_information.questions['9_1']['upload'] == 3"
                :modelValue="model.preliminary_information.questions['9_1']['files']"
                @update:modelValue="$emit('updateModelLevel2', '9_1', 'files', $event)"
              />
            </div>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">9.2</span>
              <span> {{ options.preliminary_information.questions["9_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="9_2"
              @input="$emit('updateModelLevel2', '9_2', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['9_2']['yes']"
            />
            <div v-if="model.preliminary_information.questions['9_2']['yes'] == 1">
              <p class="question-title">
                <span> {{ options.preliminary_information.questions["9_2_i"] }}</span>
              </p>
              <MultipleChoiceQuestion
                questionKey="9_2"
                @input="$emit('updateModelLevel2', '9_2', 'upload', $event)"
                :options="shared_options.upload_follow"
                :model="model.preliminary_information.questions['9_2']['upload']"
              />
              <Uploader
                v-if="model.preliminary_information.questions['9_2']['upload'] == 3"
                :modelValue="model.preliminary_information.questions['9_2']['files']"
                @update:modelValue="$emit('updateModelLevel2', '9_2', 'files', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import { openUrl } from "@/helpers/utils";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
    Uploader,
  },
  methods: {
    openUrl,
  },
});
</script>
