<template>
  <div class="reports signatures">
    <h1>Manage {{ data.property?.name }}</h1>
    <div style="margin-top: 20px; margin-bottom: 20px; border-bottom: 1px solid #e0e0e0"></div>
    <h3>Movers assigned to this property</h3>

    <div v-if="sellers.length">
      <table>
        <tr>
          <th>Name</th>
          <th>Login email</th>
          <th>Last activity</th>
          <th>Actions</th>
        </tr>
        <tr v-for="(seller, key) in sellers" :key="key">
          <td>{{ seller.name }}</td>
          <td>{{ seller.email }}</td>
          <td>
            {{
              seller.last_activity && new Date(seller.last_activity).getFullYear() != -1
                ? new Date(seller.last_activity)
                : "never"
            }}
          </td>

          <td style="display: flex; justify-content: space-between">
            <BaseButton :small="true" @click="resendInvite(seller.id)">Resend invite</BaseButton>
            <BaseButton :small="true" @click="deleteSeller(seller.id)" style="margin-left: 5px; background-color: red"
              >Remove</BaseButton
            >
          </td>
        </tr>
      </table>
    </div>
    <div v-else style="text-align: center">
      <p>No movers have been invited.</p>
    </div>
    <div class="seller-actions">
      <router-link class="header__nav__item" v-if="canInviteSeller" :to="{ name: 'invite-seller' }" style="text-align: center">
        <BaseButton>Invite Seller</BaseButton>
      </router-link>
    </div>

    
    <div v-if="hasPermissionTo(permissions.ADMIN_ONLY)">
      <div style="margin-top: 20px; margin-bottom: 20px; border-bottom: 1px solid #e0e0e0"></div>
      <h1>Resources</h1>
      <div class="resources">
        <BaseButton @click="downloadReportTemplate('Market Ready Report', 'market-ready')">Market Ready Report Template</BaseButton>
        <BaseButton @click="downloadReportTemplate('Property Information', 'info')">Property Information (TA6) Report Template</BaseButton>
      </div>
    </div>
    <!-- Delete Hop Section -->

    <div style="margin-top: 20px; margin-bottom: 20px; border-bottom: 1px solid #e0e0e0"></div>

    <div class="delete-hop-section">
      <h3 style="color: red" class="danger">Danger Zone</h3>
      <p>Deleting this hop is a permanent action and cannot be undone.</p>
      <BaseButton @click="deleteHop" class="delete-button" style="background-color: red">Delete Hop</BaseButton>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import HopService from "@/services/HopService";
import ApiUtils from "@/services/ApiUtils";
import Permissions from "@/helpers/permissions";
import permissionsValues from "@/helpers/permissionsValues";

const store = useStore();
const router = useRouter();

const sellers = ref([]);

const data = ref({});

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

const canInviteSeller = computed(() => {
  return sellers.value && sellers.value.length < 4;
});

const hasPermissionTo = computed(() => {
  return new Permissions().hasPermissionTo
});

const permissions = computed(() => {
  return permissionsValues;
});

onMounted(() => {
  store.dispatch("updateLoading", true);
  HopService.getInvitedSellers(props.id)
    .then((response) => {
      sellers.value = response.data;
      store.dispatch("updateLoading", false);
    })
    .catch((error) => {
      store.dispatch("showAlert", "There was a problem fetching data: " + error.response.data);
      store.dispatch("updateLoading", false);
    });

  HopService.getPropertySignatures(props.id)
    .then((response) => {
      data.value = response.data;
      store.dispatch("updateLoading", false);
    })
    .catch((error) => {
      store.dispatch("showAlert", "There was a problem fetching data: " + error.response.data);
      store.dispatch("updateLoading", false);
    });
});

const deleteSeller = (sellerId) => {
  store.dispatch("updateLoading", true);
  if (confirm("Are you sure you want to remove this seller?")) {
    HopService.deleteInvitedSeller(sellerId, props.id)
      .then(() => {
        sellers.value = sellers.value.filter((seller) => seller.id !== sellerId);
        store.dispatch("showAlert", "Seller removed successfully.");
      })
      .catch((error) => {
        store.dispatch("showAlert", "There was a problem removing the seller: " + error.response.data);
      });
  }
  store.dispatch("updateLoading", false);
};

const resendInvite = (sellerId) => {
  if (confirm("Are you sure you want to resend the invite to this seller?")) {
    HopService.resendSellerInvite(sellerId, props.id)
      .then(() => {
        store.dispatch("showAlert", "Invite resent successfully.");
      })
      .catch((error) => {
        store.dispatch("showAlert", "There was a problem resending the invite: " + error.response.data);
      });
  }
};

const deleteHop = () => {
  if (confirm("Are you sure you want to delete this hop? This action cannot be undone.")) {
    HopService.deleteProperty(props.id)
      .then((response) => {
        store.dispatch("showAlert", "Hop deleted successfully.");
      })
      .then(() => {
        router.push({ name: "listproperties" });
      })
      .catch((error) => {
        store.dispatch("showAlert", "Failed to delete hop. Please try again.");
        store.dispatch("updateLoading", false);
      });
  }
};

const downloadReportTemplate = (reportLabel, reportPath) => {
  store.dispatch("updateLoading", true);
  HopService.getReportTemplate(props.id, reportPath)
    .then((response) => {
      ApiUtils.openDownloadInNewTab(response, reportLabel);
      store.dispatch("updateLoading", false);
    })
    .catch((error) => {
      store.dispatch("showAlert", "There was a problem fetching data: " + error);
      store.dispatch("updateLoading", false);
    });
}
</script>

<style lang="scss" scoped>
.signatures {
  ul {
    // text-align: center;
    max-width: 180px;
    margin: 0 auto;
    li {
      // text-align: center;
    }
  }
}
.resources {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  a {
    text-align: center;
  }
}
.seller-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
