import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      options: this.$store.state["options"].options,
      model: this.$store.state["property"].property.data.conveyancing_forms,
      shared_options: this.$store.state["options"].options.shared.options,
    };
  },
});
