<template>
  <div>
    <FormSection title="8. Solar panels" :complete="model.preliminary_information.progress[8]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">8.1</span>
              <span> {{ options.preliminary_information.questions["8_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="8_1"
              @input="updateModelLevel1('8_1', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['8_1']"
            />
            <div v-if="model.preliminary_information.questions['8_1'] == 1">
              <p class="question-title">
                <span class="important">8.1.1</span>
                <span> {{ options.preliminary_information.questions["8_1_1"] }}</span>
              </p>
              <div class="question-content">
                <BaseSelect
                  initial="Year"
                  class="select"
                  :options="yearsFrom1970"
                  @input="$emit('updateModelLevel1', '8_1_1', $event)"
                  :value="model.preliminary_information.questions['8_1_1']"
                />
              </div>
              <p class="question-title">
                <span class="important">8.1.2</span>
                <span> {{ options.preliminary_information.questions["8_1_2"] }}</span>
              </p>
              <MultipleChoiceQuestion
                questionKey="8_1_2"
                @input="$emit('updateModelLevel1', '8_1_2', $event)"
                :options="shared_options.yes_no"
                :model="model.preliminary_information.questions['8_1_2']"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['8_1'] != 1 }">
            <p class="question-title">
              <span class="important">8.1.3</span>
              <span> {{ options.preliminary_information.questions["8_1_3"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="8_1_3"
              @input="$emit('updateModelLevel1', '8_1_3', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['8_1_3']"
            />
            <div v-if="model.preliminary_information.questions['8_1_3'] == 1">
              <p class="question-title">
                <span> {{ options.preliminary_information.questions["8_1_3_i"] }}</span>
              </p>
              <MultipleChoiceQuestion
                questionKey="8_1_3_i"
                @input="$emit('updateModelLevel2', '8_1_3_i', 'yes', $event)"
                :options="shared_options.upload_follow"
                :model="model.preliminary_information.questions['8_1_3_i']['yes']"
              />
              <Uploader
                v-if="model.preliminary_information.questions['8_1_3_i']['yes'] == 3"
                :modelValue="model.preliminary_information.questions['8_1_3_i']['files']"
                @update:modelValue="$emit('updateModelLevel2', '8_1_3_i', 'files', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import OptionsService from "@/services/OptionsService";
import _find from "lodash/find";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
    Uploader,
  },
  data() {
    return {
      yearsFrom1970: OptionsService.yearsFrom1970(),
      slideHiders: {
        "8_1": "",
      },
    };
  },
  methods: {
    updateModelLevel1(questionKey: string, val: string): void {
      this.$emit("updateModelLevel1", questionKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
  },
});
</script>
