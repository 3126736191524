<template>
  <div>
    <FormSection title="11. Consent" :complete="model.preliminary_information.progress[11]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">11.1</span>
              <span> {{ options.preliminary_information.questions["11_1"] }}</span>
            </p>
            <p class="question-title">
              Check with your solicitor that all necessary consents have been received. If you are not sure if permission is
              needed, ask your solicitor as soon as it is practical.
            </p>
            <MultipleChoiceQuestion
              questionKey="11_1"
              @input="$emit('updateModelLevel2', '11_1', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['11_1']['yes']"
            />
            <div class="question-content">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="More details"
                :modelValue="model.preliminary_information.questions['11_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '11_1', 'details', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import { openUrl } from "@/helpers/utils";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
  methods: {
    openUrl,
  },
});
</script>
