<template>
  <div>
    <FormSection
      title="6. Property alterations and building work"
      :complete="model.preliminary_information.progress[6]"
      :bottomBorder="true"
    >
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">6.1</span>
              <span> {{ options.preliminary_information.questions["6_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="6_1"
              @input="updateModelLevel2('6_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['6_1']['yes']"
            />
            <p class="question-title">
              Tick all those that apply. Please provide plans and details of any consents that were granted. If you are unsure,
              please ask or consult your solicitor.
            </p>
            <div class="question-content">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['6_1']['items']"
                :options="options.preliminary_information.options['6_1']"
                @update:modelValue="updateModelLevel2('6_1', 'items', $event)"
              />
            </div>
          </div>
          <div
            class="question"
            :class="{
              'slick-hide':
                model.preliminary_information.questions['6_1']['yes'] != 1 ||
                model.preliminary_information.questions['6_1']['items'].length == 0,
            }"
          >
            <p class="question-title">
              <span class="important">6.2</span>
            </p>
            <div v-for="(item, key) in model.preliminary_information.questions['6_1']['items']" :key="key">
              <p class="question-title">
                <span
                  >Give details for the work concerning: "{{
                    options.preliminary_information.options["6_1"][model.preliminary_information.questions["6_1"]["items"][key]]
                  }}".</span
                >
              </p>
              <div class="question-content">
                <BaseTextArea
                  rows="4"
                  :maxlength="5000"
                  placeholder="Work carried out"
                  :modelValue="
                    model.preliminary_information.questions['6_2'][model.preliminary_information.questions['6_1']['items'][key]][
                      'details'
                    ]
                  "
                  @update:modelValue="
                    $emit(
                      'updateModelLevel3',
                      '6_2',
                      model.preliminary_information.questions['6_1']['items'][key],
                      'details',
                      $event
                    )
                  "
                />
                <BaseSelect
                  initial="Month"
                  class="select"
                  :options="months"
                  @input="
                    $emit(
                      'updateModelLevel3',
                      '6_2',
                      model.preliminary_information.questions['6_1']['items'][key],
                      'month',
                      $event
                    )
                  "
                  :value="
                    model.preliminary_information.questions['6_2'][model.preliminary_information.questions['6_1']['items'][key]][
                      'month'
                    ]
                  "
                />
                <BaseSelect
                  initial="Year"
                  class="select"
                  :options="yearsFrom1970"
                  @input="
                    $emit(
                      'updateModelLevel3',
                      '6_2',
                      model.preliminary_information.questions['6_1']['items'][key],
                      'year',
                      $event
                    )
                  "
                  :value="
                    model.preliminary_information.questions['6_2'][model.preliminary_information.questions['6_1']['items'][key]][
                      'year'
                    ]
                  "
                />
              </div>
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['6_1']['yes'] != 1 }">
            <p class="question-title">
              <span class="important">6.2.1</span>
              <span> {{ options.preliminary_information.questions["6_2_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="updateModelLevel2('6_2_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['6_2_1']['yes']"
            />
            <div class="question-content">
              <BaseTextArea
                v-if="model.preliminary_information.questions['6_2_1']['yes'] == 2"
                rows="4"
                :maxlength="5000"
                placeholder="Give details"
                :modelValue="model.preliminary_information.questions['6_2_1']['details']"
                @update:modelValue="updateModelLevel2('6_2_1', 'details', $event)"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['6_1']['yes'] != 1 }">
            <p class="question-title">
              <span class="important">6.2.2</span>
              <span> {{ options.preliminary_information.questions["6_2_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="updateModelLevel2('6_2_2', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['6_2_2']['yes']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['6_2_2']['yes'] == 1">
              <span class="important">6.2.2.1</span>
              <span> {{ options.preliminary_information.questions["6_2_2_1"] }}</span>
            </p>
            <Uploader
              v-if="model.preliminary_information.questions['6_2_2']['yes'] == 1"
              :modelValue="model.preliminary_information.questions['6_2_2']['files']"
              @update:modelValue="$emit('updateModelLevel2', '6_2_2', 'files', $event)"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['6_2_2']['yes'] == 2">
              <span class="important">6.2.2.1</span>
              <span> {{ options.preliminary_information.questions["6_2_2_2"] }}</span>
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['6_2_2']['yes'] == 2">
              <BaseTextArea
                v-if="model.preliminary_information.questions['6_2_2']['yes'] == 2"
                rows="4"
                :maxlength="5000"
                placeholder="More details"
                :modelValue="model.preliminary_information.questions['6_2_2']['details']"
                @update:modelValue="updateModelLevel2('6_2_2', 'details', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import _find from "lodash/find";
import OptionsService from "@/services/OptionsService";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
    Uploader,
  },
  data() {
    return {
      passport: this.$store.state["property"].property.data.passport,
      slideHiders: {
        "6_1": "",
      },
      yearsFrom1970: OptionsService.yearsFrom1970(),
      months: OptionsService.allMonths(),
    };
  },
  methods: {
    updateModelLevel1(questionKey: string, val: string): void {
      this.$emit("updateModelLevel1", questionKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
    updateModelLevel2(questionKey: string, propKey: string, val: string): void {
      this.$emit("updateModelLevel2", questionKey, propKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        var updateHiddenSlides = _find(this.slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
  },
});
</script>
