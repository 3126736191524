<template>
  <div>
    <FormSection title="3. Type of ownership" :complete="model.preliminary_information.progress[3]" :bottomBorder="true">
      <form class="questions">
        <p class="note">These answers may be pre-filled with information from the title deeds. Please check they are correct.</p>
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">3.1</span>
              <span> {{ options.preliminary_information.questions["3_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="updateModelLevel1('3_1', $event)"
              :options="options.preliminary_information.options.ownership_type"
              :model="model.preliminary_information.questions['3_1']"
            />
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2</span>
              <span
                >The following questions relate to the terms of your lease. If you do not have a copy of your lease, or you do not
                feel confident answering the questions, the you can use our 'Check and Send' service. Once you have completed as
                much of the form as you can, it can be sent, along with the lease to a solicitor who will check the answers for
                you, and where the questions relate to information contained in the title deeds or the lease, complete the answers
                for you. If you would like to use the check and send service, use the button below.</span
              >
            </p>
            <BaseButton @click.prevent="checkAndSend">Use check and send</BaseButton>
            <p class="question-title" v-if="!property.passport.leasehold_agreement">
              Please provide a copy of your leasehold agreement.
              <span v-if="!property.passport.leasehold_agreement" class="note"
                >Hint: if you have a scanned copy of the lease you can upload it here. If not, we can obtain a copy directly from
                HM Land Registry for you. Alternatively, select 'To follow' from the options below and your solicitor can help you
                to get hold of a copy in due course.</span
              >
            </p>
            <p class="note" v-if="property.passport.leasehold_agreement">
              The lease has automatically been downloaded from the Land Registry.
              <BaseButton @click.prevent="viewLeaseholdAgreement()">View it here</BaseButton>
            </p>
            <YesNoQuestion
              v-if="!property.passport.leasehold_agreement"
              questionKey="3_2_i"
              @update:modelValue="leaseholdUpload"
              :modelValue="model.preliminary_information.questions['3_2_i']"
              :options="{
                3: 'Upload',
                4: 'To follow',
                6: 'Already supplied',
                7: 'Obtain a copy',
              }"
            />
            <Uploader
              v-if="model.preliminary_information.questions['3_2_i']['yes'] == 3 && !property.passport.leasehold_agreement"
              :modelValue="property.uploads.leasehold_agreement"
              :multiple="false"
              :accepts="['pdf']"
              @update:modelValue="setUploads($event)"
            />
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2.1</span>
              <span> {{ options.preliminary_information.questions["3_2_1"] }}</span>
            </p>
            <p>
              <span class="question-hint" v-if="passport.leaseTerm"
                >According to the title deeds, the lease terms are {{ passport.leaseTerm }}.</span
              >
              <BaseButton :url="$store.state['property'].property.data.passport.deeds[0]" :small="false" buttonClass=""
                >View deeds</BaseButton
              >
            </p>
            <div class="question-content">
              <BaseInput
                :modelValue="model.preliminary_information.questions['3_2_1']"
                @update:modelValue="updateModelLevel1('3_2_1', $event)"
                type="number"
                placeholder="0"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2.2.1</span>
              <span> {{ options.preliminary_information.questions["3_2_2_1"] }}</span>
            </p>
            <div class="question-content">
              <BaseInput
                :modelValue="model.preliminary_information.questions['3_2_2_1']"
                @update:modelValue="updateModelLevel1('3_2_2_1', $event)"
                type="number"
                contentBefore="£"
                placeholder="0"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2.2.2</span>
              <span> {{ options.preliminary_information.questions["3_2_2_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="updateModelLevel1('3_2_2_2', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['3_2_2_2']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['3_2_2_2'] == 1">
              Please give details below - if you don’t have your lease then your solicitor will be able to obtain a copy.
            </p>
            <div class="question-content" v-if="model.preliminary_information.questions['3_2_2_2'] == 1">
              <BaseDateInput
                :value="model.preliminary_information.questions['3_2_2_2_i']"
                @input="updateModelLevel1('3_2_2_2_i', $event)"
                placeholder="Date of next increase"
              />
              <BaseInput
                :modelValue="model.preliminary_information.questions['3_2_2_2_ii']"
                @update:modelValue="updateModelLevel1('3_2_2_2_ii', $event)"
                type="text"
                placeholder="Frequency of increase"
              />
              <BaseInput
                :modelValue="model.preliminary_information.questions['3_2_2_2_iii']"
                @update:modelValue="updateModelLevel1('3_2_2_2_iii', $event)"
                type="number"
                contentBefore="£"
                placeholder="Amount of increase"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2.2.3</span>
              <span> {{ options.preliminary_information.questions["3_2_2_3"] }}</span>
            </p>

            <MultipleChoiceQuestion
              @input="updateModelLevel1('3_2_2_3', $event)"
              :options="options.preliminary_information.options['3_2_2_3']"
              :model="model.preliminary_information.questions['3_2_2_3']"
            />
            <div class="question-content">
              <BaseTextArea
                v-if="model.preliminary_information.questions['3_2_2_3'] == 1"
                rows="4"
                :maxlength="5000"
                placeholder="More information"
                :modelValue="model.preliminary_information.questions['3_2_2_3_i']"
                @update:modelValue="updateModelLevel1('3_2_2_3_i', $event)"
              />
            </div>
            <p class="question-title" v-if="model.preliminary_information.questions['3_2_2_3'] == 2">
              <span>How much are the rent increases, and how is this calculated?</span>
            </p>
            <div class="question-content">
              <BaseTextArea
                v-if="model.preliminary_information.questions['3_2_2_3'] == 2"
                rows="4"
                :maxlength="5000"
                placeholder="More information"
                :modelValue="model.preliminary_information.questions['3_2_2_3_ii']"
                @update:modelValue="updateModelLevel1('3_2_2_3_ii', $event)"
              />
            </div>
            <p class="question-title" v-if="model.preliminary_information.questions['3_2_2_3'] == 3">
              <span>What are the amounts, and how is the revised rent calculated?</span>
            </p>
            <div class="question-content">
              <BaseTextArea
                v-if="model.preliminary_information.questions['3_2_2_3'] == 3"
                rows="4"
                :maxlength="5000"
                placeholder="More information"
                :modelValue="model.preliminary_information.questions['3_2_2_3_iii']"
                @update:modelValue="updateModelLevel1('3_2_2_3_iii', $event)"
              />
            </div>
          </div>
          <div class="question" :class="{ 'slick-hide': model.preliminary_information.questions['3_1'] == 1 }">
            <p class="question-title">
              <span class="important">3.2.3.1</span>
              <span> {{ options.preliminary_information.questions["3_2_3_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="updateModelLevel1('3_2_3_1', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['3_2_3_1']"
            />
            <p class="question-title" v-if="model.preliminary_information.questions['3_2_3_1'] == 1">
              <span class="important">3.2.3.2</span>
              <span> {{ options.preliminary_information.questions["3_2_3_2"] }}</span>
            </p>
            <div class="question-content">
              <BaseInput
                v-if="model.preliminary_information.questions['3_2_3_1'] == 1"
                type="number"
                class="input"
                placeholder="0"
                :modelValue="model.preliminary_information.questions['3_2_3_2']"
                @update:modelValue="updateModelLevel1('3_2_3_2', $event)"
                contentBefore="£"
              />
            </div>
            <p class="question-title" v-if="model.preliminary_information.questions['3_2_3_1'] == 1">
              <span class="important">3.2.3.3</span>
              <span> {{ options.preliminary_information.questions["3_2_3_3"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="3_2_3_3"
              v-if="model.preliminary_information.questions['3_2_3_1'] == 1"
              @input="$emit('updateModelLevel2', '3_2_3_3', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['3_2_3_3']['yes']"
            />
            <p
              class="question-title"
              v-if="
                model.preliminary_information.questions['3_2_3_1'] == 1 &&
                model.preliminary_information.questions['3_2_3_3']['yes'] == 1
              "
            >
              {{ options.preliminary_information.questions["3_2_3_3_i"] }}
            </p>
            <Uploader
              v-if="
                model.preliminary_information.questions['3_2_3_1'] == 1 &&
                model.preliminary_information.questions['3_2_3_3']['yes'] == 1
              "
              :modelValue="model.preliminary_information.questions['3_2_3_3']['files']"
              :multiple="false"
              @update:modelValue="$emit('updateModelLevel2', '3_2_3_3', 'files', $event)"
            />
          </div>
          <div
            class="question"
            :class="{
              'slick-hide':
                model.preliminary_information.questions['3_1'] == 1 || model.preliminary_information.questions['3_2_3_1'] != 1,
            }"
          >
            <p class="question-title">
              <span class="important">3.2.3.4</span>
              <span> {{ options.preliminary_information.questions["3_2_3_4"] }}</span>
            </p>
            <div class="question-content">
              <BaseSelect
                initial="Month"
                class="select"
                :options="months"
                @input="$emit('updateModelLevel1', '3_2_3_4_i', $event)"
                :value="model.preliminary_information.questions['3_2_3_4_i']"
              />
              <BaseSelect
                initial="Year"
                class="select"
                :options="yearsFrom1970"
                @input="$emit('updateModelLevel1', '3_2_3_4_ii', $event)"
                :value="model.preliminary_information.questions['3_2_3_4_ii']"
              />
            </div>
            <p class="question-title">
              <span class="important">3.2.3.5</span>
              <span>
                {{ options.preliminary_information.questions["3_2_3_5"] }} For instance, details of the management or freehold
                company, resident’s association:</span
              >
            </p>
            <div class="question-content">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['3_2_3_5']"
                @update:modelValue="$emit('updateModelLevel1', '3_2_3_5', $event)"
                type="text"
                placeholder="Enter details"
              />
            </div>
          </div>
          <div
            class="question"
            :class="{
              'slick-hide': model.preliminary_information.questions['3_1'] == 1,
            }"
          >
            <p class="question-title">
              <span class="important">3.2.4</span>
              <span> {{ options.preliminary_information.questions["3_2_4"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="3_2_4"
              @input="$emit('updateModelLevel2', '3_2_4', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['3_2_4']['yes']"
            />
            <div v-if="model.preliminary_information.questions['3_2_4']['yes'] == 1">
              <p class="question-title">
                <span> {{ options.preliminary_information.questions["3_2_4_i"] }}</span>
              </p>
              <div class="question-content">
                <BaseSelect
                  initial="Month"
                  class="select"
                  :options="months"
                  @input="$emit('updateModelLevel1', '3_2_4_i', $event)"
                  :value="model.preliminary_information.questions['3_2_4_i']"
                />
                <BaseSelect
                  initial="Year"
                  class="select"
                  :options="yearsFrom1970"
                  @input="$emit('updateModelLevel1', '3_2_4_ii', $event)"
                  :value="model.preliminary_information.questions['3_2_4_ii']"
                />
              </div>
              <MultipleChoiceQuestion
                questionKey="3_2_4_iii"
                @input="$emit('updateModelLevel1', '3_2_4_iii', $event)"
                :options="options.preliminary_information.options['3_2_4']"
                :model="model.preliminary_information.questions['3_2_4_iii']"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import YesNoQuestion from "@/components/Forms/YesNoQuestion.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import OptionsService from "@/services/OptionsService";
import _find from "lodash/find";
import HopService from "@/services/HopService.js";
import { UploadedFile } from "@/types/UploadedFile";
import { useStore } from "vuex";

const store = useStore();

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
    YesNoQuestion,
    Uploader,
  },
  data() {
    return {
      yearsFrom1970: OptionsService.yearsFrom1970(),
      months: OptionsService.allMonths(),
      slideHiders: {
        "3_1": "",
        "3_2_3_1": "",
      },
      passport: this.$store.state["property"].property.data.passport,
    };
  },
  computed: {
    property() {
      return store.state["property"].property.data;
    },
  },
  methods: {
    setUploads(uploads: UploadedFile[]) {
      this.$nextTick(function (): void {
        this.$store.dispatch("setUploads", {
          category: "leasehold_agreement",
          value: uploads,
        });
      });
    },
    updateModelLevel1(questionKey: string, val: string): void {
      this.$emit("updateModelLevel1", questionKey, val);
      this.$nextTick(function (): void {
        // If it's a data change that affects slides, update them
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var updateHiddenSlides = _find((this as any).slideHiders, function (element, index) {
          if (questionKey == index) {
            return true;
          }
          return false;
        });
        if (updateHiddenSlides != null) {
          this.$nextTick(function (): void {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.$refs.carousel as any).updateHiddenSlides();
          });
        }
      });
    },
    checkAndSend() {
      HopService.sendEnquiry(
        this.$store.state["property"].property.id,
        this.$store.state["user"].user.displayName,
        `Request for Property Information check and send service for ${this.$store.state["property"].property.name}..`
      )
        .then(() => {
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("showAlert", "Request received. We will be in touch soon.");
        })
        .catch(() => {
          // console.log(error);
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("showAlert", "Request failed, please get in touch with us.");
        });
    },
    viewLeaseholdAgreement() {
      let url = this.$store.state["property"].property.data.passport.leasehold_agreement;
      window.open(url, "_blank");
    },
    leaseholdUpload($event) {
      this.$emit("updateModelLevel2", "3_2_i", $event.key, $event.val);
      if ($event.val == 7) {
        this.$store.dispatch("updateLoading", true);
        HopService.sendEnquiry(
          this.$store.state["property"].property.id,
          this.$store.state["user"].user.displayName,
          "Request for leasehold agreement to be manually added to this property."
        )
          .then(() => {
            this.$store.dispatch("updateLoading", false);
            this.$store.dispatch("showAlert", "Request received. We will obtain your leasehold agreement for you.");
          })
          .catch(() => {
            // console.log(error);
            this.$store.dispatch("updateLoading", false);
            this.$store.dispatch("showAlert", "Request failed, please get in touch with us.");
          });
      }
    },
  },
});
</script>
