import _forEach from "lodash/forEach";
import store from "@/store";
import { round } from "@/helpers/utils";
import _debounce from "lodash/debounce";

export const state = {};

export const mutations = {
  FILL_IN_FITTINGS_CONTENTS(state) {
    _forEach(state.property.data.conveyancing_forms.fittings_contents.questions, function (category) {
      _forEach(category.questions, function (item) {
        item.included = Math.floor(Math.random() * Math.floor(3)) + 1;
      });
    });
  },
  UPDATE_FITTINGS_CONTENTS_PROGRESS(state) {
    state.property.data.conveyancing_forms.fittings_contents.complete = true;
    _forEach(state.property.data.conveyancing_forms.fittings_contents.questions, function (category, catName) {
      if (catName != "other") {
        let points = 0;
        let maxPoints = 0;
        _forEach(category.questions, function (question, index) {
          // Don't count additional entries
          if (index.indexOf("other_") == -1) {
            if (catName == "kitchen") {
              // Kitchen is a special case, it has 2 fields
              maxPoints++;
              if (question.fitted != 0) {
                points++;
              }
              // It's fitted, so they must indicate whether it's included
              if (question.fitted != 3 && question.fitted != 0) {
                maxPoints++;
                if (question.included != 0) {
                  points++;
                }
              }
            } else {
              // All other sections
              maxPoints++;
              if (question.included != 0) {
                points++;
              }
            }
          }
        });
        category.progress = round((points / maxPoints) * 100, 0);
        if (category.progress != 100) {
          state.property.data.conveyancing_forms.fittings_contents.complete = false;
        }
      }
    });
  },
  ADD_FITTINGS_CONTENTS_ITEM(state, payload) {
    state.property.data.conveyancing_forms.fittings_contents.questions[payload.question_id].questions[payload.key] = {
      included: 0,
      price: null,
      files: [],
      comments: "",
      name: "",
    };
    store.dispatch("setTouched", true);
  },
  UPDATE_FITTINGS_CONTENTS_QUESTION(state, payload) {
    state.property.data.conveyancing_forms.fittings_contents.questions[payload.modelKey].questions[payload.questionKey][
      payload.propKey
    ] = payload.value;

    store.dispatch("setTouched", true);
  },
};

export const actions = {
  fillInFittingsContents({ commit, dispatch }) {
    commit("FILL_IN_FITTINGS_CONTENTS");
    dispatch("updateFittingsContentsProgress");
    store.dispatch("setTouched", true);
    dispatch("savePropertyData");
  },
  updateFittingsContentsQuestion({ commit, dispatch }, payload) {
    commit("UPDATE_FITTINGS_CONTENTS_QUESTION", payload);
    dispatch("updateFittingsContentsProgress");
    dispatch("savePropertyData");
  },
  addFittingsContentsItem(context, payload) {
    let key = "";
    for (let i = 1; i <= context.rootState.options.options.fittings_contents[payload].max_other; i++) {
      if (
        typeof context.state.property.data.conveyancing_forms.fittings_contents.questions[payload].questions["other_" + i] !=
        "object"
      ) {
        key = "other_" + i;
        break;
      }
    }
    if (key != "") {
      context.commit("ADD_FITTINGS_CONTENTS_ITEM", {
        key: key,
        question_id: payload,
      });
    }
    context.dispatch("savePropertyData");
  },
  updateFittingsContentsProgress: _debounce(({ commit }) => {
    commit("UPDATE_FITTINGS_CONTENTS_PROGRESS");
  }, 1000),
};
