<template>
  <div class="reports">
    <p><b>Name: </b>{{ organisation.name }}</p>
    <p v-if="organisation.parent_name"><b>Parent: </b>{{ organisation.parent_name }}</p>
    <p><b>Type: </b>{{ organisation.type_name }}</p>
    <p><b>Default phase: </b>{{ organisation.default_phase?.label }}</p>
    <h1>Questionnaire config by phase</h1>
    <div v-for="questionnaire in organisation?.phase_config?.questionnaires" :key="questionnaire.id">
      <h2>{{ questionnaire.name }}</h2>
      <table>
        <!-- eslint-disable-next-line vue/no-v-for-template-key -->
        <template v-for="section in questionnaire.sections" :key="section.id">
          <tr>
            <th>{{ section.name }}</th>
            <th v-for="phase in organisation?.phase_config?.phases" :key="phase.id">{{ phase.label }}</th>
          </tr>
          <!-- eslint-disable-next-line vue/no-v-for-template-key -->
          <template v-for="group in section.groups" :key="group.id">
            <tr v-if="group.name">
              <td>
                <b>{{ group.name }}</b>
              </td>
              <td v-for="phase in organisation?.phase_config?.phases" :key="phase.id"></td>
            </tr>
            <!-- eslint-disable-next-line vue/no-v-for-template-key -->
            <template v-for="question in group.questions" :key="question.id">
              <tr v-if="question.type !== 'upload'">
                <td>{{ question.question }}</td>
                <td v-for="phase in organisation?.phase_config?.phases" :key="phase.id">
                  <span v-if="question.enabled_in_phase[phase.id]" class="progress">&#10003;</span>
                  <span v-if="!question.enabled_in_phase[phase.id]" class="progress">✘</span>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
  },
  data() {
    return {
      organisation: {},
      showSuccess: false,
      showFailure: false,
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData(backgroundLoad = false) {
      if (!backgroundLoad) {
        this.$store.dispatch("updateLoading", true);
      }

      HopService.getOrganisation(this.id)
        .then((response) => {
          this.organisation = response.data;
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch organisation";
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        });
    },
    getQuestionNumber(form_reference) {
      if (!form_reference) {
        return "";
      }
      let parts = form_reference.split(".");
      if (parts.length > 1 && parts[1] !== "yes") {
        return "";
      }

      parts = parts[0].split("_");
      return parts.join(".") + ". ";
    },
  },
};
</script>

<style lang="scss" scoped></style>
