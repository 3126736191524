<template>
  <div>
    <div class="loader-overlay" :class="{ opaque: opaque }" v-if="loading"></div>
    <div class="loader" v-if="loading">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="loader-background-overlay" :class="{ shown: backgroundLoading }"></div>
    <div class="loader-background" :class="{ shown: backgroundLoading }">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.state["ui"].loading;
    },
    backgroundLoading() {
      return this.$store.state["ui"].backgroundLoading;
    },
  },
  props: {
    opaque: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--colour-primary);
  opacity: 0.8;
  z-index: 1;

  &.opaque {
    opacity: 1;
  }
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loader-background-overlay {
  transition: opacity 0.4s;
  position: fixed;
  bottom: 0;
  background-color: var(--colour-primary);
  // border: 2px solid var(--colour-secondary);
  opacity: 0;
  z-index: 1;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  right: 0;
  transform: scale(0.6);

  &.shown {
    opacity: 1;
  }
}

.loader-background {
  transition: opacity 0.4s;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  opacity: 0;
  width: 80px;
  height: 80px;
  transform: scale(0.5);

  &.shown {
    opacity: 1;
  }

  .lds-roller {
    div {
      background-color: var(--colour-white);
    }
  }
}
</style>
