export function shouldShowIf(query: string, answers: object): boolean | null {
  let queryParts = query.split(/(AND|OR)/).map((part) => part.trim());
  if (queryParts.length > 3) {
    queryParts = [...queryParts.slice(0, 2), queryParts.slice(2).join(" ")];
  }
  if (queryParts.length === 1) {
    const statement = queryParts[0];
    const notEqual = statement.includes(" != ");
    let statementParts: string[] = [];
    if (notEqual) {
      statementParts = query.split(" != ").map((part) => part.trim());
    } else {
      statementParts = query.split(" = ").map((part) => part.trim());
    }
    if (statementParts.length !== 2) {
      console.error("Invalid conditional statement", statement);
      return false;
    }
    statementParts = statementParts.map((part) => {
      if (part.startsWith("answer")) {
        const match = part.match(/answer\[(\d+)\]/);
        if (match) {
          const answerIndex = parseInt(match[1], 10);
          return answers[answerIndex] || "answer_not_found";
        } else {
          return part;
        }
      } else {
        return part;
      }
    });
    if (statementParts.includes("answer_not_found")) {
      return false;
    }
    return (statementParts[0] === statementParts[1]) !== notEqual;
  } else if (queryParts.length === 3) {
    const leftResult = shouldShowIf(queryParts[0], answers);
    if (leftResult === null) {
      return null;
    }
    const operator = queryParts[1];
    const rightResult = shouldShowIf(queryParts[2], answers);
    if (rightResult === null) {
      return null;
    }
    if (operator === "AND") {
      return leftResult && rightResult;
    } else if (operator == "OR") {
      return leftResult || rightResult;
    } else {
      console.error("Unexpected operator", operator);
      return null;
    }
  } else {
    console.error("Unsupported query", query);
    return null;
  }
}

export function getDependencies(query: string): number[] {
  const regex = /\[(\d+)\]/g;

  const dependencies = new Set<number>();
  let m;
  while ((m = regex.exec(query)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    dependencies.add(parseInt(m[1], 10));
  }

  return Array.from(dependencies);
}
