import { round } from "@/helpers/utils";

import HopService from "@/services/HopService.js";
import store from "@/store";
import OptionsService from "@/services/OptionsService";
import {
  state as PreliminaryInformationState,
  mutations as PreliminaryInformationMutations,
  actions as PreliminaryInformationActions,
} from "@/store/modules/property/preliminary-information";
import {
  state as FittingsContentsState,
  mutations as FittingsContentsMutations,
  actions as FittingsContentsActions,
} from "@/store/modules/property/fittings-contents";
import {
  state as SellersQuestionsState,
  mutations as SellersQuestionsMutations,
  actions as SellersQuestionsActions,
} from "@/store/modules/property/sellers-questions";
import { mutations as UploadMutations, actions as UploadActions } from "@/store/modules/property/uploads";
import _debounce from "lodash/debounce";
import { CheckListItem } from "@/types/Base/CheckListItem";
import router from "@/router";

export const mutations = {
  ...PreliminaryInformationMutations,
  ...FittingsContentsMutations,
  ...SellersQuestionsMutations,
  ...UploadMutations,
  INCREASE_NUM_USERS(state) {
    state.property.num_sellers++;
  },
  SET_SEARCHES_REQUESTED(state, payload) {
    state.property.searches_requested = payload;
  },
  SET_QUESTIONNAIRE_COMPLETION(state, payload) {
    state.property.questionnaire_completion[payload.key] = payload.value;
  },
  UPDATE_FORMS_COMPLETION(state) {
    // Conv enquiries
    if (state.property.data.passport.report.sellers_questions.length == 0) {
      state.property.data.sellers_questions.accepted = true;
    }

    if (
      (state.property.data.sellers_questions.complete == true ||
        state.property.data.passport.report.sellers_questions.length == 0) &&
      state.property.data.sellers_questions.accepted == true
    ) {
      state.property.data.checklist.conveyancing_enquiries = true;
    } else {
      state.property.data.checklist.conveyancing_enquiries = false;
    }
  },
  UPDATE_PROPERTY_QUESTIONNAIRE_COMPLETION(state, payload) {
    state.property.questionnaire_completion[payload.index] = payload.completion;
  },
  UPDATE_CHECK_LIST_ITEMS(state, payload) {
    state.property.check_list_items = payload;
  },
  UPDATE_PROPERTY_COMPLETE(state, payload) {
    if (state.property.complete && state.property.complete !== payload && payload === 100) {
      store.dispatch("notifyAgentComplete");
    }
    state.property.complete = payload;
  },
  UPDATE_PROPERTY(state, payload) {
    if (state.property.complete && state.property.complete !== payload.complete && payload.complete === 100) {
      store.dispatch("notifyAgentComplete");
    }

    state.property = payload;

    if (!state.property.data.scores) {
      // Blank data, add it in
      state.property.data = {
        ...state.property.data,
        // ...OptionsService.blankProperty(),
      };
    }

    if (!state.property.questionnaire_completion) {
      state.property.questionnaire_completion = {};
    }

    if (state.property.data.conveyancing_forms.preliminary_information == undefined) {
      state.property.data.conveyancing_forms.preliminary_information = OptionsService.preliminaryInfo();
    }

    if (state.property.data.uploads == undefined) {
      state.property.data.uploads = OptionsService.uploads();
    }

    store.dispatch("setTouched", false);
  },
};

export const actions = {
  ...PreliminaryInformationActions,
  ...FittingsContentsActions,
  ...SellersQuestionsActions,
  ...UploadActions,
  updateQuestionnaireCompletion({ commit }, payload) {
    commit("SET_QUESTIONNAIRE_COMPLETION", payload);
  },
  updateCurrentProperty(context, payload): Promise<void> | void {
    return new Promise((resolve, reject) => {
      if (payload != undefined) {
        if (payload == state.property.id) {
          resolve();
          return;
        }

        context.commit("UPDATE_LOADING", true);
        // Fetch data
        HopService.getProperty(payload)
          .then(function (response) {
            context.commit("UPDATE_PROPERTY", response.data);
            context.commit("UPDATE_LOADING", false);
            context.commit("UPDATE_CONNECTION_PROBLEM", false);
            resolve();
          })
          .catch((error) => {
            if (error.response.status == 404) {
                context.commit("UPDATE_LOADING", false);
                router.push({ name: "properties" });
                context.dispatch("showAlert", `Property not found with id ${payload}.`);
                return;
            }
            context.commit("UPDATE_LOADING", false);
            context.commit("UPDATE_CONNECTION_PROBLEM", true);
            reject(error);
          });
      } else {
        reject();
      }
    });
  },
  refreshProperty(context): Promise<void> | void {
    return new Promise((resolve, reject) => {
      context.commit("UPDATE_LOADING", true);
      // Fetch data
      HopService.getProperty(state.property.id)
        .then(function (response) {
          context.commit("UPDATE_PROPERTY", response.data);
          context.commit("UPDATE_LOADING", false);
          context.commit("UPDATE_CONNECTION_PROBLEM", false);
          resolve();
        })
        .catch((error) => {
          context.commit("UPDATE_LOADING", false);
          context.commit("UPDATE_CONNECTION_PROBLEM", true);
          reject(error);
        });
    });
  },
  savePropertyData({ commit, dispatch }) {
    savePropertyDataDebounced(commit, dispatch);
  },
  savePropertyDataImmediately({ commit, dispatch }) {
    commit("UPDATE_BACKGROUND_LOADING", true);
    const touched = store.getters.getTouched;
    dispatch("setTouched", false);
    HopService.updateProperty(state.property.id, state.property.data)
      .then((response) => {
        // Update just the completion for "old" questionnaires, but all data is actually returned
        dispatch("updatePropertyQuestionnaireCompletion", {
          index: 2,
          completion: response.data.questionnaire_completion[2],
        });
        dispatch("updatePropertyQuestionnaireCompletion", {
          index: 3,
          completion: response.data.questionnaire_completion[3],
        });

        if (response.data.data.uploads?.uncategorised) {
          dispatch("setUploads", {
            category: "uncategorised",
            value: response.data.data.uploads.uncategorised,
          });
        }
        commit("UPDATE_CHECK_LIST_ITEMS", response.data.check_list_items);
        commit("UPDATE_PROPERTY_COMPLETE", response.data.complete);
        commit("UPDATE_BACKGROUND_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", false);
        // dispatch("showAlert", "Saved!");
      })
      .catch(() => {
        commit("UPDATE_BACKGROUND_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", true);
        dispatch("setTouched", touched);
      });
  },
  updatePropertyQuestionnaireCompletion({ commit }, payload) {
    commit("UPDATE_PROPERTY_QUESTIONNAIRE_COMPLETION", payload);
  },
  orderSearches({ commit, dispatch }, id) {
    HopService.orderSearches(id)
      .then(() => {
        commit("SET_SEARCHES_REQUESTED", true);
      })
      .catch((error) => {
        dispatch("showAlert", "An error occurred while ordering searches: " + error.response.data.message);
      });
  },
  notifyAgentComplete({ commit, dispatch, rootState }) {
    commit("UPDATE_LOADING", true);
    HopService.notifyAgentComplete(state.property.id, rootState.ui.themeId)
      .then(() => {
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", false);
      })
      .catch(() => {
        dispatch("showAlert", "We were unable to notify the agents that documentation is complete.");
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", true);
      });
  },
};

export const state = {
  property: {
    id: null as number | null,
    touched: false,
    created_at_js: "",
    owner_name: null,
    info: {
      name: "",
    },
    data: {
      conveyancing_forms: {
        preliminary_information: PreliminaryInformationState,
        fittings_contents: FittingsContentsState,
      },
      sellers_questions: SellersQuestionsState,
      passport: {
        leasehold: false,
      },
    },
    questionnaire_completion: {} as { all_signed: boolean }[],
    check_list_items: [] as CheckListItem[],
  },
};

export const getters = {
  checkListItemWorth(state) {
    let numItems = 4;
    if (state.property.ta6_part1_active == 1) {
      numItems++;
    }
    if (state.property.data.passport.leasehold) {
      numItems++;
    }
    return 80 / numItems;
  },
  checkListItemWorthKf(state) {
    let numItems = 3;
    if (state.property.ta6_part1_active == 1) {
      numItems++;
    }
    if (state.property.data.passport.leasehold) {
      numItems++;
    }
    return 80 / numItems;
  },
};

const savePropertyDataDebounced = _debounce((commit, dispatch) => {
  dispatch("savePropertyDataImmediately");
}, 5000);
