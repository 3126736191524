<template>
  <div class="alert" :class="{ shown: showAlert }">{{ text }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    showAlert(): boolean {
      return this.$store.state["ui"].showAlert;
    },
    text(): string {
      return this.$store.state["ui"].alertText;
    },
  },
});
</script>

<style lang="scss" scoped>
.alert {
  pointer-events: none;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s;
  top: 10px;
  z-index: 3;
  font-size: 20px;
  background-color: var(--colour-primary);
  border-left: 5px solid var(--colour-secondary);
  color: var(--colour-white);
  padding: 10px 20px 10px 10px;

  &.shown {
    transform: translateX(0);
  }
}
</style>
