<template>
  <QuestionnaireForm :questionnaireId="4">
    <h1>About my home</h1>
    <p>
      Telling us more information about your house and your sale at this stage will put you ahead of the game. The information you
      provide will be available to your estate agent and your solicitor, and you should be aware that it may also be made
      available to potential buyers or included in documentation which may be in the public domain (eg sales particulars posted on
      property portals to advertise your property).
    </p>
  </QuestionnaireForm>
</template>

<script>
import QuestionnaireForm from "@/components/Forms/Questionnaires/QuestionnaireForm.vue";

export default {
  components: {
    QuestionnaireForm,
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
