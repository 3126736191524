<template>
  <div class="question">
    <div class="count">{{ index + 1 }} / {{ numQuestions }}</div>
    <div class="name" v-if="typeof nameModel != 'string'">
      {{ questionName }}
    </div>
    <BaseInput
      v-if="typeof nameModel == 'string'"
      type="text"
      class="input"
      :placeholder="otherLabel"
      :modelValue="nameModel"
      :maxlength="titleMaxLength"
      @update:modelValue="updateModel('name', $event)"
    />
    <!-- This shows for kitchen -->
    <BaseSelect
      v-if="modelKey == 'kitchen'"
      class="select"
      :options="kitchenOptions"
      :value="fittedModel"
      @input="updateModel('fitted', $event)"
    />
    <!-- This shows for non-other -->
    <BaseSelect
      v-if="typeof nameModel != 'string' && modelKey != 'kitchen'"
      class="select"
      :options="options"
      :value="includedModel"
      @input="updateModel('included', $event)"
    />
    <!-- This shows for kitchen when fitted -->
    <BaseSelect
      v-if="modelKey == 'kitchen' && fittedModel != 0 && fittedModel != 3"
      class="select"
      :options="options"
      :value="includedModel"
      @input="updateModel('included', $event)"
    />
    <!-- This shows for other -->
    <BaseSelect
      v-if="typeof nameModel == 'string' && modelKey != 'kitchen'"
      class="select"
      :options="otherIncluded"
      :value="includedModel"
      @input="updateModel('included', $event)"
    />
    <BaseInput
      type="number"
      class="input"
      placeholder="0"
      :modelValue="priceModel"
      @update:modelValue="updateModel('price', $event)"
      contentBefore="£"
      :required="false"
    />
    <p class="subtitle">Please enter 0 if N/A</p>
    <p class="name">Comments</p>
    <BaseTextArea
      rows="2"
      :maxlength="descriptionMaxLength"
      :modelValue="commentsModel"
      @update:modelValue="updateModel('comments', $event)"
    />
    <Uploader
      v-if="priceModel != 0 && priceModel != null"
      :modelValue="uploadsModel"
      label="You can upload a picture of the item you wish to sell if you like:"
      @update:modelValue="updateUploadsModel($event)"
    />
    <BaseButton class="addBtn" @click="addOption" v-if="index + 1 == numQuestions && index + 1 != maxQuestions">
      Add another item in {{ sectionTitle }} section
    </BaseButton>
  </div>
</template>

<script>
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import _clone from "lodash/clone";
import permissionsValues from "@/helpers/permissionsValues";
import Permissions from "@/helpers/permissions";

export default {
  components: {
    Uploader,
  },
  props: {
    index: Number,
    questionKey: String,
    maxQuestions: Number,
    model: Object,
    modelKey: String,
    numQuestions: Number,
    options: Array,
    questionName: String,
    otherLabel: String,
    sectionTitle: String,
  },
  data() {
    return {
      otherIncluded: this.options.filter(function (obj) {
        // This is included options minus "none" for additional "other" items
        return obj.value !== "3";
      }),
      priceModel: this.model.price,
      commentsModel: this.model.comments,
      includedModel: this.model.included,
      fittedModel: this.model.fitted,
      nameModel: this.model.name,
      uploadsModel: this.model.files,
      kitchenOptions: this.$store.state.options.options.fittings_contents.select_options.kitchen_fitted,
    };
  },
  methods: {
    updateUploadsModel(val) {
      if (this.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS)) {
        return;
      }
      this.$store.dispatch("updateFittingsContentsQuestion", {
        modelKey: this.modelKey,
        questionKey: this.questionKey,
        propKey: "files",
        value: _clone(val),
      });
      this.$store.dispatch("savePropertyData");
    },
    updateModel(propKey, val) {
      if (this.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS)) {
        return;
      }
      this.$store.dispatch("updateFittingsContentsQuestion", {
        modelKey: this.modelKey,
        questionKey: this.questionKey,
        propKey: propKey,
        value: val,
      });
    },
    addOption(event) {
      if (event) {
        event.preventDefault();
      }
      this.$emit("addOption");
    },
  },
  computed: {
    descriptionMaxLength() {
      if (this.modelKey == "kitchen") {
        return 25;
      } else {
        return 32;
      }
    },
    titleMaxLength() {
      if (this.modelKey == "kitchen") {
        return 25;
      } else {
        return 29;
      }
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  watch: {
    model: {
      handler(newValue) {
        // This is so that when a new item is created, this.*model is up to date for recreating the DOM
        if (newValue) {
          this.priceModel = newValue.price;
          this.commentsModel = newValue.comments;
          this.includedModel = newValue.included;
          this.fittedModel = newValue.fitted;
          this.nameModel = newValue.name;
          // this.uploadsModel = JSON.parse(JSON.stringify(newValue.files));
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  background-color: $colour-light-grey;
  text-align: center;
  padding: 20px 0;

  .addBtn {
    margin: 0 30px;
  }

  .count {
    color: var(--colour-primary);
    font-weight: 500;
  }

  .subtitle {
    font-size: 12px;
    margin: 10px 0;
  }

  .select,
  .input,
  .textarea {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .name {
    font-weight: 500;
    font-size: 16px;
    color: $colour-grey;
  }
}
</style>
