import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bbe156fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "select-input question-content tiles"
}
const _hoisted_2 = {
  key: 1,
  class: "select-input question-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseRadio = _resolveComponent("BaseRadio")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    (Object.keys(_ctx.answers).length <= 8)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (option, key) => {
            return (_openBlock(), _createBlock(_component_BaseRadio, {
              key: key,
              label: option,
              name: _ctx.id + '_radio',
              checked: _ctx.answer == key.toString(),
              value: _ctx.answer,
              onInput: ($event: any) => (_ctx.$emit('input', key))
            }, null, 8, ["label", "name", "checked", "value", "onInput"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BaseSelect, {
            name: _ctx.id + '_select',
            class: "select",
            options: _ctx.selectAnswers,
            value: _ctx.answer,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event)))
          }, null, 8, ["name", "options", "value"])
        ]))
  ]))
}