<template>
  <div>
    <div class="answer" v-for="(answers, answersKey) in answersList" :key="answersKey">
      <div class="answer-heading">
        <span class="important">{{ answersKey + 1 }}. </span>
        <BaseButton v-if="answersList.length != 1" :small="true" @click="removeAnswer(answersKey)"> Delete </BaseButton>
      </div>

      <Question
        v-for="(question, questionKey) in questions"
        :propertyId="4"
        :key="answersKey + questionKey"
        :answer="answers[questionKey]"
        :question="question"
        @input="(event) => updateAnswer(answersKey, questionKey, event)"
      />
    </div>
    <BaseButton :small="true" @click="addAnswer()"> Add another </BaseButton>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    answer: {
      type: Array as PropType<Array<Array<string>>>,
      default: () => [],
    },
    questions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      answersList: this.answer?.length > 0 ? this.answer : [this.questions?.map(() => "")],
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAnswer(answersKey: number, questionKey: number, answer: any): void {
      this.answersList[answersKey][questionKey] = answer;
      this.$emit("input", this.answersList);
    },
    addAnswer(): void {
      this.answersList.push(this.questions?.map(() => ""));
    },
    removeAnswer(answersKey: number): void {
      this.answersList.splice(answersKey, 1);
      this.$emit("input", this.answersList);
    },
  },
});
</script>

<style lang="scss" scoped>
.answer {
  border: solid 1px lightgrey;
  padding: 20px;
  margin: 10px 0;

  .answer-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
  }
}
</style>
