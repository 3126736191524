<template>
  <div>
    <FormSection title="1. Property being sold" :complete="model.preliminary_information.progress[1]" :bottomBorder="true">
      <form class="questions">
        <p class="note">These answers may be pre-filled with information from the title deeds. Please check they are correct.</p>
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">1.1</span>
              <span> {{ options.preliminary_information.questions["1_1"] }}</span>
            </p>
            <div class="question-content">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['1_1']"
                @update:modelValue="$emit('updateModelLevel1', '1_1', $event)"
                type="text"
                placeholder="Full address"
              />
              <BaseInput
                :modelValue="model.preliminary_information.questions['1_1_i']"
                @update:modelValue="$emit('updateModelLevel1', '1_1_i', $event)"
                type="text"
                placeholder="Postcode"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
  },
});
</script>
