<template>
  <div class="input" :class="{ 'not-required': !this.required || placeHolderGrey }">
    <label v-if="label">{{ label }}</label>
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="#ff0505" v-if="isInvalid" v-bind:class="'invalid'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>
    <input v-bind="$attrs" type="date" :class="{ isInvalid: isInvalid }" :value="value" @input="updateValue" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: [String, Number],
    required: {
      type: Boolean,
      default: true,
    },
    placeHolderGrey: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      touched: false,
    };
  },
  computed: {
    isInvalid(): boolean {
      if (!this.touched) {
        return false;
      }
      return false;
    },
  },
  methods: {
    updateValue(event): void {
      this.touched = true;
      this.$emit("input", event.target.value);
    },
  },
});
</script>

<style lang="scss" scoped>
.input {
  margin: 10px auto 0;
  position: relative;

  .before {
    position: absolute;
    top: 8px;
    left: 10px;
    font-weight: bold;
    color: $colour-grey;
  }

  input {
    width: 100%;
    height: 32px;
    text-align: center;
    color: $colour-grey;

    &.isInvalid {
      padding: 0 30px;
    }
  }

  .invalid {
    // background-color: red;
    height: 32px;
    // width: 10px;
    position: absolute;
    right: 5px;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $colour-red;
    font-weight: bold;
    opacity: 1; /* Firefox */
  }

  &.not-required ::placeholder {
    color: $colour-grey;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $colour-red;
    font-weight: bold;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $colour-red;
    font-weight: bold;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
