<template>
  <div class="question">
    <div v-if="question?.question" class="question-title">
      <div class="question-text" v-html="question?.question"></div>
      <QuestionWidgets :widgets="question?.widgets" />
      <span v-if="question?.read_only" class="tooltip tooltip-left read-only-lock">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="#e8eaed" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240zm0-80h480v-400H240v400zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280zM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80zM240-160v-400 400z"/></svg>
        <span class="tooltiptext">Read only</span>
      </span>
    </div>
    <div class="question-content" :class="{ 'read-only': question?.read_only }">
      <SelectInput
        v-if="question?.type == 'select'"
        :answers="question?.answers"
        :answer="answer?.toString()"
        @input="$emit('input', $event)"
      />
      <CheckboxInput
        v-if="question?.type == 'checkbox'"
        :answers="question?.answers"
        :answer="answer"
        @update:modelValue="$emit('input', $event)"
      />
      <TextInput
        v-if="['text', 'currency', 'email', 'month', 'number', 'percentage'].includes(question?.type)"
        :answer="answer"
        :type="question?.type"
        @input="$emit('input', $event)"
      />
      <TextAreaInput v-if="question?.type == 'textarea'" :answer="answer" @input="$emit('input', $event)" />
      <ListInput
        v-if="question?.type == 'list'"
        :answer="answer"
        :questions="question.list_questions"
        @input="$emit('input', $event)"
      />
      <Uploader v-if="question?.type == 'upload'" :modelValue="answer" @update:modelValue="$emit('input', $event)" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CheckboxInput from "./CheckboxInput.vue";
import SelectInput from "./SelectInput.vue";
import TextInput from "./TextInput.vue";
import TextAreaInput from "./TextAreaInput.vue";
import ListInput from "./ListInput.vue";
import QuestionWidgets from "./QuestionWidgets.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";

export default defineComponent({
  components: {
    Uploader,
    SelectInput,
    TextInput,
    CheckboxInput,
    TextAreaInput,
    ListInput,
    QuestionWidgets,
  },
  props: {
    question: Object,
    answer: [String, Number, Array],
    propertyId: Number,
  },
});
</script>

<style lang="scss" scoped>
.read-only {
  pointer-events: none;
}

.question-title {
  position: relative;

  .question-text {
    padding-right: 28px;
  }

  .read-only-lock {
    position: absolute;
    right: 40px;
    top: 0;

    svg {
      pointer-events: none;
      height: 1lh;
      fill: var(--colour-primary);
    }
  }
}
</style>
