<template>
  <div class="reports">
    <h1>Admin Panel for {{ user.organisation.name }}</h1>
    <p v-if="user.organisation.credits != -1">Credits remaining: {{ user.organisation.credits }}</p>
    <p v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES_UNDER_BRAND)">
      There are {{ brand.num_agencies }} agencies under this brand.
    </p>
    <div v-if="hasPermissionTo(permissions.ACTION_SET_GLOBAL_ADMIN_OPTIONS)" class="global-options">
      <h2>Global settings</h2>
      <div>
        <BaseInput label="Per-brand monthly HOP limit (-1 for unlimited)" v-model="monthlyHopLimit" />
        <BaseButton :small="true" @click="updateMonthlyLimit">Update</BaseButton>
      </div>
    </div>
    <h2 v-if="hasPermissionTo(permissions.ACTION_CREATE_AGENCY)">Estate Agents</h2>
    <div v-if="hasPermissionTo(permissions.ACTION_CREATE_AGENCY)" class="buttons">
      <BaseButton @click="$router.push({ name: 'agencies' })">Agencies</BaseButton>
      <BaseButton v-if="hasPermissionTo(permissions.ACTION_CREATE_AGENT)" @click="$router.push({ name: 'agents' })"
        >Individual Agents</BaseButton
      >
    </div>
    <h2 v-if="hasPermissionTo(permissions.ACTION_VIEW_SOLICITORS)">Solicitors</h2>
    <div v-if="hasPermissionTo(permissions.ACTION_VIEW_SOLICITORS)" class="buttons">
      <BaseButton @click="$router.push({ name: 'solicitorsfirms' })">Solicitors' firms</BaseButton>
      <BaseButton v-if="hasPermissionTo(permissions.ACTION_CREATE_AGENT)" @click="$router.push({ name: 'solicitors' })"
        >Individual Solicitors</BaseButton
      >
    </div>
    <h2 v-if="hasPermissionTo(permissions.ACTION_VIEW_MOVERS)">Movers</h2>
    <div v-if="hasPermissionTo(permissions.ACTION_VIEW_MOVERS)" class="single">
      <BaseButton @click="$router.push({ name: 'movers' })">Individual Movers</BaseButton>
    </div>

    <h2>Properties</h2>
        
    <div class="buttons">
      <BaseButton
       v-if="hasPermissionTo(permissions.ACTION_CREATE_PROP)"
       @click="$router.push({ name: 'listproperties'})"
        >Browse Properties</BaseButton>
      <BaseButton v-if="hasPermissionTo(permissions.ACTION_CREATE_PROP)" @click="$router.push({ name: 'addproperty' })"
        >Add Property</BaseButton
      >
    </div>
    <h2 v-if="hasPermissionTo(permissions.ACTION_VIEW_BRANDS)">Brands</h2>
    <div v-if="hasPermissionTo(permissions.ACTION_VIEW_BRANDS)" class="single">
      <BaseButton @click="$router.push({ name: 'brands' })">Brands</BaseButton>
    </div>
    <h2 v-if="hasPermissionTo(permissions.REPORT_PROP_ATTRIBUTES)">Reports</h2>
    <div v-if="hasPermissionTo(permissions.REPORT_PROP_ATTRIBUTES)" class="single">
      <BaseButton v-if="hasPermissionTo(permissions.REPORT_PROP_ATTRIBUTES)" @click="$router.push({ name: 'propattrs' })"
        >Property Attributes Insights</BaseButton
      >
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";
import moment from 'moment';

export default {
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    usersOrgName() {
      return this.user.organisation.name;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  data() {
    return {
      brand: {},
      monthlyHopLimit: 0,
      searchProperty: false,
      searchTerm: "",
    };
  },
  methods: {
    updateMonthlyLimit() {
      this.$store.dispatch("updateLoading", true);
      HopService.setMonthlyHopLimit(this.monthlyHopLimit)
        .then(() => {
          this.$store.dispatch("showAlert", "Monthly HOP limit updated");
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("connectionProblem", true);
          this.$store.dispatch("showAlert", "Error updating monthly HOP limit");
        });
    },
  },
  created() {
    if (this.hasPermissionTo(this.permissions.ACTION_VIEW_BRAND)) {
      HopService.getBrand()
        .then((response) => {
          this.brand = response.data;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("connectionProblem", true);
        });

      if (this.hasPermissionTo(this.permissions.ACTION_SET_GLOBAL_ADMIN_OPTIONS)) {
        HopService.getMonthlyHopLimit().then((response) => {
          this.monthlyHopLimit = response.data.value;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.link {
  font-weight: bold;
}

p {
  margin-top: 10px;
}

h2 {
  margin: 5px 0 0;
}

.single {
  .container {
    // margin: 0;
    :deep(.btn) {
      min-width: 162px;
      // padding: 8px 20px;
    }
  }
}

.buttons {
  max-width: 370px;
  margin: 10px auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  align-items: stretch;

  .container {
    margin: 0;
    :deep(.btn) {
      width: 100%;
      padding: 8px 20px;
    }
  }
}

.global-options {
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;

  :deep(input) {
    max-width: 300px;
    display: block;
    margin: 0 auto 10px;
  }
}

</style>
