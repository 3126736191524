<template>
  <div class="container tooltip" :class="{ small: small, secondary: secondaryColour, disabled }" :style="{style}">
    <a v-if="url" class="link" :href="url" target="_blank">
      <button v-bind="$attrs" class="btn" :class="buttonClass">
        <slot />
      </button>
    </a>
    <button type="button" v-if="!url" v-bind="$attrs" class="btn" @click.stop="$emit('click')" :class="buttonClass">
      <slot />
    </button>
    <span v-if="tooltip" class="tooltiptext">{{ tooltip }}</span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["click"],
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
      default: "",
    },
    url: String,
    small: Boolean,
    secondaryColour: Boolean,
    tooltip: {
      type: String,
      default: "",
    },
    noMargin: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    disabled() {
      return this.$attrs.disabled;
    },
    style() {
      return this.noMargin ? '' : 'margin: 10px 0';
    }
  }
});

</script>

<style lang="scss" scoped>
.container {
  text-align: center;

  user-select: none;
  
  .link {
    display: inline-block;
    .btn {
      pointer-events: none;
    }
  }

  &.secondary {
    .btn {
      background-color: var(--colour-secondary);
      color: var(--colour-primary);

      &:focus {
        filter: brightness(110%);
        outline: 0;
      }

      &:active {
        filter: brightness(90%);
        outline: 0;
      }
    }
  }

  &.small {
    margin: 0;
    display: inline;
    .btn {
      display: inline;
      padding: 6px 8px;
    }
  }

  &.disabled {
    .btn {
      opacity: 0.5;
      cursor: auto;
      pointer-events: none;
    }
    .link {
      cursor: auto;
      pointer-events: none;
    }
  }

  .btn {
    margin: 0 auto;
    display: block;
    background-color: var(--colour-primary);
    padding: 8px 28px;
    font-size: 14px;
    color: var(--colour-white);
    border: 0;
    font-weight: 400;
    cursor: pointer;

    &:active,
    &:focus {
      filter: brightness(105%);
      // background-color: lighten(var(--colour-primary), 5%);
      outline: 0;
    }

    &.white {
      background-color: $colour-light-grey;
      color: $colour-grey;
    }
  }
}
</style>
