import Dashboard from "@/views/Dashboard.vue";
import Properties from "@/views/Properties/Properties.vue";
import Auth from "@/views/Auth.vue";
import AboutMyHome from "@/views/AboutMyHome.vue";
import Ta10FittingsContents from "@/views/ConveyancingForms/Ta10FittingsContents.vue";
import Ta7LeaseholdInformation from "@/views/ConveyancingForms/Ta7LeaseholdInformation.vue";
import Ta6PropertyInformation from "@/views/ConveyancingForms/Ta6PropertyInformationV2.vue";
import Ta6PreliminaryInformation from "@/views/ConveyancingForms/Ta6PreliminaryInformation.vue";
import AddProperty from "@/views/Properties/AddProperty.vue";
import Explainer from "@/views/Explainer.vue";
import InviteSeller from "@/views/InviteSeller.vue";
import InviteSolicitor from "@/views/InviteSolicitor.vue";
import InviteAgent from "@/views/InviteAgent.vue";
import ConveyancingEnquiries from "@/views/ConveyancingEnquiries.vue";
import Enquiry from "@/views/Enquiry.vue";
import Admin from "@/views/Admin.vue";
import Brands from "@/views/Admin/Brands.vue";
import Agencies from "@/views/Admin/Agencies.vue";
import SolicitorsFirms from "@/views/Admin/SolicitorsFirms.vue";
import Solicitors from "@/views/Admin/Solicitors.vue";
import PropertySignatures from "@/views/Admin/PropertySignatures.vue";
import Agents from "@/views/Admin/Agents.vue";
import Movers from "@/views/Admin/Movers.vue";
import Root from "@/views/Root.vue";
import ListProperties from "@/views/Admin/ListProperties.vue";
import PropAttributes from "@/views/Admin/Reports/PropAttributes.vue";
import AdminPropertyManage from "@/views/Admin/AdminPropertyManage.vue";
import store from "./store/index.ts";
import * as authHelpers from "./helpers/authentication.js";
import { createWebHistory } from "vue-router";
import { createRouter } from "vue-router";
import { getAuth0IsLoading, getAuth0IsAuthenticated } from "./services/HopService.js";
import Organisation from "./views/Admin/Organisation.vue";

const routes = [
  {
    path: "/",
    name: "root",
    component: Root,
    meta: {
      public: true,
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
  },
  {
    path: "/admin/",
    name: "admin",
    component: Admin,
  },
  {
    path: "/admin/brands/",
    name: "brands",
    component: Brands,
  },
  {
    path: "/admin/agencies/",
    name: "agencies",
    component: Agencies,
  },
  {
    path: "/admin/solicitors-firms/",
    name: "solicitorsfirms",
    component: SolicitorsFirms,
  },
  {
    path: "/admin/agents/",
    name: "agents",
    component: Agents,
  },
  {
    path: "/admin/solicitors/",
    name: "solicitors",
    component: Solicitors,
  },
  {
    path: "/admin/movers/",
    name: "movers",
    component: Movers,
  },
  {
    path: "/admin/organisation/:id",
    name: "organisation",
    component: Organisation,
    props: true,
  },
  {
    path: "/admin/properties/",
    name: "listproperties",
    component: ListProperties,
  },
  {
    path: "/admin/properties/:id/signatures",
    name: "propertysignatures",
    component: PropertySignatures,
    props: true,
  },
  {
    path: "/admin/properties/:id/manage",
    name: "adminpropertymanage",
    component: AdminPropertyManage,
    props: true,
  },
  {
    path: "/admin/reports/attributes",
    name: "propattrs",
    component: PropAttributes,
  },
  {
    path: "/properties/",
    name: "properties",
    component: Properties,
  },
  {
    path: "/properties/:id/enquiry/",
    name: "enquiry",
    component: Enquiry,
  },
  {
    path: "/properties/:id/help/",
    name: "help",
    component: Enquiry,
  },
  {
    path: "/properties/:id/explainer/",
    name: "explainer",
    component: Explainer,
  },
  {
    path: "/properties/new",
    name: "addproperty",
    component: AddProperty,
  },
  {
    path: "/properties/:id",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/properties/:id/invite-seller",
    name: "invite-seller",
    component: InviteSeller,
  },
  {
    path: "/properties/:id/invite-solicitor",
    name: "invite-solicitor",
    component: InviteSolicitor,
  },
  {
    path: "/properties/:id/invite-agent",
    name: "invite-agent",
    component: InviteAgent,
  },
  {
    path: "/properties/:id/about-my-home",
    name: "about-my-home",
    component: AboutMyHome,
  },
  {
    path: "/properties/:id/fixtures-fittings",
    name: "fixtures-fittings",
    component: Ta10FittingsContents,
  },
  {
    path: "/properties/:id/property-information",
    name: "property-information",
    component: Ta6PropertyInformation,
  },
  {
    path: "/properties/:id/preliminary-information",
    name: "preliminary-information",
    component: Ta6PreliminaryInformation,
  },
  {
    path: "/properties/:id/leasehold-information",
    name: "leasehold-information",
    component: Ta7LeaseholdInformation,
  },
  {
    path: "/properties/:id/conveyancing-enquiries",
    name: "conveyancing-enquiries",
    component: ConveyancingEnquiries,
  },
  {
    path: "/*",
    beforeEnter() {
      window.location = "/";
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.params.id == undefined) {
    store.dispatch("appIsLoaded", true);
    store.dispatch("updateLoading", false);
  }

  if (to.name == "root" || to.name == "auth") {
    next();
  } else {
    checkRoutePermissions(to, from, next);
  }
});

function checkRoutePermissions(to, from, next) {
  if (getAuth0IsLoading()) {
    setTimeout(() => {
      return checkRoutePermissions(to, from, next);
    }, 100);
    return;
  }
  if (store.getters.getUserRole == -1 && !getAuth0IsLoading() && !getAuth0IsAuthenticated()) {
    window.location = "/auth";
  }

  if (store.getters.getUserRole == -1) {
    store.dispatch("updateLoading", true);
    setTimeout(() => {
      return checkRoutePermissions(to, from, next);
    }, 100);
  } else {
    if (authHelpers.checkRoutePermissions(to, store.getters.getUserRole)) {
      store.dispatch("updateLoading", false);
      next();
    } else {
      window.location = "/auth";
    }
  }
}
export default router;
