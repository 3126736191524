import { DropDownListItem } from "@/types/Base/DropDownListItem";
import { PreliminaryInformation } from "@/types/Property/PreliminaryInformation";
import _forEach from "lodash/forEach";

export default {
  floors(): DropDownListItem[] {
    const options = [] as DropDownListItem[];
    options.push({
      label: "Ground",
      value: 1000,
    });
    for (let i = 1; i <= 100; i++) {
      options.push({
        label: String(i),
        value: i,
      });
    }
    return options;
  },
  next50Years(): DropDownListItem[] {
    const years = [] as DropDownListItem[];
    years.push({
      label: "Unknown",
      value: 1000,
    });
    for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + 50; i++) {
      years.push({
        label: String(i),
        value: i,
      });
    }
    return years;
  },
  yearsFrom1970(): DropDownListItem[] {
    const years = [] as DropDownListItem[];
    years.push({
      label: "Not applicable",
      value: -1,
    });
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      years.push({
        label: String(i),
        value: i,
      });
    }
    years.push({
      label: "Before 1970",
      value: 1000,
    });
    return years;
  },
  parkingTypes() {
    const options = [
      "None",
      "Garage",
      "On Street",
      "Communal car park (without allocated space)",
      "Communal car park (with allocated space)",
      "Residents Permit",
      "Driveway",
      "Metered Parking",
    ];
    const selectOptions = [] as DropDownListItem[];
    _forEach(options, function (value) {
      selectOptions.push({
        label: value,
        value: value,
      });
    });

    return selectOptions;
  },
  preliminaryInfo(): PreliminaryInformation {
    return {
      accepted: false,
      complete: false,
      progress: {
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "13": 0,
        "14": 0,
        "15": 0,
        "16": 0,
        "17": 0,
        "18": 0,
        "19": 0,
        "20": 0,
        "21": 0,
      },
      questions: {
        "1_1": "",
        "1_1_i": "",
        "2_1_i": "",
        "2_1_ii": "",
        "3_1": 0,
        "3_2_i": {
          yes: 0,
          files: [],
        },
        "3_2_1": "",
        "3_2_2_1": "",
        "3_2_2_2": 0,
        "3_2_2_2_i": "",
        "3_2_2_2_ii": "",
        "3_2_2_2_iii": "",
        "3_2_2_3": 0,
        "3_2_2_3_i": "",
        "3_2_2_3_ii": "",
        "3_2_2_3_iii": "",
        "3_2_3_1": 0,
        "3_2_3_2": "",
        "3_2_3_3": {
          yes: 0,
          files: [],
        },
        "3_2_3_4_i": 0,
        "3_2_3_4_ii": 0,
        "3_2_3_5": "",
        "3_2_4": {
          yes: 0,
        },
        "3_2_4_i": 0,
        "3_2_4_ii": 0,
        "3_2_4_iii": 0,
        "4_1": {
          yes: 0,
          files: [],
        },
        "5_1": {
          yes: 0,
          option: 0,
          details: "",
          other: "",
          month: 0,
          year: 0,
        },
        "5_2": {
          yes: 0,
          items: [],
          details: "",
        },
        "6_1": {
          items: [],
          yes: 0,
        },
        "6_2": [
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
          {
            details: "",
            month: 0,
            year: 0,
          },
        ],
        "6_2_1": {
          yes: 0,
          details: "",
        },
        "6_2_2": {
          yes: 0,
          files: [],
        },
        "7_1": {
          items: [],
          details: "",
          yes: 0,
        },
        "8_1": 0,
        "8_1_1": "",
        "8_1_2": 0,
        "8_1_3": 0,
        "8_1_3_i": {
          files: [],
          yes: 0,
        },
        "9_1": {
          files: [],
          yes: 0,
          upload: 0,
        },
        "9_2": {
          files: [],
          yes: 0,
          upload: 0,
        },
        "10_1": 0,
        "10_1_1": 0,
        "10_1_2": {
          yes: 0,
          files: [],
        },
        "11_1": {
          yes: 0,
          details: "",
        },
        "12_1": {
          yes: 0,
          items: [],
        },
        "12_1_i": [
          {
            details: "",
            cost: null,
          },
          {
            details: "",
            cost: null,
          },
          {
            details: "",
            cost: null,
          },
          {
            details: "",
            cost: null,
          },
          {
            details: "",
            cost: null,
          },
        ],
        "13_1": {
          yes: 0,
          details: "",
          items: [],
        },
        "13_1_i": [
          {
            cost: null,
            payee: "",
          },
          {
            cost: null,
            payee: "",
          },
          {
            cost: null,
            payee: "",
          },
          {
            cost: null,
            payee: "",
          },
        ],
        "13_2": {
          yes: 0,
        },
        "13_3": {
          yes: 0,
        },
        "14_1": {
          items: [],
          details: "",
        },
        "15_1": {
          yes: 0,
          details: "",
          items: [],
        },
        "16_1": {
          other: "",
          details: "",
        },
        "16_2": {
          yes: 0,
        },
        "17_1": {
          yes: 0,
        },
        "17_2": {
          yes: 0,
        },
        "17_2_1": "",
        "17_2_2": {
          yes: 0,
        },
        "17_3": {
          yes: 0,
        },
        "17_3_1": {
          yes: 0,
        },
        "17_3_2": {
          yes: 0,
        },
        "17_4": {
          upload: 0,
          files: [],
        },
        "18_1": {
          yes: 0,
          items: [],
        },
        "18_2": {
          yes: 0,
          upload: 0,
          files: [],
        },
        "19_1": {
          yes: 0,
          details: "",
        },
        "20_1": {
          yes: 0,
          details: "",
        },
        "20_2": {
          yes: 0,
          details: "",
        },
        "21_1": {
          yes: 0,
        },
        "21_2": {
          yes: 0,
        },
        "21_3": {
          yes: 0,
        },
        "21_4": {
          yes: 0,
          details: "",
        },
        "21_5": "",
      },
    } as PreliminaryInformation;
  },
  uploads() {
    return {
      leasehold_agreement: [],
      uncategorised: [],
    };
  },
  allMonths(): DropDownListItem[] {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const months = [] as DropDownListItem[];
    _forEach(monthNames, function (month, index) {
      months.push({
        label: month,
        value: index + 1,
      });
    });
    return months;
  },
  // blankProperty() {
  //   return {
  //     checklist: {
  //       sellers_information: false,
  //       rewards: false,
  //       conveyancing_enquiries: false,
  //     },
  //     scores: {
  //       ready_percentage: 20,
  //       ready_weeks: 12,
  //     },
  //     conveyancing_forms: {
  //       fittings_contents: {
  //         questions: {
  //           basic: {
  //             progress: 0,
  //             questions: {
  //               boiler_immersion: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               radiators_wall: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               night_storage: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               free_standing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               gas_fires: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               electric_fires: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               light_switches: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               roof_insulation: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               window_fittings: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               window_shutters: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               int_door_fittings: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               ext_door_fittings: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               doorbell: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               sockets: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               burglar_alarm: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           kitchen: {
  //             progress: 0,
  //             questions: {
  //               hob: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               extractor_hood: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               oven: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               cooker: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               microwave: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               fridge_freezer: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               freezer: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dishwasher: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               tumble_dryer: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               washing_machine: {
  //                 included: 0,
  //                 fitted: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           bathroom: {
  //             progress: 0,
  //             questions: {
  //               bath: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               shower_fitting: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               shower_curtain: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bathroom_cabinet: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               taps: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               separate_shower: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               towel_rail: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               soap_toothbrush_holders: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               toilet_roll_holders: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bathroom_mirror: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           carpets: {
  //             progress: 0,
  //             questions: {
  //               hall_stairs_landing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               living_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dining_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               kitchen: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_2: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_3: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           curtain_rails_poles_pelmets: {
  //             progress: 0,
  //             questions: {
  //               hall_stairs_landing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               living_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dining_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               kitchen: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_2: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_3: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           curtains_blinds: {
  //             progress: 0,
  //             questions: {
  //               hall_stairs_landing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               living_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dining_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               kitchen: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_2: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_3: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           light_fittings: {
  //             progress: 0,
  //             questions: {
  //               hall_stairs_landing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               living_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dining_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               kitchen: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_2: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_3: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           fitted_units: {
  //             progress: 0,
  //             questions: {
  //               hall_stairs_landing: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               living_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dining_room: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               kitchen: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_2: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               bedroom_3: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           outdoor_area: {
  //             progress: 0,
  //             questions: {
  //               garden_furniture: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               garden_ornaments: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               trees_plants_shrubs: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               barbecue: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               dustbins: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               garden_shed: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               greenhouse: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               outdoor_heater: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               outside_lights: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               water_butt: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               clothes_line: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               rotary_line: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           television_telephone: {
  //             progress: 0,
  //             questions: {
  //               telephone_receivers: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               television_aerial: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               radio_aerial: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               satellite_dish: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           fuel: {
  //             progress: 0,
  //             questions: {
  //               oil: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               wood: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //               lpg: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //               },
  //             },
  //           },
  //           other: {
  //             questions: {
  //               other_1: {
  //                 included: 0,
  //                 price: null,
  //                 files: [],
  //                 comments: "",
  //                 name: "",
  //               },
  //             },
  //           },
  //         },
  //         accepted: false,
  //         complete: false,
  //       },
  //       property_information: {
  //         complete: false,
  //         accepted: false,
  //         progress: {
  //           1: 0,
  //           2: 0,
  //           3: 0,
  //           4: 0,
  //           5: 0,
  //           6: 0,
  //           7: 0,
  //           8: 0,
  //           9: 0,
  //           10: 0,
  //           11: 0,
  //           12: 0,
  //           13: 0,
  //           14: 0,
  //         },
  //         questions: {
  //           "1_1_a": "",
  //           "1_1_b": "",
  //           "1_1_c": "",
  //           "1_1_d": "",
  //           "1_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "1_3": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "1_4": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "1_5": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "1_6": {
  //             yes: 0,
  //             upload: 0,
  //             details: "",
  //           },
  //           "1_7": {
  //             yes: 0,
  //             files: [],
  //           },
  //           "2_1": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "2_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "3_1": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "3_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "4_1_a": {
  //             yes: 0,
  //             items: [],
  //           },
  //           "4_1_b": {
  //             yes: 0,
  //             year: 0,
  //           },
  //           "4_1_c": {
  //             yes: 0,
  //             year: 0,
  //           },
  //           "4_1_d": {
  //             yes: 0,
  //             year: 0,
  //           },
  //           "4_2_a": {
  //             yes: 0,
  //             files: [],
  //           },
  //           "4_2_a_i": {
  //             yes: 0,
  //           },
  //           "4_2_b": "",
  //           "4_3": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "4_4": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "4_5": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "4_6_a": {
  //             yes: 0,
  //             year: 0,
  //           },
  //           "4_6_b": {
  //             yes: 0,
  //           },
  //           "4_6_c": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "4_7_a": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "4_7_b": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "4_8": {
  //             yes: 0,
  //           },
  //           "4_8_a": {
  //             yes: 0,
  //           },
  //           "4_8_b": {
  //             yes: 0,
  //             files: [],
  //           },
  //           "4_9": {
  //             files: [],
  //           },
  //           "5_1_a": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_b": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_c": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_d": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_e": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_f": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_g": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_h": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_1_i": {
  //             yes: 0,
  //             details: "",
  //             upload: 0,
  //             files: [],
  //           },
  //           "5_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_1": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_3": {
  //             yes: 0,
  //           },
  //           "6_4_a": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_4_b": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_4_c": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_4_d": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "6_5": {
  //             yes: 0,
  //             items: [],
  //           },
  //           "7_1": {
  //             yes: 0,
  //             items: [],
  //           },
  //           "7_3": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "7_4": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "7_4_b": {
  //             yes: 0,
  //           },
  //           "7_5": {
  //             yes: 0,
  //           },
  //           "7_6": {
  //             upload: 0,
  //             files: [],
  //           },
  //           "7_7": {
  //             yes: 0,
  //             details: "",
  //             upload: 0,
  //             files: [],
  //           },
  //           "7_8": {
  //             yes: 0,
  //           },
  //           "7_8_a": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "8_1": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_3": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_4_a": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_4_b": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_4_c": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_5_a": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_5_b": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_5_c": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_6": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_7": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_8": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_9": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "8_9_a": {
  //             upload: 0,
  //             files: [],
  //           },
  //           "9_1": "",
  //           "9_1_a": "",
  //           "9_2": {
  //             yes: 0,
  //           },
  //           "10_1": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "11_1": {
  //             yes: 0,
  //           },
  //           "11_2": {
  //             yes: 0,
  //           },
  //           "11_3": "",
  //           "11_4": {
  //             yes: 0,
  //           },
  //           "11_5": {
  //             yes: 0,
  //           },
  //           "11_5_a": {
  //             yes: 0,
  //           },
  //           "11_5_b": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "12_1": {
  //             yes: 0,
  //           },
  //           "12_1_a": {
  //             year: 0,
  //             yes: 0,
  //             files: [],
  //           },
  //           "12_2": {
  //             yes: 0,
  //             files: [],
  //           },
  //           "12_2_a": {
  //             document: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "12_2_b": {
  //             yes: 0,
  //           },
  //           "12_2_c": {
  //             yes: 0,
  //           },
  //           "12_3": {
  //             yes: 0,
  //           },
  //           "12_3_a": "",
  //           "12_3_a_i": [],
  //           "12_3_b": {
  //             year: 0,
  //             yes: 0,
  //             files: [],
  //           },
  //           "12_3_c": {
  //             yes: 0,
  //           },
  //           "12_3_d": {
  //             year: 0,
  //             yes: 0,
  //             files: [],
  //           },
  //           "12_4_a": {
  //             yes: 0,
  //           },
  //           "12_4_b": {
  //             yes: 0,
  //           },
  //           "12_5_a": {
  //             yes: 0,
  //           },
  //           "12_5_a_i": {
  //             month: 0,
  //             year: 0,
  //           },
  //           "12_5_b": {
  //             yes: 0,
  //           },
  //           "12_5_c": {
  //             yes: 0,
  //           },
  //           "12_6": {
  //             properties: "",
  //             yes: 0,
  //           },
  //           "12_7": 0,
  //           "12_8": 0,
  //           "12_9": 0,
  //           "12_10": {
  //             yes: 0,
  //             upload: 0,
  //             files: [],
  //           },
  //           "13_1": {
  //             yes: 0,
  //           },
  //           "13_1_a": "",
  //           "13_1_b": "",
  //           "13_2": {
  //             yes: 0,
  //           },
  //           "13_2_a": "",
  //           "13_2_b": "",
  //           "13_2_c": "",
  //           "13_3": {
  //             yes: 0,
  //           },
  //           "13_3_a": "",
  //           "13_4": {
  //             yes: 0,
  //           },
  //           "13_4_a": "",
  //           "13_4_b": "",
  //           "13_5": {
  //             yes: 0,
  //           },
  //           "13_5_a": "",
  //           "13_6": {
  //             yes: 0,
  //           },
  //           "13_6_a": "",
  //           "13_7": {
  //             yes: 0,
  //           },
  //           "13_7_a": {
  //             yes: 0,
  //           },
  //           "14_1": {
  //             yes: 0,
  //           },
  //           "14_2": {
  //             yes: 0,
  //             details: "",
  //           },
  //           "14_3": {
  //             yes: 0,
  //           },
  //           "14_4_a": {
  //             yes: 0,
  //           },
  //           "14_4_b": {
  //             yes: 0,
  //           },
  //           "14_4_c": {
  //             yes: 0,
  //           },
  //           "14_4_d": {
  //             yes: 0,
  //           },
  //         },
  //       },
  //       preliminary_information: this.preliminaryInfo(),
  //     },
  //     sellers_questions: {
  //       answers: [],
  //       files: [],
  //       complete: false,
  //       accepted: false,
  //     },
  //     sellers_information: {
  //       complete: false,
  //       accepted: false,
  //       progress: 0,
  //       answers: {
  //         1: "",
  //         2: "",
  //         "2_a": "",
  //         3: "",
  //         "3_a": "",
  //         4: "",
  //         "4_a": "",
  //         5: "",
  //         6: "",
  //         "6_a": "",
  //         7: "",
  //         "7_a": "",
  //         8: "",
  //         "8_a": "",
  //         9: "",
  //         "9_a": "",
  //         10: "",
  //         "10_a": "",
  //         11: "",
  //         "11_a": "",
  //         12: "",
  //         "12_a": "",
  //         13: "",
  //         "13_a": "",
  //         14: "",
  //         "14_a": "",
  //         15: "",
  //         "15_a": "",
  //         16: "",
  //         17: "",
  //         18: "",
  //         19: "",
  //         "19_a": "",
  //         20: "",
  //       },
  //     },
  //   };
  // },
};
