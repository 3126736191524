<template>
  <div>
    <h1>Invite agent</h1>
    <p class="note important">
      Please note: by adding an agent their agency will be able to view all of the documentation contained in HOP about
      <span class="propName">{{ property.name ? property.name.toLowerCase() : "" }}</span> and the seller. Please ensure that you
      have your client’s authority to proceed before allowing access, and check that the agent's details inputted are correct.
    </p>
    <p class="note failure" v-if="property.agencies_names && property.agencies_names.length > 0">
      This property has already been made available to {{ property.agencies_names }}, no more agencies can be added.
    </p>
    <div v-if="!property.agencies_names">
      <p class="note">Choose an agency from the list below:</p>
      <BaseSelect
        ref="agencySelect"
        initial="Choose an agency"
        :placeHolderGrey="false"
        class="select"
        :options="agencies"
        :value="selectedAgency"
        @input="updateSelectedAgency($event)"
      />
      <div v-if="selectedAgency == -1">
        <p class="note">Please enter the name of the agent's agency below:</p>
        <BaseInput type="text" placeholder="Agency name" v-model="newAgentsAgencyName" />
        <BaseButton @click="addAgency">Add Agency</BaseButton>
      </div>
      <BaseSelect
        v-if="selectedAgency != 0 && selectedAgency != -1"
        ref="agentSelect"
        initial="Choose an agent"
        :placeHolderGrey="false"
        class="select"
        :options="agents"
        :value="selectedAgent"
        @input="updateSelectedAgent($event)"
      />
      <div v-if="selectedAgency != -1 && selectedAgent == -1">
        <p class="note">Please enter the agent's details below:</p>
        <BaseInput type="text" placeholder="Agent's name" v-model="newAgentsName" />
        <BaseInput type="email" placeholder="Agent's email" v-model="newAgentsEmail" />
        <BaseButton @click="addAgent">Add new agent</BaseButton>
      </div>
      <div v-if="showSuccess" class="note success">{{ successMessage }}</div>
      <div v-if="showFailure" class="note failure">{{ failureMessage }}</div>
      <BaseButton v-if="!showSuccess && selectedAgent != 0 && selectedAgent != -1 && selectedAgency != -1" @click="sendInvite">
        Send invite
      </BaseButton>
    </div>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to Control Panel </BaseButton>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";

export default {
  computed: {
    property() {
      return this.$store.state["property"].property;
    },
  },
  data() {
    return {
      email: "",
      newAgentsAgencyName: "",
      newAgentsName: "",
      newAgentsEmail: "",
      agencies: [],
      agents: [],
      selectedAgency: 0,
      selectedAgent: 0,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.getAgencies();
  },
  methods: {
    addAgent() {
      HopService.addAgent(this.newAgentsName, this.newAgentsEmail, this.selectedAgency)
        .then((response) => {
          this.updateSelectedAgency(this.selectedAgency, response.data);
          this.showFailure = false;
          this.selectedAgent = 0;
          this.newAgentsName = "";
          this.newAgentsEmail = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to add agent.";
          this.$store.dispatch("updateLoading", false);
        });
    },
    addAgency() {
      HopService.addAgency(this.newAgentsAgencyName)
        .then((response) => {
          this.$store.dispatch("updateLoading", false);
          this.getAgencies(response.data);
          this.selectedAgent = 0;
          this.newAgentsAgencyName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to add agency.";
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateSelectedAgent(agent) {
      this.selectedAgent = agent;
      this.$nextTick(function () {
        this.$refs.agentSelect.receiveUpdatedValue();
      });
    },
    updateSelectedAgency(agency, preselectedAgent = 0) {
      this.selectedAgency = agency;
      this.selectedAgent = 0;
      this.$nextTick(function () {
        this.$refs.agencySelect.receiveUpdatedValue();
      });

      if (agency != -1) {
        HopService.getAgentsByAgency(this.selectedAgency)
          .then((response) => {
            this.agents = [];
            response.data.forEach((element) => {
              this.agents.push({
                value: element.id,
                label: element.name || element.email,
              });
            });
            this.agents.push({
              value: -1,
              label: "The agent I want is not here",
            });
            if (preselectedAgent) {
              this.updateSelectedAgent(preselectedAgent);
            }
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.showSuccess = false;
            this.failureMessage = "Failed to fetch agents.";
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
    sendInvite() {
      this.$store.dispatch("updateLoading", true);
      HopService.inviteAgentToProperty(this.property.id, this.selectedAgent, this.$store.state["ui"].theme)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.successMessage = "Agent invited successfully";
          this.$store.dispatch("updateLoading", false);
        })
        .catch((error) => {
          // console.log(error);
          this.showFailure = true;
          this.showSuccess = false;
          if (error.response.data.message) {
            this.failureMessage = error.response.data.message;
          } else {
            this.failureMessage =
              "Inviting agent failed. Our technical team has been informed, but if you continue to have problems, please get contact us.";
          }
          this.$store.dispatch("updateLoading", false);
        });
    },
    getAgencies(preSelectedAgency = 0) {
      this.$store.dispatch("updateLoading", true);
      HopService.getAgencies()
        .then((response) => {
          this.agencies = [];
          response.data.forEach((element) => {
            this.agencies.push({
              value: element.id,
              label: element.name,
            });
          });
          this.agencies.push({
            value: -1,
            label: "The agency I want is not here",
          });
          if (preSelectedAgency) {
            this.updateSelectedAgency(preSelectedAgency);
          }
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch agencies.";
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped>
.propName {
  text-transform: capitalize;
}

.note {
  padding: 10px;
}
</style>
