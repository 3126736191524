<template>
  <div class="conveyancingEnquiries" v-if="$store.state['property'].property.id && questions.length">
    <h1>Conveyancing Enquiries</h1>
    <p>Please provide answers to the following questions and assemble any documents asked for, if you have them to hand.</p>
    <p>Your changes will be saved automatically as you go along.</p>
    <Carousel ref="carousel">
      <div class="question" v-for="(question, key) in questions" :key="key">
        <div class="questionNumber">{{ key + 1 }}/{{ questions.length }}</div>
        <div class="questionText">{{ question }}</div>
        <BaseTextArea
          rows="8"
          placeholder="Enter details"
          :maxlength="5000"
          :modelValue="model.answers[key]"
          @update:modelValue="updateModel(key, $event)"
        />
        <Uploader
          :modelValue="model.files[key]"
          @update:modelValue="$store.dispatch('updateSellersQuestionFile', { key: key, val: $event })"
        />
      </div>
    </Carousel>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to Control Panel </BaseButton>
    <CompletePrompt :complete="model.complete" completeAction="completeConveyancingEnquiries" />
  </div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import CompletePrompt from "@/components/CompletePrompt.vue";
import Uploader from "@/components/Forms/Questionnaires/Uploader.vue";
import permissionsValues from "@/helpers/permissionsValues";
import Permissions from "@/helpers/permissions";

export default {
  components: {
    Carousel,
    CompletePrompt,
    Uploader,
  },
  data() {
    return {
      questions: {},
    };
  },
  computed: {
    model() {
      if (this.$store.state["property"].property.data.sellers_questions.answers.length == 0) {
        this.$store.dispatch("initSellersQuestionsAnswers");
      }
      if (
        this.$store.state["property"].property.data.sellers_questions.files == undefined ||
        this.$store.state["property"].property.data.sellers_questions.files.length == 0
      ) {
        this.$store.dispatch("initSellersQuestionsFiles");
      }
      return this.$store.state["property"].property.data.sellers_questions;
    },
    touched() {
      return this.$store.state["ui"].touched;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  methods: {
    updateModel(key, text) {
      if (this.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS)) {
        return;
      }

      this.$store.dispatch("updateSellersQuestionAnswer", {
        key: key,
        text: text,
      });
    },
    completeEnquiries() {
      this.$store.dispatch("completeConveyancingEnquiries");
      this.hideModal("finished", null);
    },
    showModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$modal.open(name);
    },
    hideModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$modal.close(name);
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id).then(() => {
      this.questions = this.$store.state["property"].property.data.passport.report.sellers_questions;
    });
  },
  //   watch: {
  // "model.complete": {
  //   handler: function(newValue) {
  //     console.log("complete", newValue);
  //     if (newValue == true && !this.model.accepted) {
  //       this.showModal("finished");
  //     }
  //   }
  // }
  //   }
};
</script>

<style lang="scss" scoped>
.conveyancingEnquiries {
  padding: 20px 15px;

  .important {
    font-weight: bold;
  }

  h1 {
    margin: 0;
  }

  p {
    margin: 10px 0;
  }

  .question {
    background-color: $colour-light-grey;
    margin: 5px;
    padding: 20px 10px;
    text-align: left;

    .questionNumber {
      font-weight: 500;
      color: var(--colour-primary);
      text-align: center;
    }

    .questionText {
      margin: 10px auto;
      font-weight: 400;
    }

    .textarea {
      width: 75%;
    }
  }
}
</style>
