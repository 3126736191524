import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgets, (widget, key) => {
      return (_openBlock(), _createElementBlock("div", { key: key }, [
        (widget === 'view_plan')
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "button",
              href: _ctx.$store.state['property'].property.data.passport.deeds[1],
              target: "_blank"
            }, [
              _createVNode(_component_BaseButton, {
                small: false,
                buttonClass: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("View plan")
                ]),
                _: 1
              })
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        (widget === 'view_deeds')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createTextVNode(" You can view your deeds here to see whether there are any restrictive covenants affecting your property. (NB there may be more Restrictive Covenants contained in any documents referred to in your title deeds. You can ask your lawyer about this.) "),
              _createElementVNode("a", {
                class: "button",
                href: _ctx.$store.state['property'].property.data.passport.deeds[0],
                target: "_blank"
              }, [
                _createVNode(_component_BaseButton, {
                  small: false,
                  buttonClass: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("View deeds")
                  ]),
                  _: 1
                })
              ], 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}