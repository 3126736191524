<template>
  <div class="uploader form__item" :class="{ small: small }">
    <div v-if="label.length > 0 || help.length > 0" class="form__item__label">
      {{ label }}
      <div class="formkit-help">{{ help }}</div>
    </div>
    <div class="form__item__content">
      <label
        v-if="!readOnly && (multiple || files.length == 0)"
        class="drop-area"
        :for="'file-input-' + id"
        :class="{ highlight: showHighlight }"
        @dragenter.prevent.stop="highlight(true)"
        @dragover.prevent.stop="highlight(true)"
        @dragleave.prevent.stop="highlight(false)"
        @drop.prevent.stop="drop"
        @mouseover="highlight(true)"
        @mouseleave="highlight(false)"
        @keyup.enter="browse"
        tabindex="0"
      >
        <input
          ref="input"
          type="file"
          :id="'file-input-' + id"
          class="file-input"
          :accepts="validation"
          @change="change"
          :multiple="multiple"
        />
        <div v-if="loading">
          {{ statusMessage }}
        </div>
        <h2>Select a file to upload, or drop it here</h2>
        <h5>Accepted formats: {{ accepts.join(", ").toUpperCase() }}</h5>
        <p v-if="errors.length > 0" class="error" style="color: #ff0505">{{ errors.join(", ") }}</p>
      </label>
      <div class="files" v-if="showFiles && files.length">
        <div class="file" v-for="(file, key) in files" :key="key">
          <span>
            {{ file.originalFileName }}
          </span>
          <a href="#" class="delete-btn" v-if="!readOnly" @click.prevent="deleteFile(key)"> Delete </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from "vue";
import _uniqueId from "lodash/uniqueId";
import HopService from "@/services/HopService";
import { useStore } from "vuex";
import _clone from "lodash/clone";
import { UploadedFile } from "@/types/UploadedFile";

const store = useStore();
const emit = defineEmits(["update:modelValue", "delete:modelValue"]);
const input = ref<InstanceType<typeof HTMLInputElement> | null>(null);
const errors = ref([] as string[]);

const props = defineProps({
  accepts: {
    type: Array as PropType<string[]>,
    default: () => ["png", "jpg", "pdf"],
  },
  label: {
    type: String,
    default: "",
  },
  help: {
    type: String,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Array as PropType<UploadedFile[]>,
    default: () => {
      return [];
    },
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  deleter: {
    type: Function as PropType<(key: number) => void>,
    default: null,
  },
  showFiles: {
    type: Boolean,
    default: true,
  },
});

const files = ref([] as UploadedFile[]);
const showHighlight = ref(false);
const numFiles = ref(0);
const doneFiles = ref(0);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const id = ref(_uniqueId());

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const statusMessage = computed((): string => {
  if (numFiles.value > 1) {
    return "Uploading " + (doneFiles.value + 1) + "/" + numFiles.value + "...";
  }
  return "Uploading...";
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const loading = computed((): boolean => {
  return numFiles.value != doneFiles.value;
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validation = computed(() => {
  const mimeTypes = {
    pdf: "application/pdf",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    mp4: "video/mp4",
    csv: "text/csv",
  } as Record<string, string>;
  let validation = "";

  Object.keys(mimeTypes).forEach((key: string) => {
    if (props.accepts.includes(key)) {
      if (validation.length != 0 && validation.substring(validation.length, validation.length + 1) != ":") {
        validation += ",";
      }
      validation += mimeTypes[key];
    }
  });

  return validation;
});

function highlight(val: boolean) {
  showHighlight.value = val;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function drop(event: DragEvent) {
  highlight(false);
  processUpload(event.dataTransfer?.files);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function change(event: Event) {
  processUpload((event.target as HTMLInputElement).files);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function browse() {
  input.value?.click();
}

function processUpload(filesList: FileList | undefined | null) {
  if (filesList && filesList.length != 0) {
    const filesArr = [...filesList];
    numFiles.value = filesArr.length;
    doneFiles.value = 0;
    filesArr.forEach((file) => {
      upload(file)
        .then((newFile) => {
          files.value.push(newFile);
          emit("update:modelValue", files.value);
        })
        .finally(() => {
          doneFiles.value++;
        });
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deleteFile(key: number) {
  if (props.deleter != null) {
    props.deleter(key);
  } else {
    files.value.splice(key, 1);
    emit("update:modelValue", files.value);
    emit("delete:modelValue", files.value);
  }
}

function upload(file: File): Promise<UploadedFile> {
  return new Promise<UploadedFile>((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);
    const propertyId = store.state["property"].property.id;
    HopService.uploadFile(formData, propertyId)
      .then((response) => {
        resolve({
          fileName: response.data.id,
          originalFileName: file.name,
        } as UploadedFile);
      })
      .catch((error) => {
        let errorMessage = "There was a problem uploading.";
        if (error.response && error.response.data) {
          errorMessage += " " + error.response.data;
        }
        store.dispatch("showAlert", errorMessage);

        if (error.response.data == "PDF is user or owner password protected") {
          errors.value.push(
            "It looks like the document you are trying to upload is password protected.  Please try again with a version without the password protection or alternatively choose document ‘to follow’ as your answer"
          );
        }

        reject(error);
      });
  });
}

watch(
  props,
  (newValue) => {
    if (Array.isArray(newValue.modelValue)) {
      files.value = _clone(newValue.modelValue);
    } else {
      files.value = [];
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss" scoped>
.uploader {
  gap: 0;
  margin: 10px 15px;

  .form__item__label {
    margin: 0 0 5px 0;
  }

  .formkit-help {
    font-size: 12px;
  }

  .form__item__content {
    flex-grow: 1;
  }

  &.small {
    .drop-area {
      padding: 5px 15px;

      :deep(.action-icon) {
        margin: 2px 0 0 0;
        svg {
          height: 20px;
        }
      }

      h2 {
        font-size: 14px;
        font-weight: normal;
        margin-top: 0;
      }

      h5 {
        display: none;
      }

      :deep(.single-cog-loader) {
        .slot {
          flex-direction: row;
          gap: 10px;
        }
      }
    }
  }

  .drop-area {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: 2px dashedvar(--colour-primary);
    border-radius: 10px;
    padding: 15px;
    width: 75%;

    h2 {
      margin: 5px 0 0 0;
      font-size: 16px;
    }

    h5 {
      margin: 7px 0 0 0;
      font-size: 10px;
      font-weight: normal;
    }

    :deep(.action-icon) {
      svg {
        height: 35px;
      }
    }

    &.highlight,
    &:hover,
    &:active,
    &:focus {
      filter: brightness(105%);
      // border-color: lighten(var(--colour-primary), 5%);
    }

    .file-input {
      display: none;
    }

    :deep(.single-cog-loader) {
      .slot {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    :deep(.formkit-outer) {
      .formkit-file-list {
        .formkit-file-item {
          .formkit-file-remove {
            display: none;
          }
        }
      }
    }
  }

  .files {
    margin-top: 20px;

    .file {
      text-align: left;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      align-items: center;
      padding: 2px 8px;

      .delete-btn {
        background-color: var(--colour-primary);
        color: var(--colour-white);
        padding: 4px 6px;
        border-radius: 10px;
      }
    }
  }
}
</style>
