<template>
  <div class="reports">
    <h1>
      Agencies
      <span v-if="hasNoPermissionTo(permissions.ACTION_VIEW_ALL_AGENCIES)"> belonging to {{ user.organisation.name }}</span>
    </h1>

    <table>
      <tr>
        <th>Name</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_ALL_AGENCIES)">Parent brand</th>
        <th>Number of agents</th>
        <th>Number of properties</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES_CREDITS)">Credits remaining</th>
        <th v-if="hasPermissionTo(permissions.EDIT_TA6_TRIAL)">Running TA6 Part 1 trial?</th>
        <th>Default starting phase</th>
      </tr>
      <tr v-for="agency in agencies" :key="agency.id">
        <td>
          <router-link :to="{ name: 'organisation', params: { id: agency.id } }">{{ agency.name }}</router-link>
        </td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_ALL_AGENCIES)">{{ agency.parent_name }}</td>
        <td>{{ agency.num_agents }}</td>
        <td>{{ agency.num_properties }}</td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES_CREDITS)">
          {{ agency.credits == "-1" ? "n/a" : agency.credits }}
        </td>
        <td v-if="hasPermissionTo(permissions.EDIT_TA6_TRIAL) && agency.type == 2">
          <BaseCheckbox
            label="TA6 Part 1"
            @input="updateTa6Trial(agency.id, agency.ta6_trial == 1 ? 0 : 1)"
            :value="agency.ta6_trial"
          />
        </td>
        <td>{{ agency.default_phase?.label }}</td>
      </tr>
    </table>

    <div class="add">
      <BaseInput :small="true" :placeHolderGrey="true" type="Text" placeholder="Name" v-model="newName" />
      <BaseSelect
        initial="Parent brand"
        :placeHolderGrey="true"
        v-if="hasPermissionTo(permissions.ACTION_VIEW_BRANDS)"
        class="select"
        :options="brands"
        :value="selectedBrand"
        @input="updateSelectedBrand($event)"
      />
      <div class="note" v-if="selectedBrand > 0">
        Default starting phase will be {{ brands.find(brand => brand.value === selectedBrand)?.default_phase?.label }} unless overridden below.
      </div>
      <BaseSelect
        initial="Default starting phase"
        :placeHolderGrey="true"
        class="select"
        :options="phases"
        :value="selectedDefaultPhase"
        @input="updateSelectedDefaultPhase($event)"
      />
      <div v-if="showSuccess" class="note success">{{ successMessage }}</div>
      <div v-if="showFailure" class="note failure">
        {{ failureMessage }}
      </div>
      <div v-if="errorMessage" class="note failure">{{ errorMessage }}</div>
      <BaseButton @click="addAgency" :disabled="errorMessage !== ''">Add new</BaseButton>
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
    errorMessage() {
      if (this.selectedBrand === 0 && this.selectedDefaultPhase === 0) {
        return "Please select a brand or a default starting phase";
      }
      if (this.newName.length < 2) {
        return "Please enter a name";
      }
      return "";
    }
  },
  data() {
    return {
      newName: "",
      agencies: [],
      brands: [],
      phases: [],
      selectedBrand: 0,
      selectedDefaultPhase: 0,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData(backgroundLoad = false) {
      if (!backgroundLoad) {
        this.$store.dispatch("updateLoading", true);
      }
      var ctx = this;

      HopService.getAgencies()
        .then((response) => {
          this.agencies = response.data;
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch agencies";
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        });

      // Get the brands list
      if (this.hasPermissionTo(this.permissions.ACTION_VIEW_BRANDS)) {
        HopService.getBrands()
          .then((response) => {
            ctx.brands = [];
            response.data.forEach((element) => {
              ctx.brands.push({
                value: element.id,
                label: element.name,
                default_phase: element.default_phase,
              });
            });
            this.showFailure = false;
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.failureMessage = "Failed to fetch agencies/brands";
            this.showSuccess = false;
            this.$store.dispatch("updateLoading", false);
          });
      }

      HopService.getPhases()
          .then((response) => {
            ctx.phases = [];
            response.data.forEach((element) => {
              ctx.phases.push({
                value: element.id,
                label: element.label,
              });
            });
            this.showFailure = false;
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.failureMessage = "Failed to fetch phases";
            this.showSuccess = false;
            this.$store.dispatch("updateLoading", false);
          });
    },
    updateSelectedBrand(value) {
      this.selectedBrand = parseInt(value, 10);
    },
    updateSelectedDefaultPhase(value) {
      this.selectedDefaultPhase = parseInt(value, 10);
    },
    addAgency() {
      this.$store.dispatch("updateLoading", true);
      HopService.addAgency(this.newName, this.selectedBrand, this.selectedDefaultPhase)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.successMessage = "Added successfully.";
          this.updateData();
          this.newName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to add new agency";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateTa6Trial(id, value) {
      this.$store.dispatch("updateBackgroundLoading", true);
      HopService.saveAgency(id, value)
        .then(() => {
          this.$store.dispatch("showAlert", "Agency successfully updated.");
          this.showFailure = false;
          this.updateData(true);
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to update agency";
          this.showSuccess = false;
          this.$store.dispatch("updateBackgroundLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
