<template>
  <div class="container" :class="{ hidden: !fireworks.show }" v-if="fireworks.present">
    <div class="pyro">
      <div class="before"></div>
      <div class="after"></div>
    </div>

    <div class="congrats-container">
      <div class="congrats">
        <div class="title">Section complete</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {},
  computed: mapState({
    fireworks: (state) => state.ui.fireworks,
  }),
};
</script>

<style lang="scss" scoped>
.container {
  // transition: opacity 1s;
  // z-index: 9;

  &.hidden {
    opacity: 0;
  }
}

.congrats-container {
  pointer-events: none;
  position: fixed;
  right: 50%;
  transform: translateX(50%) translateY(-50%);
  transition: right 0.4s;
  top: 50%;
  z-index: 1;

  .tupack {
    animation-name: popup;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
  }
  .congrats {
    border: 1px solid var(--colour-yellow);
    color: var(--colour-yellow);
    background-color: rgba(var(--colour-primary), 0.8);
    padding: 15px 15px 15px 15px;

    .title,
    .message {
      display: block;
    }
    .title {
      font-size: 20px;
      // margin-bottom: 20px;
      color: var(--colour-white);
    }

    // .message {
    //   font-size: 40px;
    //   font-weight: bold;
    //   text-align: center;
    // }
  }
}

// For tupack
@keyframes popup {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
    random($width)- math.div($width, 2) + px random($height)- math.div($height, 1.2) + px hsl(random(360), 100%, 50%);
  $box-shadow2: $box-shadow2, 0 0 #fff;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
  overflow: hidden;
}

.pyro > .before,
.pyro > .after {
  position: fixed;
  z-index: 10;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  top: 25%;
  @include animation(
    (1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards)
  );
}

.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
</style>
