<template>
  <div class="questionnaire-form">
    <div class="header">
      <slot></slot>
      <p v-if="!formCompletion?.signed && !formCompletion?.some_signed">
        Your changes will be saved automatically as you go along.
      </p>
      <p v-if="!formCompletion?.signed && !formCompletion?.some_signed">
        HOP pre-fills as many of the answers to these questions as possible, but please check all answers are correct
        before
        completing the form. If there are other sellers they will also need to log in to sign the form. If they would
        like to
        review the answers then do not sign the form until they have had chance to check.
      </p>
      
      <p v-if="formCompletion?.signed">
        You have signed this form, but some sellers have not. In the meantime you can download a draft copy of the
        completed form.
      </p>
    </div>
    <div class="toolbar">
      <BaseButton v-if="draftAllowed" :disabled="!!canDownloadDraftMessage" @click="downloadDraft" :tooltip="canDownloadDraftMessage">Download draft</BaseButton>
      <BaseButton @click="jumpToNextQuestion" :disabled="!!showJumpToNextQuestionMessage" :tooltip="showJumpToNextQuestionMessage">Next unanswered question</BaseButton>
      <BaseButton :disabled="!!canSignFormMessage" @click="openCompleteModal" :tooltip="canSignFormMessage">Sign form</BaseButton>
    </div>
    <div class="forms">
      <div v-for="(section, key) in questionnaire?.sections" :key="key">
        <FormSection :shown="questionnaire.sections.length === 1"
          :title="sectionNumber(section, key) + ' ' + section.name" :bottomBorder="true"
          :complete="completion[section.id]" :readonly="isSectionReadOnly(section)" :ref="'section' + section.id">
          <div class="group" v-if="section.notes && section.notes.length <= 200">
            <div class="section-notes" v-html="section.notes"></div>
          </div>
          <form class="questions">
            <Carousel :ref="'carousel' + section.id">
              <div class="group" v-if="section.notes && section.notes.length > 200">
                <div class="section-notes" v-html="section.notes"></div>
              </div>
              <div class="group" v-for="(group, groupKey) in section.groups" :key="groupKey"
                :class="{ 'slick-hide': getIsGroupHidden(group, answers) }" :ref="'group' + group.id">
                <p v-if="group.name" class="group-title">
                  {{ group.name }}
                </p>
                <p v-if="group.notes">
                  <span v-html="group.notes" class="group-notes"></span>
                </p>

                <Question v-for="(question, questionKey) in group.questions" :propertyId="propertyId"
                  :class="{ 'slick-hide': getIsQuestionHidden(question, answers) }" :key="questionKey"
                  :answer="answers[question.id]" :question="question"
                  @input="answerQuestion(question.id, $event, 'carousel' + section.id)" />
              </div>
            </Carousel>
          </form>
        </FormSection>
      </div>
    </div>
    <div class="toolbar">
      <BaseButton v-if="draftAllowed" :disabled="!!canDownloadDraftMessage" @click="downloadDraft" :tooltip="canDownloadDraftMessage">Download draft</BaseButton>
      <BaseButton @click="jumpToNextQuestion" :disabled="!!showJumpToNextQuestionMessage" :tooltip="showJumpToNextQuestionMessage">Next unanswered question</BaseButton>
      <BaseButton :disabled="!!canSignFormMessage" @click="openCompleteModal" :tooltip="canSignFormMessage">Sign form</BaseButton>
    </div>
    <CompletePrompt @completeForm="completeForm" :formCompletion="formCompletion" :taForm="true" :isSeller="isSeller" ref="completePrompt"/>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import CompletePrompt from "@/components/Forms/Questionnaires/CompletePrompt.vue";
import HopService from "@/services/HopService";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import Question from "@/components/Forms/Questionnaires/Question.vue";
import QuestionnaireServices from "@/services/QuestionnaireServices";
import _debounce from "lodash/debounce";
import ApiUtils from "@/services/ApiUtils";
import { Questionnaire, QuestionnaireGroup, QuestionnaireSection } from "@/types/Base/Questionnaire";

export default defineComponent({
  components: {
    FormSection,
    Question,
    Carousel,
    CompletePrompt,
  },
  data() {
    return {
      questionnaire: {} as Questionnaire,
      answers: {},
      saveDataDebounced: Function,
    };
  },
  props: {
    questionnaireId: {
      type: Number,
      default: 1,
    },
    formPath: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    propertyId(): number {
      return this.$store.state["property"].property.id;
    },
    showJumpToNextQuestionMessage(): string {
      return this.formCompletion?.completion === 100 ? "Form is complete" : "";
    },
    formCompletion(): { accepted: boolean; completion: number; signed: boolean; some_signed: boolean } {
      return this.$store.state["property"].property.questionnaire_completion[this.questionnaireId] || null;
    },
    completion(): object {
      const completion = {
        all: true,
      };
      if (!this.questionnaire?.sections) return completion;

      for (const section of this.questionnaire.sections) {
        completion[section.id] = this.getSectionCompletion(section, this.answers);
        if (completion[section.id] != 100) {
          completion.all = false;
        }
      }

      return completion;
    },
    showHiderQuestions(): number[] {
      return QuestionnaireServices.getQuestionsWithDependencies(this.questionnaire);
    },
    theme(): string {
      return this.$store.state["ui"].theme;
    },
    isSeller(): boolean {
      return this.$store.state["property"].property.is_seller;
    },
    completePrompt(): typeof CompletePrompt {
      return this.$refs.completePrompt as typeof CompletePrompt;
    },
    canSignFormMessage(): string {
      if (!this.formCompletion || this.formCompletion?.completion !== 100) {
        return "The form must be completed before signing";
      }
      if (!this.isSeller) {
        return "Only sellers can sign the form";
      }
      if (this.formCompletion?.signed) {
        return "You have aleady signed the form";
      }
      return "";
    },
    draftAllowed(): boolean {
      if (!this.formPath) {
        return false;
      }
      return this.$store.state["property"].property.available_documents?.some(doc => doc.path === this.formPath);
    },
    canDownloadDraftMessage(): string {
      if (!this.formPath) {
        return "No form path provided";
      }
      if (this.formCompletion?.completion !== 100) {
        return "The form must be completed";
      }
      return "";
    },
  },
  created(): void {
    if (this.formCompletion?.accepted == true) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    getIsGroupHidden: QuestionnaireServices.getIsGroupHidden,
    getIsQuestionHidden: QuestionnaireServices.getIsQuestionHidden,
    getSectionCompletion: QuestionnaireServices.getSectionCompletion,
    downloadDraft() {
      if (this.formPath) {
        let fileName = "Draft";
        if (this.questionnaire.name) {
          fileName = `${this.questionnaire.name} - Draft`;
        }
        HopService.getReport(this.$store.state["property"].property.id, this.formPath).then((response) => {
          ApiUtils.openDownloadInNewTab(response, fileName);
        });
      } else {
        console.error("No form path provided");
      }
    },
    sectionNumber(section: QuestionnaireSection, key: number): string {
      if (this.questionnaire.sections.length === 1) {
        return "";
      }

      for (let group of section.groups) {
        for (let question of group.questions) {
          if (question.form_reference) {
            return question.form_reference.split("_")[0] + ".";
          }
        }
      }
      return (key + 1) + ".";
    },
    completeForm(name: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.saveDataDebounced as any).cancel();
      this.$store.commit("UPDATE_BACKGROUND_LOADING", true);

      HopService.signQuestionnaire(this.$store.state["property"].property.questionnaire_completion[this.questionnaireId].id, {
        name: name,
      })
        .then(() => {
          this.$store.dispatch("refreshProperty").then(() => {
            this.$router.push({ name: "dashboard" });
            this.$store.dispatch("showAlert", "Successfully signed");
            this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
            this.$store.commit("UPDATE_CONNECTION_PROBLEM", false);
          });
        })
        .catch(() => {
          this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
          this.$store.commit("UPDATE_CONNECTION_PROBLEM", true);
        });
    },
    answerQuestion(questionId, answer, carouselRef) {
      this.answers[questionId] = answer;
      // Don't do this if not a show/hider question
      if (this.showHiderQuestions.includes(questionId)) {
        if (undefined != this.$refs[carouselRef]) {
          const carousel = this.$refs[carouselRef];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (undefined != (carousel as any)[0]) {
            nextTick(() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (carousel as any)[0].updateHiddenSlides();
            });
          }
        }
      }
      this.saveDataDebounced();
    },
    buildSeparatedString(string, newPart, separator = ",") {
      if (string && string.length > 0) {
        string += separator + " ";
      }
      return newPart;
    },
    isSectionReadOnly(section) {
      for (let group of section.groups) {
        for (let question of group.questions) {
          if (!question.read_only) {
            return false;
          }
        }
      }
      return true;
    },
    jumpToNextQuestion() {
      const nextPath = QuestionnaireServices.getNextIncompleteQuestion(this.questionnaire, this.answers);
      if (!nextPath) {
        return;
      }
      const [sectionId, groupId, questionId] = nextPath;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formSection = (this.$refs['section' + sectionId] as any)[0];
      (formSection as any).expand();
      (formSection as any).$el.scrollIntoView({ behavior: "smooth" });

      nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const group = (this.$refs['group' + groupId] as any)[0];
        const carouselIndex = Array.prototype.indexOf.call(group.parentNode.parentNode.parentNode.childNodes, group.parentNode.parentNode);
        (this.$refs['carousel' + sectionId] as any)[0].goTo(carouselIndex);
      });
    },
    openCompleteModal() {
      this.completePrompt.showModal();
    },
  },
  mounted() {
    this.$store.commit("UPDATE_LOADING", true);
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id).then(() => {
      if (this.$store.state["property"].property.ta6_part1_active == 1 && this.formCompletion?.completion != 100) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.introModal as any).open();
      }
      this.saveDataDebounced = _debounce(() => {
        this.$store.commit("UPDATE_BACKGROUND_LOADING", true);
        HopService.saveQuestionnaire(this.propertyId, this.questionnaireId, this.answers)
          .then((response) => {
            this.$store.dispatch("updatePropertyQuestionnaireCompletion", {
              index: this.questionnaireId,
              completion: response.data.completion,
            });
            this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
            this.$store.commit("UPDATE_CONNECTION_PROBLEM", false);
          })
          .catch(() => {
            this.$store.commit("UPDATE_BACKGROUND_LOADING", false);
            this.$store.commit("UPDATE_CONNECTION_PROBLEM", true);
          });
      }, 5000);
      this.$store.commit("UPDATE_LOADING", true);
      HopService.getQuestionnaire(this.$store.state["property"].property.id, this.questionnaireId)
        .then((response) => {
          this.questionnaire = response.data.questionnaire;
          this.answers = response.data.answers;
          this.$store.commit("UPDATE_PROPERTY_QUESTIONNAIRE_COMPLETION", {
            index: this.questionnaireId,
            completion: response.data.completion,
          });
          this.$store.commit("UPDATE_LOADING", false);
          this.$store.commit("UPDATE_CONNECTION_PROBLEM", false);
        })
        .catch(() => {
          this.$store.commit("UPDATE_CONNECTION_PROBLEM", true);
          this.$store.commit("UPDATE_LOADING", false);
        });
    });
  },
  watch: {
    completion: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        for (let i = 0; i < keys.length; i++) {
          if (Number(parseFloat(oldValue[keys[i]])) !== oldValue[keys[i]]) {
            // Old value was a string, so ignore
            continue;
          }
          if (newValue[keys[i]] === oldValue[keys[i]]) {
            // unchanged
            continue;
          }
          if (
            newValue[keys[i]] == 100 || oldValue[keys[i]] == 100
          ) {
            // We are transitioning to or from 100% completion so immediately save.
            (this.saveDataDebounced as any).flush();
            if (newValue[keys[i]] == 100) {
              this.$store.dispatch("showAlert", "Section complete!");
            }
            break;
          }
        }
      },
      deep: true,
    },
  },
});
</script>

<style lang="scss">
@import "@/styles/_conveyancing_form.scss";

.questionnaire-form {
  overflow-x: clip;
  padding: $container-spacing-vertical 0 $container-spacing-vertical + 10;

  .header {
    p {
      margin-bottom: 10px;
    }
    margin-bottom: 10px;
  }

  .note {
    display: block;
    font-weight: bold;
    margin: 10px 0;
  }

  .slick-hide {
    display: none;
  }

  .group {
    background-color: $colour-light-grey;
    text-align: center;
    padding: 20px 0;

    .section-notes {
      text-align: center;
      padding: 0 30px;
      margin: 10px 0;
      color: $colour-grey;
    }

    .group-notes {
      text-align: left;
      padding: 0 30px;
      margin: 10px 0;
      color: $colour-grey;
    }

    .group-title {
      text-align: left;
      padding: 0 30px;
      margin: 10px 0;
      color: $colour-grey;
    }

    .question {
      padding: 5px 0 0;

      .question-title {
        margin: 5px 0 0;
      }

      .question-content {
        .input {
          margin-top: 3px;
        }
      }
    }
  }
  .toolbar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
    padding: 0 4px;
  }
}
</style>
