<template>
  <form class="questions">
    <Carousel ref="carousel">
      <FittingsQuestion
        v-for="(question, key, index) in model"
        :key="question.id"
        :questionKey="key"
        :index="index"
        :numQuestions="numQuestions"
        :questionName="questions.items[key]"
        :options="options"
        :model="model[key]"
        :modelKey="modelKey"
        :maxQuestions="maxQuestions"
        :otherLabel="questions.other_label"
        :sectionTitle="sectionTitle || modelKey"
        @addOption="addOption"
      />
    </Carousel>
  </form>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import FittingsQuestion from "@/components/Fittings/FittingsQuestion.vue";
import _forEach from "lodash/forEach";
import _size from "lodash/size";

import permissionsValues from "@/helpers/permissionsValues";
import Permissions from "@/helpers/permissions";

export default {
  components: {
    Carousel,
    FittingsQuestion,
  },
  props: {
    options: Array,
    questions: Object,
    model: Object,
    modelKey: String,
    sectionTitle: String,
  },
  data() {
    var currentSize = 0;
    _forEach(this.model, function (item, key) {
      if (!key.includes("other_")) {
        currentSize++;
      }
    });
    return {
      maxQuestions: this.modelKey == "other" ? this.questions.max_other : currentSize + this.questions.max_other,
    };
  },
  computed: {
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    numQuestions() {
      return _size(this.model);
    },
  },
  methods: {
    addOption() {
      if (this.hasPermissionTo(permissionsValues.ACTION_READ_ONLY_FORM_ACCESS)) {
        return;
      }

      this.$refs.carousel.updateSlot(function (context) {
        context.$store.dispatch("addFittingsContentsItem", context.modelKey);
      }, this);
    },
  },
};
</script>

<style lang="scss" scoped></style>
