<template>
  <div class="reports">
    <h1>Solicitors' firms</h1>

    <table>
      <tr>
        <th>Name</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_ALL_AGENCIES)">Parent brand</th>
        <th>Number of solicitors</th>
        <th>Number of properties</th>
        <th v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES_CREDITS)">Credits remaining</th>
        <th v-if="hasPermissionTo(permissions.EDIT_TA6_TRIAL)">Running TA6 Part 1 trial?</th>
        <th>Default starting phase</th>
      </tr>
      <tr v-for="firm in firms" :key="firm.id">
        <td>
          <router-link :to="{ name: 'organisation', params: { id: firm.id } }">{{ firm.name }}</router-link>
        </td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_ALL_AGENCIES)">{{ firm.parent_name }}</td>
        <td>{{ firm.num_agents }}</td>
        <td>{{ firm.num_properties }}</td>
        <td v-if="hasPermissionTo(permissions.ACTION_VIEW_AGENCIES_CREDITS)">
          {{ firm.credits == "-1" ? "n/a" : firm.credits }}
        </td>
        <td v-if="hasPermissionTo(permissions.EDIT_TA6_TRIAL)">
          <BaseCheckbox
            label="TA6 Part 1"
            @input="updateTa6Trial(firm.id, firm.ta6_trial == 1 ? 0 : 1)"
            :value="firm.ta6_trial"
          />
        </td>
        <td>{{ firm.default_phase?.label }}</td>
      </tr>
    </table>

    <div class="add">
      <BaseInput :small="true" :placeHolderGrey="true" type="Text" placeholder="Name" v-model="newName" />
      <BaseSelect
        initial="Parent brand (optional)"
        :placeHolderGrey="true"
        v-if="hasPermissionTo(permissions.ACTION_VIEW_BRANDS)"
        class="select"
        :options="brands"
        :value="selectedBrand"
        @input="updateSelectedBrand($event)"
      />
      <div v-if="showSuccess" class="note success">{{ successMessage }}</div>
      <div v-if="showFailure" class="note failure">
        {{ failureMessage }}
      </div>
      <BaseButton @click="addSolicitorsFirm">Add new</BaseButton>
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
  },
  data() {
    return {
      newName: "",
      firms: [],
      brands: [],
      selectedBrand: 0,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData(backgroundLoad = false) {
      if (!backgroundLoad) {
        this.$store.dispatch("updateLoading", true);
      }
      var ctx = this;

      HopService.getSolicitorsFirms()
        .then((response) => {
          this.firms = response.data;
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch solicitors' firms";
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("updateBackgroundLoading", false);
        });

      // Get the brands list
      if (this.hasPermissionTo(this.permissions.ACTION_VIEW_BRANDS)) {
        HopService.getBrands()
          .then((response) => {
            ctx.brands = [];
            response.data.forEach((element) => {
              ctx.brands.push({
                value: element.id,
                label: element.name,
              });
            });
            this.showFailure = false;
            this.$store.dispatch("updateLoading", false);
          })
          .catch(() => {
            this.showFailure = true;
            this.failureMessage = "Failed to fetch brands";
            this.showSuccess = false;
            this.$store.dispatch("updateLoading", false);
          });
      }
    },
    updateSelectedBrand(value) {
      this.selectedBrand = value;
    },
    addSolicitorsFirm() {
      this.$store.dispatch("updateLoading", true);
      HopService.addSolicitorsFirm(this.newName, this.selectedBrand)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.successMessage = "Added successfully.";
          this.updateData();
          this.newName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to add new solicitor's firm";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateTa6Trial(id, value) {
      this.$store.dispatch("updateBackgroundLoading", true);
      HopService.saveSolicitorsFirm(id, value)
        .then(() => {
          this.$store.dispatch("showAlert", "Agency successfully updated.");
          this.showFailure = false;
          this.updateData(true);
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to update firm";
          this.showSuccess = false;
          this.$store.dispatch("updateBackgroundLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
