<template>
  <div class="reports signatures">
    <h1>Signatures collected for {{ data.property?.name }}</h1>
    <div v-for="(questionnaire, key) in data.attempts" :key="key">
      <h3>{{ questionnaire.phase?.label }} - {{ questionnaire.questionnaire_name }}</h3>
      <table v-if="questionnaire.signatures.length">
        <tr>
          <th>Signed name</th>
          <th>Login email</th>
          <th>Signed at</th>
          <th v-if="user.role == permissions.USER_TYPE_ADMIN">Actions</th>
        </tr>
        <tr v-for="(signature, signatureKey) in questionnaire.signatures" :key="signatureKey">
          <td>{{ signature.name }}</td>
          <td>{{ signature.user.email }}</td>
          <td>{{ signature.signed_at }}</td>
          <td v-if="user.role == permissions.USER_TYPE_ADMIN">
            <p v-if="signature.user.role !== 1">⚠️ This signature was collected by {{ signature.user.email }}</p>
            <BaseButton class="delete-button" style="background-color: red" @click="deleteSignature(signature.id)"
              >Delete</BaseButton
            >
          </td>
        </tr>
      </table>
      <p v-else>No signatures found.</p>
    </div>
    <div>
      <h3>Movers assigned to this property</h3>
      <ul>
        <li v-for="(email, key) in data.property?.user_emails" :key="key">{{ email }}</li>
      </ul>
      <p v-if="data.property?.user_emails && data.property?.user_emails.length == 0">No movers have been assigned.</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import HopService from "@/services/HopService";

import permissionsValues from "@/helpers/permissionsValues";

const store = useStore();

const data = ref({});

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
});

onMounted(() => {
  store.dispatch("updateLoading", true);
  HopService.getPropertySignatures(props.id)
    .then((response) => {
      data.value = response.data;
      store.dispatch("updateLoading", false);
    })
    .catch((error) => {
      store.dispatch("showAlert", "There was a problem fetching data: " + error.response.data);
      store.dispatch("updateLoading", false);
    });
});

const permissions = permissionsValues;

const user = store.state.user.user;

const deleteSignature = (id) => {
  if (confirm("Are you sure you want to delete this signature?") && user.role == permissions.USER_TYPE_ADMIN) {
    HopService.deleteSignature(id)
      .then(() => {
        store.dispatch("showAlert", "Signature deleted successfully.");
        data.value.attempts = data.value.attempts.map((attempt) => {
          attempt.signatures = attempt.signatures.filter((signature) => signature.id != id);
          return attempt;
        });
      })
      .catch((error) => {
        store.dispatch("showAlert", "There was a problem deleting the signature: " + error.response.data);
      });
  }
};
</script>

<style lang="scss" scoped>
.signatures {
  ul {
    // text-align: center;
    max-width: 180px;
    margin: 0 auto;
    li {
      // text-align: center;
    }
  }
}
</style>
