<template>
  <div class="reports">
    <h1>Movers</h1>
    <p>
      <span v-if="hasNoPermissionTo(permissions.ACTION_VIEW_ALL_MOVERS)"
        >Movers assigned to properties belonging to agencies belonging to {{ user.organisation.name }}.</span
      >
    </p>
    <table>
      <tr>
        <th>Email</th>
        <th>Date registered</th>
        <th>Properties</th>
        <th>Accepted T&Cs?</th>
        <th>Last activity</th>
      </tr>
      <tr v-for="mover in movers" :key="mover.id">
        <td>{{ mover.email }}</td>
        <td>{{ mover.date_registered }}</td>
        <td>{{ mover.num_properties }}</td>
        <td>{{ mover.accepted_tcs }}</td>
        <td>
          {{ mover.last_activity && new Date(mover.last_activity).getFullYear() != -1 ? new Date(mover.last_activity) : "never" }}
        </td>
      </tr>
    </table>

    <div class="note">To add a mover first add their property, then invite them.</div>
    <div v-if="showSuccess" class="note success">Added successfully.</div>
    <div v-if="showFailure" class="note failure">
      {{ failureMessage }}
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
  },
  data() {
    return {
      movers: [],
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$store.dispatch("updateLoading", true);

      HopService.getMovers()
        .then((response) => {
          this.movers = response.data;
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch movers";
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
