<template>
  <div>
    <h1>Invite seller</h1>
    <p class="note">
      To allow the seller to complete details for
      <span class="propName">{{ property.name ? property.name.toLowerCase() : "" }}</span
      >, enter their email address below, and we will send them an invite.
    </p>
    <p v-if="property.num_sellers < 4">
      This property has {{ property.num_sellers }} associated seller<span v-if="property.num_sellers > 1">s</span>, you can add a
      maximum of 4.
    </p>
    <p class="note failure" v-if="property.num_sellers >= 4">
      This property has already been made available to {{ property.num_sellers }} sellers, no more sellers can be added.
    </p>
    <div v-if="property.num_sellers < 4">
      <BaseInput v-if="!showSuccess" type="email" class="input" placeholder="Email address" v-model="email" />
    </div>

    <div v-if="showSuccess" class="note success">User invited successfully</div>
    <div v-if="showFailure" class="note failure">{{ failureMessage }}</div>
    <BaseButton v-if="showSuccess && property.num_sellers < 4" @click="inviteAnother"> Invite another seller </BaseButton>
    <BaseButton v-if="!showSuccess && property.num_sellers < 4" @click="sendInvite"> Send invite </BaseButton>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to Control Panel </BaseButton>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";

export default {
  computed: {
    property() {
      return this.$store.state["property"].property;
    },
  },
  data() {
    return {
      email: "",
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
    };
  },
  methods: {
    sendInvite() {
      this.$store.dispatch("updateLoading", true);
      HopService.inviteSellerToProperty(this.property.id, this.email, this.$store.state["ui"].theme)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
          this.$store.commit("INCREASE_NUM_USERS");
        })
        .catch((error) => {
          // console.log(error);
          this.showFailure = true;
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
          if (error.response.data.message) {
            this.failureMessage = error.response.data.message;
          } else {
            this.failureMessage = "Inviting seller failed. Did you enter a valid email address?";
          }
        });
    },
    inviteAnother() {
      this.showSuccess = false;
      this.email = "";
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped>
.propName {
  text-transform: capitalize;
  font-weight: bold;
}
</style>
