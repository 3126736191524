<template>
  <div>
    <BaseTextArea placeholder="" :modelValue="answer" @update:modelValue="$emit('input', $event)" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    answer: String,
  },
});
</script>

<style scoped></style>
