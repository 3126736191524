<template>
  <div @scroll="onScroll" class="properties">
    <!-- <BaseModal name="delete">
      <h1>Are you sure?</h1>
      <p>Any progress you have made with this property will be lost permanently.</p>
      <BaseButton @click="removeProperty()">
        Yes
      </BaseButton>
      <BaseButton @click="closeModal('delete')">
        No
      </BaseButton>
    </BaseModal> -->
    <h1>My properties</h1>
    <div class="note">
      Hello {{ user.displayName ? user.displayName : user.email
      }}<span v-if="user.organisation.name"> ({{ user.organisation.name }})</span>.
    </div>
    <div class="note" v-if="user.role != permissions.USER_TYPE_SIMPLE">
      Your role is:
      <span v-if="user.role == permissions.USER_TYPE_ADMIN">administrator</span>
      <span v-if="user.role == permissions.USER_TYPE_BRAND">brand admin</span>
      <span v-if="user.role == permissions.USER_TYPE_AGENT">agent</span>
      <span v-if="user.role == permissions.USER_TYPE_MOVER">home mover</span>
      <span v-if="user.role == permissions.USER_TYPE_SOLICITOR">solicitor</span>
    </div>

    <router-link
      v-if="hasPermissionTo(permissions.ACTION_CREATE_PROP) && user.organisation.credits != 0"
      :to="{ name: 'addproperty' }"
    >
      <BaseButton> Add property </BaseButton>
    </router-link>
    <div class="note important" v-if="hasPermissionTo(permissions.ACTION_CREATE_PROP) && user.organisation.credits == 0">
      Your organisation currently has 0 HOP credits. Please get in touch to order more.
      <BaseButton @click.prevent="requestCredits()">Request credits</BaseButton>
    </div>

    <div class="note" v-if="user.role == permissions.USER_TYPE_MOVER && user.properties.length == 0">
      <h1>No properties found</h1>
      <h2>Not to worry. This can be for a couple of reasons:</h2>
      <ol>
        <li>
          If you have logged in<span v-if="!whiteLabel"> to HOP</span> from an email invite from your estate agent or solicitor,
          have you used a different email address? Try logging out, then log in again with the address mentioned in your email,
          and you should then be able to see your property.
        </li>
        <li>
          If you have logged in<span v-if="!whiteLabel"> to HOP</span> from our website, then there won’t be any properties
          assigned to you yet. Get in touch with us and we can help you get started.
        </li>
      </ol>
      <a href="https://www.homeownerspassport.com/?showForm=true">
        <BaseButton buttonClass="">Get in touch</BaseButton>
      </a>
    </div>
    <ul>
      <li v-for="property in user.properties" :key="property.id" class="property">
        <router-link :to="{ name: 'dashboard', params: { id: property.id } }">
          <div class="topPane">
            <div class="title">
                {{ property.name.trim().replace(/^,+|,+$/g, '').toLowerCase() }}
            </div>
            <div class="subtitle">
              {{ property.city.toLowerCase() }}
            </div>
            <div class="completion" v-if="hasPermissionTo(permissions.ACTION_VIEW_PROP_COMPLETION)">
              {{ property?.phase?.label ? `${property.phase.label}:` : "" }}
              {{ property.complete }}%
            </div>
          </div>
          <div class="bottomPane" v-if="hasPermissionTo(permissions.ACTION_VIEW_PROP_META)">

            <div class="phase"><b>Phase:</b> {{ property.phase.label }}</div>
            <div class="movers"><b>Home mover:</b> {{ property.user_emails.join(", ") }}</div>
            <div class="agents"><b>Agency:</b> {{ property.agencies_names }}</div>
            <div class="date"><b>Date added:</b> {{ property.created_at }}</div>
            <div class="lastSignin">
              <b>Last sign in by home mover:</b>
              {{ property.last_sign_in > 0 ? new Date(property.last_sign_in * 1000).toLocaleDateString("en-GB") : "never" }}
            </div>
          </div>
      </router-link>
    </li>
    </ul>
    <!-- <BaseButton v-if="user.role != permissions.USER_TYPE_MOVER && !loadedAllProperties" @click="loadRemaining">Load remaining properties if available</BaseButton> -->
    <BaseModal ref="tcsModal" :noClose="true">
      <h1>Terms and Conditions</h1>
      <p>You must accept our Terms and Conditions in order to continue.</p>
      <p><a href="https://www.homeownerspassport.com/privacy/" target="_blank">Click here to view them.</a></p>
      <BaseButton @click="acceptTcs()"> Accept </BaseButton>
      <BaseButton @click="logOut"> Decline </BaseButton>
    </BaseModal>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/helpers/permissions";
import permissionsValues from "@/helpers/permissionsValues";

export default {
  computed: {
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    user() {
      return this.$store.state["user"].user;
    },
    ...mapState({
      whiteLabel: (state) => state.ui.whiteLabel,
    }),
    ...mapGetters({
      loadedAllProperties: "getLoadedAllProperties",
    }),
  },
  data() {
    return {
      selectedProp: 0,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.user.accepted_tcs == 0) {
        this.showModal("tcsModal");
      }
    }, 500);
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const scrollPos = document.documentElement.scrollTop + window.innerHeight;
      if (document.documentElement.offsetHeight - scrollPos < 20) {
        if (!this.$store.state.ui.loading) {
          this.$store.dispatch("loadMoreProperties");
        }
      }
    },
    showModal(name, event, propId) {
      if (event) {
        event.preventDefault();
      }
      this.selectedProp = propId;
      this.$refs[name].open();
    },
    closeModal(name, event) {
      // console.log("closemodal");
      if (event) {
        event.preventDefault();
      }
      this.$modal.close(name);
    },
    removeProperty() {
      // console.log("revmove", this.selectedProp);
    },
    logOut() {
      this.$auth0.logout({
        logoutParams: {
          returnTo: window.location.origin + "/auth",
        },
      });
    },
    acceptTcs() {
      return HopService.acceptTcs()
        .then(() => {
          this.$refs.tcsModal.close();
          this.$router.go(0);
        })
        .catch(() => {
          this.$store.dispatch("connectionProblem", true);
        });
    },
    requestCredits() {
      this.$store.dispatch("updateLoading", true);
      HopService.sendEnquiry(0, this.$store.state["user"].user.displayName, "Request for more HOP credits.")
        .then(() => {
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("showAlert", "Request received. We will be in touch.");
        })
        .catch(() => {
          // console.log(error);
          this.$store.dispatch("updateLoading", false);
          this.$store.dispatch("showAlert", "Request failed, please get in touch with us.");
        });
    },
  },
  watch: {
    "user.accepted_tcs"(newValue) {
      if (newValue.accepted_tcs == "0") {
        this.$refs.tcsModal.open();
      } else {
        this.$refs.tcsModal.close();
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.properties {
  padding: 20px 0;
  margin: 0;
  background-color: $colour-light-grey;
  h1 {
    margin: 0;
  }
  ul {
    padding: 0 10px;
  }

  ol {
    padding: 0 20px;

    li {
      text-align: justify;
      margin-bottom: 20px;
    }
  }

  .note {
    padding: 10px;
    text-align: center;
    color: $colour-grey;

    h2 {
      margin: 10px 0 0;
    }
  }
  .property {
    max-width: 450px;
    margin: 10px auto;
    list-style: none;
    .topPane {
      padding: 5px 5px 5px 5px;
      background-color: var(--colour-primary);
      padding-bottom: 15px;
      text-transform: capitalize;
      position: relative;

      .image {
        img {
          width: 100%;
        }
      }

      .buttons-container {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .title {
        color: var(--colour-white);
        font-weight: 400;
        text-align: left;
        font-size: 26px;
      }

      .title,
      .subtitle {
        margin: 10px 5px 0;
      }

      .subtitle {
        color: $colour-light-grey;
      }

      .completion {
        color: $colour-light-grey;
        position: absolute;
        right: 15px;
        bottom: 15px;
      }
    }

    .bottomPane {
      padding: 15px 10px 5px;
      background-color: $colour-tertiary;
      padding-bottom: 15px;
      overflow: hidden;
    }
  }
}
</style>
