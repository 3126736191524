<template>
  <div class="select-container">
    <select v-bind="$attrs" :value="selectValue" :class="{ error: error && !placeHolderGrey }" @input.stop="updateValue">
      <option :value="unselectedValue" :selected="unselectedValue === selectValue" :disabled="required">{{ initial }}</option>
      <option v-for="option in options" :key="option.value" :selected="option.value === selectValue" :value="option.value">
        {{ option.label }}
      </option>
      <optgroup label=""></optgroup>
    </select>
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2.127 -5.135 17 24.583" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.351-4.3c.209-.001.315.102.524.204l6.93 6.807c.314.309.318.726.008.937-.309.314-.727.318-.938.008L6.366-2.74-.028 3.769c-.31.315-.727.319-.938.009-.314-.31-.318-.726-.008-.938l6.807-6.927a.794.794 0 01.518-.213zM6.427 18.615c-.209 0-.313-.104-.521-.208l-6.875-6.861c-.312-.311-.313-.729 0-.938.311-.312.729-.312.938 0l6.458 6.446 6.445-6.457c.312-.313.729-.314.938-.002.312.313.312.729.001.938l-6.862 6.873a.8.8 0 01-.522.209z"/></svg>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: [String, Number],
    initial: {
      type: String,
      default: "Not Selected",
    },
    placeHolderGrey: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    unselectedValue: {
      type: [String, Number],
      default: 0,
    },
  },
  computed: {
    selectValue() {
      if (this.displayedValue == undefined) {
        return this.unselectedValue;
      }
      return this.displayedValue;
    },
  },
  mounted() {
    this.checkError(this.selectValue);
  },
  data() {
    return {
      error: false,
      displayedValue: this.value,
    };
  },
  watch: {
    // So that changes to this.value bubble down to the select
    options: {
      handler: function (newValue, oldValue) {
        if (oldValue.length != 0 && oldValue.length != newValue.length) {
          this.displayedValue = 0;
          this.checkError(this.selectValue);
        }
      },
      deep: true,
    },
  },
  methods: {
    receiveUpdatedValue() {
      this.displayedValue = this.value;
      this.checkError(this.value);
    },
    updateValue(event) {
      this.displayedValue = event.target.value;
      this.$emit("input", event.target.value);
      this.checkError(event.target.value);
    },
    checkError(val) {
      if (val == 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  height: 32px;
  margin: 10px auto;
}

.icon {
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--colour-white);
  width: 28px;
  height: 32px;
  display: inline-block;
  pointer-events: none;

  &:after {
    content: "";
    width: 1px;
    height: 21px;
    background: $colour-grey;
    position: absolute;
    bottom: 6px;
    right: 0px;
  }

  svg {
    width: 14px;
    top: 5px;
    position: relative;
    path {
      fill: var(--colour-primary);
      stroke: var(--colour-primary);
    }
  }
}

select {
  top: 0;
  left: 0;
  position: absolute;
  display: inline-block;
  color: $colour-grey;
  background-color: var(--colour-white);
  border: 0;
  padding: 5px 10px 5px 2px;
  margin: 0;
  height: 32px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-align-last: center;
  border-radius: 0;
  text-indent: 32px;

  &.error {
    color: $colour-red;
  }
}
</style>
