<template>
  <div class="input">
    <label :for="id" v-if="label">{{ label }}</label>
    <input
      v-bind="$attrs"
      :id="id"
      type="checkbox"
      :value="value"
      :checked="value == true || value == 1 || value == '1'"
      @input="updateValue"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: [Boolean, Number, String],
  },
  data() {
    return {
      id: this._uid,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  margin: 0;
  color: $colour-grey;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  display: block;
  text-align: center;

  label {
    padding-right: 12px;
    display: inline-block;
    text-align: center;
  }

  input {
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    margin: 0 auto;
    top: -1px;
  }
}
</style>
