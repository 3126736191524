<template>
  <div>
    <div v-for="(widget, key) in widgets" :key="key">
      <a
        class="button"
        v-if="widget === 'view_plan'"
        :href="$store.state['property'].property.data.passport.deeds[1]"
        target="_blank"
      >
        <BaseButton :small="false" buttonClass="">View plan</BaseButton>
      </a>
      <div v-if="widget === 'view_deeds'">
        You can view your deeds here to see whether there are any restrictive covenants affecting your property. (NB there may be
        more Restrictive Covenants contained in any documents referred to in your title deeds. You can ask your lawyer about
        this.)
        <a class="button" :href="$store.state['property'].property.data.passport.deeds[0]" target="_blank">
          <BaseButton :small="false" buttonClass="">View deeds</BaseButton>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    widgets: Array,
  },
  computed: {},
});
</script>

<style scoped></style>
