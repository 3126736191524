const config = {
  default: {
    hostname: "app.homeownerspassport.com",
    auth0Domain: process.env.VUE_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    theme: "hop",
    favicon: "/favicon.ico",
    appName: "HOP",
  },
  knightfrank: {
    hostname: "knightfrank.homeownerspassport.com",
    theme: "knightfrank",
    auth0ClientId: "ECTSgwQTnxp5LWrVa3siPtMG4Jlzemil",
    favicon: "/themes/knightfrank/favicon.png",
    appName: "Knight Frank Move Ready",
  },
  "knightfrank-staging": {
    hostname: "knightfrank-staging.homeownerspassport.com",
    auth0Domain: "auth.homeownerspassport.com",
    auth0ClientId: "2xzFoTVCEA7SxktXxjEMQKeMNqODBiPW",
    theme: "knightfrank",
    favicon: "/themes/knightfrank/favicon.png",
    appName: "Knight Frank Move Ready",
  },
  kfwestmidlands: {
    hostname: "kfwestmidlands.homeownerspassport.com",
    theme: "knightfrank",
    auth0ClientId: "zaiRb4hwJaKptrK5xQgeUfQRtwVMuF3D",
    favicon: "/themes/knightfrank/favicon.png",
    appName: "Knight Frank Move Ready",
  },
  "kfwestmidlands-staging": {
    hostname: "kfwestmidlands-staging.homeownerspassport.com",
    auth0Domain: "auth.homeownerspassport.com",
    auth0ClientId: "3DZMLc8hpFpbmyqrqPPiVnPMkLj7qLi0",
    theme: "knightfrank",
    favicon: "/themes/knightfrank/favicon.png",
    appName: "Knight Frank Move Ready",
  },
  canopy: {
    hostname: "canopy.homeownerspassport.com",
    theme: "canopy",
    auth0ClientId: "ty136F7PhNwCMUypymFOakvlPzm6Mm8A",
    favicon: "/themes/canopy/favicon.ico",
    appName: "Canopy Move Ready",
  },
  "canopy-staging": {
    hostname: "canopy-staging.homeownerspassport.com",
    auth0ClientId: "h6fCPeKRsDlVCRNG4ERUi4a6OkxLZHnc",
    theme: "canopy",
    favicon: "/themes/canopy/favicon.ico",
    appName: "Canopy Move Ready",
  },
};

export default class Config {
  public static getClient(): string {
    const domainOverride = process.env.VUE_APP_CONFIG_HOSTNAME_OVERRIDE || window.location.hostname;
    for (const key in config) {
      if (config[key].hostname === domainOverride) {
        return key;
      }
    }
    return "default";
  }


  public static getAuth0ClientId(): string {
    return config[Config.getClient()].auth0ClientId || config.default.auth0ClientId;
  }

  public static getAuth0Domain(): string {
    return config[Config.getClient()].auth0Domain || config.default.auth0Domain;
  }

  public static getTheme(): string {
    return config[Config.getClient()].theme || config.default.theme;
  }

  public static getFavicon(): string {
    return config[Config.getClient()].favicon || config.default.favicon;
  }

  public static getAppName(): string {
    return config[Config.getClient()].appName || config.default.appName;
  }

  public static getHostname(): string {
    return config[Config.getClient()].hostname || config.default.hostname;
  }

  public static getClientConfigByKey(client: string, key: string): string {
    return config[client][key];
  }
}
