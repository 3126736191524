<template>
  <div class="input" :class="{ active: $attrs.checked, showRadio: showRadio }">
    <label :for="id" @click="updateValue" v-if="label">{{ label }}</label>
    <input v-bind="$attrs" :id="id" type="radio" :value="value" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: [Number, String],
    showRadio: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: this._uid,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  margin: 0;
  position: relative;
  background-color: var(--colour-white);
  color: $colour-grey;
  font-weight: 500;
  font-size: 15px;
  position: relative;

  &.active {
    background-color: var(--colour-primary);
    color: var(--colour-white);
  }

  label {
    padding: 10px 6px;
    display: block;
    text-align: center;
  }

  input {
    display: none;
  }

  &.showRadio {
    padding-left: 30px;

    input {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      width: 23px;
      height: 23px;
    }
  }
}
</style>
