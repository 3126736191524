import { isValidEmail, round } from "@/helpers/utils";
import { Questionnaire, QuestionnaireGroup, QuestionnaireQuestion } from "@/types/Base/Questionnaire";
import { getDependencies, shouldShowIf } from "./QuestionnaireUtils";

export default {
  getSectionCompletion(section, answers): number | null {
    const stringTyped = function (model) {
      maxPoints++;

      if (model && model.length > 0) {
        points++;
        return true;
      }
      return false;
    };

    const emailTyped = function (model) {
      maxPoints++;
      if (model && model.length > 0 && isValidEmail(model)) {
        points++;
        return true;
      }
      return false;
    };
    const selectTyped = function (model) {
      maxPoints++;

      if (model && model.length > 0 && model !== "0") {
        points++;
        return true;
      }
      return false;
    };

    let maxPoints = 0;
    let points = 0;
    let questions = [] as QuestionnaireQuestion[];
    for (const group of section.groups) {
      questions = questions.concat(group.questions);
    }
    for (const question of questions) {
      if (question.required == false) {
        continue;
      }
      if (this.getIsQuestionHidden(question, answers)) {
        continue;
      }
      switch (question.type) {
        case "select":
          selectTyped(answers[question.id]);
          break;
        case "text":
        case "textarea":
        case "checkbox":
        case "currency":
        case "upload":
          stringTyped(answers[question.id]);
          break;
        case "email":
          emailTyped(answers[question.id]);
          break;
      }
    }
    if (maxPoints == 0) {
      return null;
    }
    return round((points / maxPoints) * 100, 0);
  },
  getNextIncompleteQuestion(questionnaire: Questionnaire, answers): [number, number, number] | null {
    for (const section of questionnaire.sections) {
      for (const group of section.groups) {
        for (const question of group.questions) {
          if (question.required == false) {
            continue;
          }
          if (this.getIsQuestionHidden(question, answers)) {
            continue;
          }
          const answer = answers[question.id];
          if (!answer || answer.length == 0 || (question.type === "select" && answer === "0")) {
            return [section.id, group.id, question.id];
          }
        }
      }
    }
    return null;
  },
  getIsQuestionHidden(question, answers): boolean {
    if (null != question.show_if) {
      const showIf = shouldShowIf(question.show_if, answers);
      if (null != showIf) {
        return !showIf;
      }
    }

    if (null == question.show_if_question) {
      return false;
    }

    if (this.getIsQuestionHidden(question.show_if_question, answers)) {
      // If the question that this question depends on is hidden, then this question is hidden too
      return true;
    }

    if (null != question.show_if_answer) {
      if (null == answers || null == answers[question.show_if_question.id]) {
        return true;
      }
      return answers[question.show_if_question.id] != question.show_if_answer;
    }
    if (null != question.show_if_answer_includes) {
      if (null == answers[question.show_if_question.id]) {
        return true;
      } else {
        for (const a of answers[question.show_if_question.id]) {
          if (a == question.show_if_answer_includes) {
            return false;
          }
        }
        return true;
      }
    }

    return false;
  },
  getIsGroupHidden(group: QuestionnaireGroup, answers): boolean {
    let hasVisibleQuestions = false;
    for (const question of group.questions) {
      if (!this.getIsQuestionHidden(question, answers)) {
        hasVisibleQuestions = true;
        break;
      }
    }
    return !hasVisibleQuestions;
  },
  getQuestionsWithDependencies(questionnaire: Questionnaire): number[] {
    const questions = [] as number[];
    for (const section of questionnaire.sections) {
      for (const group of section.groups) {
        for (const question of group.questions) {
          if (null != question.show_if_question) {
            questions.push(question.show_if_question.id);
          } else if (null != question.show_if) {
            questions.push(...getDependencies(question.show_if));
          }
        }
      }
    }
    return questions;
  },
};
