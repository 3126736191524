import store from "@/store";
import { round } from "@/helpers/utils";
import _uniqueId from "lodash/uniqueId";
import _debounce from "lodash/debounce";
import HopService from "@/services/HopService";
import router from "@/router";

import { PreliminaryInformation } from "@/types/Property/PreliminaryInformation";

export const state = {} as PreliminaryInformation;

export const mutations = {
  UPDATE_PRELIMINARY_INFORMATION_PROGRESS(state, payload) {
    const stringTyped = function (model) {
      maxPoints++;
      if (model && model.length > 0) {
        points++;
        return true;
      }
      return false;
    };

    const yesNoSingle = function (model) {
      maxPoints++;
      if (model != 0) {
        points++;
      }
    };

    const yesNoItems = function (model, itemFields = ["year", "category", "description"]) {
      maxPoints++;
      if (model.yes != 0) {
        points++;
        if (model.yes != 2) {
          model.items.forEach((item) => {
            itemFields.forEach((field) => {
              maxPoints++;
              if (field == "description") {
                if (item[field] != undefined && item[field].length > 0) {
                  points++;
                }
              } else if (item[field] != undefined && item[field] != 0) {
                points++;
              }
            });
          });
        }
      }
    };

    const yesNoUpload = function (model, yesNo = true, uploadYesNoCriteria = 1) {
      maxPoints++;
      if (yesNo) {
        if (model.yes != 0) {
          points++;
          if (model.yes == uploadYesNoCriteria) {
            maxPoints++;
            if (model.upload != 0) {
              points++;
              upload(model);
            }
          }
        }
      } else {
        // Just check upload field
        if (model.upload != 0) {
          points++;
          upload(model);
        }
      }
    };

    const upload = function (model) {
      if (model.upload == 3) {
        files(model);
      }
    };

    const files = function (model) {
      maxPoints++;
      if (model.files && model.files.length > 0) {
        points++;
      }
    };

    const model = state.property.data.conveyancing_forms.preliminary_information.questions;

    let points = 0;
    let maxPoints = 0;
    if (payload.questionKey.indexOf("1_") == 0) {
      stringTyped(model["1_1"]);
      stringTyped(model["1_1_i"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[1] = round((points / maxPoints) * 100, 0);
    }
    if (payload.questionKey.indexOf("2_") == 0) {
      stringTyped(model["2_1_i"]);
      stringTyped(model["2_1_ii"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[2] = round((points / maxPoints) * 100, 0);
    }
    if (payload.questionKey.indexOf("3_") == 0) {
      yesNoSingle(model["3_1"]);
      if (model["3_1"] == 2) {
        yesNoSingle(model["3_2_i"]["yes"]);
        stringTyped(model["3_2_1"]);
        stringTyped(model["3_2_2_1"]);
        yesNoSingle(model["3_2_2_2"]);
        if (model["3_2_2_2"] == 1) {
          stringTyped(model["3_2_2_2_i"]);
          stringTyped(model["3_2_2_2_ii"]);
          stringTyped(model["3_2_2_2_iii"]);
        }
        yesNoSingle(model["3_2_2_3"]);
        if (model["3_2_2_3"] == 1) {
          stringTyped(model["3_2_2_3_i"]);
        } else if (model["3_2_2_3"] == 2) {
          stringTyped(model["3_2_2_3_ii"]);
        } else if (model["3_2_2_3"] == 3) {
          stringTyped(model["3_2_2_3_iii"]);
        }
        yesNoSingle(model["3_2_3_1"]);
        if (model["3_2_3_1"] == 1) {
          stringTyped(model["3_2_3_2"]);
          yesNoSingle(model["3_2_3_3"]["yes"]);
          if (model["3_2_3_3"]["yes"] == 1) {
            maxPoints++;
            if (model["3_2_3_3"]["files"].length > 0) {
              points++;
            }
          }

          stringTyped(model["3_2_3_4_i"]);
          stringTyped(model["3_2_3_4_ii"]);
          stringTyped(model["3_2_3_5"]);
        }
        yesNoSingle(model["3_2_4"]);
        if (model["3_2_4"]["yes"] == 1) {
          stringTyped(model["3_2_4_i"]);
          stringTyped(model["3_2_4_ii"]);
          stringTyped(model["3_2_4_iii"]);
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[3] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("4_") == 0) {
      yesNoSingle(model["4_1"]["yes"]);
      state.property.data.conveyancing_forms.preliminary_information.progress[4] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("5_") == 0) {
      yesNoSingle(model["5_1"]["yes"]);
      if (model["5_1"]["yes"] == 1) {
        yesNoSingle(model["5_1"]["option"]);
        stringTyped(model["5_1"]["details"]);
        if (model["5_1"]["option"] == 5) {
          stringTyped(model["5_1"]["other"]);
        } else if (model["5_1"]["option"] == 4 || model["5_1"]["option"] == 3) {
          stringTyped(model["5_1"]["month"]);
          stringTyped(model["5_1"]["year"]);
        }
      }

      yesNoSingle(model["5_2"]["yes"]);
      if (model["5_2"]["yes"] == 1) {
        maxPoints++;
        if (model["5_2"]["items"].length > 0) {
          points++;
        }
        if (model["5_2"]["items"].includes(8)) {
          stringTyped(model["5_2"]["details"]);
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[5] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("6_") == 0) {
      yesNoSingle(model["6_1"]["yes"]);
      if (model["6_1"]["yes"] == 1) {
        maxPoints++;
        if (model["6_1"]["items"].length > 0) {
          points++;
        }

        model["6_1"]["items"].forEach((element) => {
          stringTyped(model["6_2"][element]["details"]);
          stringTyped(model["6_2"][element]["month"]);
          stringTyped(model["6_2"][element]["year"]);
        });

        yesNoSingle(model["6_2_1"]["yes"]);
        if (model["6_2_1"]["yes"] == 2) {
          stringTyped(model["6_2_1"]["details"]);
        }
        yesNoSingle(model["6_2_2"]["yes"]);
        if (model["6_2_2"]["yes"] == 1) {
          maxPoints++;
          if (model["6_2_2"]["files"].length > 0) {
            points++;
          }
        } else {
          stringTyped(model["6_2_2"]["details"]);
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[6] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("7_") == 0) {
      maxPoints++;
      if (model["7_1"]["items"].length > 0) {
        points++;
        if (model["7_1"]["items"][0] != 4) {
          stringTyped(model["7_1"]["details"]);
        }
      }
      state.property.data.conveyancing_forms.preliminary_information.progress[7] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("8_") == 0) {
      yesNoSingle(model["8_1"]);
      if (model["8_1"] == 1) {
        stringTyped(model["8_1_1"]);
        yesNoSingle(model["8_1_2"]);
        yesNoSingle(model["8_1_3"]);
        if (model["8_1_3"] == 1) {
          yesNoSingle(model["8_1_3_i"]["yes"]);
          if (model["8_1_3_i"]["yes"] == 3) {
            maxPoints++;
            if (model["8_1_3_i"]["files"].length > 0) {
              points++;
            }
          }
        }
      }
      state.property.data.conveyancing_forms.preliminary_information.progress[8] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("9_") == 0) {
      yesNoSingle(model["9_1"]["yes"]);
      if (model["9_1"]["yes"] == 1) {
        yesNoSingle(model["9_1"]["upload"]);
        if (model["9_1"]["upload"] == 3) {
          maxPoints++;
          if (model["9_1"]["files"].length > 0) {
            points++;
          }
        }
      }
      yesNoSingle(model["9_2"]["yes"]);
      if (model["9_2"]["yes"] == 1) {
        yesNoSingle(model["9_2"]["upload"]);
        if (model["9_2"]["upload"] == 3) {
          maxPoints++;
          if (model["9_2"]["files"].length > 0) {
            points++;
          }
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[9] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("10_") == 0) {
      yesNoSingle(model["10_1"]);
      if (model["10_1"] == 1) {
        yesNoSingle(model["10_1_1"]);
        yesNoSingle(model["10_1_2"]["yes"]);
        if (model["10_1_2"]["yes"] == 3) {
          maxPoints++;
          if (model["10_1_2"]["files"].length > 0) {
            points++;
          }
        }
      }
      state.property.data.conveyancing_forms.preliminary_information.progress[10] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("11_") == 0) {
      yesNoSingle(model["11_1"]["yes"]);
      stringTyped(model["11_1"]["details"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[11] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("12_") == 0) {
      yesNoSingle(model["12_1"]["yes"]);
      if (model["12_1"]["yes"] == 1) {
        maxPoints++;
        if (model["12_1"]["items"].length > 0) {
          points++;
          model["12_1"]["items"].forEach((element) => {
            stringTyped(model["12_1_i"][element]["details"]);
            stringTyped(model["12_1_i"][element]["cost"]);
          });
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[12] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("13_") == 0) {
      yesNoSingle(model["13_1"]["yes"]);
      if (model["13_1"]["yes"] == 1) {
        maxPoints++;
        if (model["13_1"]["items"].length > 0) {
          points++;
          model["13_1"]["items"].forEach((element) => {
            stringTyped(model["13_1_i"][element]["payee"]);
            stringTyped(model["13_1_i"][element]["cost"]);
          });
        }
      }
      yesNoSingle(model["13_2"]["yes"]);
      yesNoSingle(model["13_3"]["yes"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[13] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("14_") == 0) {
      maxPoints++;
      if (model["14_1"]["items"].length > 0) {
        points++;
        if (model["14_1"]["items"].includes(9)) {
          stringTyped(model["14_1"]["details"]);
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[14] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("15_") == 0) {
      yesNoSingle(model["15_1"]["yes"]);
      if (model["15_1"]["yes"] == 1) {
        maxPoints++;
        if (model["15_1"]["items"].length > 0) {
          points++;
          if (model["15_1"]["items"].includes(5)) {
            stringTyped(model["15_1"]["details"]);
          }
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[15] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("16_") == 0) {
      stringTyped(model["16_1"]["details"]);
      if (model["16_1"]["details"] != "None") {
        stringTyped(model["16_1"]["other"]);
      }
      yesNoSingle(model["16_2"]["yes"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[16] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("17_") == 0) {
      yesNoSingle(model["17_1"]["yes"]);
      yesNoSingle(model["17_2"]["yes"]);
      if (model["17_2"]["yes"] == 1) {
        stringTyped(model["17_2_1"]);
        yesNoSingle(model["17_2_2"]["yes"]);
      }
      yesNoSingle(model["17_3"]["yes"]);
      if (model["17_2"]["yes"] == 1 && model["17_3"]["yes"] == 1) {
        yesNoSingle(model["17_3_1"]["yes"]);
        yesNoSingle(model["17_3_2"]["yes"]);
      }
      if (model["17_3"]["yes"] == 2) {
        yesNoSingle(model["17_4"]["upload"]);
        if (model["17_4"]["upload"] == 3) {
          maxPoints++;
          if (model["17_4"]["files"].length > 0) {
            points++;
          }
        }
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[17] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("18_") == 0) {
      yesNoItems(model["18_1"], ["year", "month", "category", "description"]);

      yesNoUpload(model["18_2"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[18] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("19_") == 0) {
      yesNoSingle(model["19_1"]["yes"]);
      if (model["19_1"]["yes"] == 1) {
        stringTyped(model["19_1"]["details"]);
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[19] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("20_") == 0) {
      yesNoSingle(model["20_1"]["yes"]);
      if (model["20_1"]["yes"] == 1) {
        stringTyped(model["20_1"]["details"]);
      }
      yesNoSingle(model["20_2"]["yes"]);
      if (model["20_2"]["yes"] == 1) {
        stringTyped(model["20_2"]["details"]);
      }

      state.property.data.conveyancing_forms.preliminary_information.progress[20] = round((points / maxPoints) * 100, 0);
    } else if (payload.questionKey.indexOf("21_") == 0) {
      yesNoSingle(model["21_1"]["yes"]);
      yesNoSingle(model["21_2"]["yes"]);
      yesNoSingle(model["21_3"]["yes"]);
      yesNoSingle(model["21_4"]["yes"]);
      if (model["21_4"]["yes"] == 1) {
        stringTyped(model["21_4"]["details"]);
      }
      stringTyped(model["21_5"]);

      state.property.data.conveyancing_forms.preliminary_information.progress[21] = round((points / maxPoints) * 100, 0);
    }

    if (
      state.property.data.conveyancing_forms.preliminary_information.progress[1] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[2] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[3] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[4] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[5] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[6] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[7] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[8] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[9] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[10] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[11] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[12] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[13] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[14] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[15] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[16] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[17] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[18] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[19] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[20] == 100 &&
      state.property.data.conveyancing_forms.preliminary_information.progress[21] == 100
    ) {
      state.property.data.conveyancing_forms.preliminary_information.complete = true;
    } else {
      state.property.data.conveyancing_forms.preliminary_information.complete = false;
    }
  },
  UPDATE_PRELIMINARY_INFORMATION_QUESTION(state, payload) {
    if (payload.index != undefined) {
      // Create if it doesn't exist
      if (
        state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey][payload.index] == undefined
      ) {
        state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey][payload.index] = {};
      }
      state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey][payload.index][
        payload.propKey
      ] = payload.value;
    } else if (payload.propKey) {
      // Create if it doesn't exist
      if (state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey] == undefined) {
        state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey] = {};
      }
      state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey][payload.propKey] =
        payload.value;
    } else {
      state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey] = payload.value;
    }
    store.dispatch("setTouched", true);
  },
  REMOVE_PRELIMINARY_INFORMATION_ITEM(state, payload) {
    state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey].items.splice(payload.index, 1);
    store.dispatch("setTouched", true);
  },
  UPDATE_PRELIMINARY_INFORMATION_ITEM(state, payload) {
    state.property.data.conveyancing_forms.preliminary_information.questions[payload.questionKey].items[payload.event.index][
      payload.event.fieldName
    ] = payload.event.val;
    store.dispatch("setTouched", true);
  },
  ADD_PRELIMINARY_INFORMATION_ITEM(state, payload) {
    state.property.data.conveyancing_forms.preliminary_information.questions[payload].items.push({
      id: _uniqueId("info_item_"),
    });
    store.dispatch("setTouched", true);
  },
  COMPLETE_PRELIMINARY_INFORMATION_FORM(state, payload: string) {
    if (state.property.data.conveyancing_forms.preliminary_information.complete == true) {
      state.property.data.conveyancing_forms.preliminary_information.accepted = true;
      state.property.data.conveyancing_forms.preliminary_information.signature = payload;
    }
  },
};

export const actions = {
  updatePreliminaryInformationQuestion({ commit, dispatch }, payload) {
    commit("UPDATE_PRELIMINARY_INFORMATION_QUESTION", payload);
    dispatch("updatePreliminaryInformationProgress", payload);
    dispatch("savePropertyData");
  },
  completePreliminaryInformationForm({ commit, dispatch, state }, payload: string) {
    commit("UPDATE_LOADING", true);
    commit("COMPLETE_PRELIMINARY_INFORMATION_FORM", payload);
    const touched = store.getters.getTouched;
    store.dispatch("setTouched", false);
    HopService.updateProperty(state.property.id, state.property.data)
      .then((response) => {
        commit("UPDATE_PROPERTY", response.data);
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", false);
        router.push({ name: "dashboard" });
      })
      .catch(() => {
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", true);
        store.dispatch("setTouched", touched);
      });
  },
  updatePreliminaryInformationProgress({ commit, dispatch }, payload) {
    if (payload.value == "" || payload.propKey == "yes" || payload.updateProgressImmediately) {
      commit("UPDATE_PRELIMINARY_INFORMATION_PROGRESS", payload);
    } else {
      dispatch("updatePreliminaryInformationProgressDebounced", payload);
    }
  },
  addPreliminaryInformationItem({ commit, dispatch }, payload) {
    commit("ADD_PRELIMINARY_INFORMATION_ITEM", payload);
    dispatch("updatePreliminaryInformationProgress", { questionKey: payload });
    dispatch("savePropertyData");
  },
  removePreliminaryInformationItem({ commit, dispatch }, payload) {
    commit("REMOVE_PRELIMINARY_INFORMATION_ITEM", payload);
    dispatch("updatePreliminaryInformationProgress", payload);
    dispatch("savePropertyData");
  },
  updatePreliminaryInformationItem({ commit, dispatch }, payload) {
    commit("UPDATE_PRELIMINARY_INFORMATION_ITEM", payload);
    dispatch("updatePreliminaryInformationProgress", payload);
    dispatch("savePropertyData");
  },
  updatePreliminaryInformationProgressDebounced: _debounce(({ commit }, payload) => {
    commit("UPDATE_PRELIMINARY_INFORMATION_PROGRESS", payload);
  }, 1000),
};
