<template>
  <div>
    <BaseModal ref="completeModal" @open="modalOpen = true" @close="modalOpen = false">
      <h1>Complete the form</h1>
      <br />
      <h3>To finish, please enter your full name:</h3>
      <BaseInput type="text" class="input" placeholder="Your name" v-model="sellerName" />
      <br />
      <div class="hidden" :class="{ shown: sellerName.length > 1 }">
        <h3>Now select which of the following applies to this property:</h3>
        <br />
        <BaseRadio
          class="submit-options"
          v-for="(option, key) in submitOptions"
          :showRadio="true"
          :key="key"
          :label="option.label"
          :name="key + '_radio'"
          :checked="sellerType == option.value"
          :value="option.value"
          @input="updateSellerType(option.value)"
        />
      </div>
      <div class="hidden" :class="{ shown: sellerName.length > 1 && sellerType != 0 }">
        <br />
        <h3>Now you can complete the form:</h3>
        <p>The date will be recorded as {{ currentDate }}</p>
        <br />
        <BaseButton @click="completeForm()"> Complete </BaseButton>
        <BaseButton @click="hideModal('completeModal', $event)"> Cancel </BaseButton>
      </div>
    </BaseModal>
    <div :class="{ shifted: connection }" class="save-banner-content" v-if="complete">
      <h1>Ready to complete?</h1>
      <p>
        If you have finished, press 'Complete form'. Once you complete the form, you can't make any alterations and if you need to
        alter any of your answers, you'll need to let your solicitor know directly.
        <!-- If you want to carry on later, save your changes. Once you complete the form, any further alterations will need to be made
        by your solicitor. -->
      </p>
      <br />
      <BaseButton
        @click="taForm ? showModal('completeModal') : completeForm()"
        v-if="complete"
        :small="true"
        :class="{ hidden: modalOpen }"
        :secondaryColour="true"
        >Complete form</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers/utils";

// if (process.env.VUE_APP_THEME && process.env.VUE_APP_THEME == "lawsociety") {
//   import("@/styles/themes/" + process.env.VUE_APP_THEME + "/_save_prompt.scss");
// }

export default {
  props: {
    complete: {
      type: Boolean,
    },
    completeAction: {
      type: String,
    },
    taForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    touched() {
      return this.$store.state["ui"].touched;
    },
    connection() {
      return this.$store.state["ui"].connectionProblem;
    },
    currentDate() {
      return formatDate(new Date());
    },
  },
  data() {
    return {
      submitOptions: this.$store.state.options.options.form_submit,
      sellerType: 0,
      sellerName: "",
      modalOpen: false,
    };
  },
  methods: {
    completeForm() {
      if (this.taForm) {
        if (this.sellerType != 0 && this.sellerName != "") {
          this.$store.dispatch(this.completeAction, {
            sellerType: this.sellerType,
            sellerName: this.sellerName,
            date: this.currentDate,
          });
        }
      } else {
        this.$store.dispatch(this.completeAction);
      }
    },
    showModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$refs[name].open(name);
    },
    hideModal(name, event) {
      if (event) {
        event.preventDefault();
      }
      this.$modal.close(name);
    },
    updateSellerType(val) {
      this.sellerType = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.save-banner,
.save-banner-content {
  &.shifted {
    bottom: 80.5px;
  }
}

.modal {
  .hidden {
    transition: opacity 0.6s;
  }
}

.hidden {
  opacity: 0;

  &.shown {
    opacity: 1;
  }
}

.save-banner-content {
  text-align: center;
  padding: 15px 10px 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  // background-color: rgba(70, 70, 70, 0.6);
  background-color: rgba(var(--colour-primary), 0.9);
  border-top: 3px solid var(--colour-secondary);
  background-color: #030303;
  h1 {
    color: var(--colour-white);
    margin: 0 0 5px;
  }

  p {
    display: inline-block;
    color: var(--colour-white);
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
  }

  .submit-options {
    border: 2px solidvar(--colour-primary);
  }

  :deep(.container) {
    display: inline-block;
    .btn {
      margin-right: 10px;
    }
  }
}
</style>
