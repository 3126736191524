import store from "@/store";
import HopService from "@/services/HopService.js";
import router from "@/router";
import _debounce from "lodash/debounce";

export const mutations = {
  INIT_SELLERS_QUESTIONS_ANSWERS(state) {
    for (let i = 0; i < state.property.data.passport.report.sellers_questions.length; i++) {
      state.property.data.sellers_questions.answers[i] = "";
    }
  },
  INIT_SELLERS_QUESTIONS_FILES(state) {
    if (state.property.data.sellers_questions.files == undefined) {
      state.property.data.sellers_questions.files = [];
    }
    for (let i = 0; i < state.property.data.passport.report.sellers_questions.length; i++) {
      state.property.data.sellers_questions.files[i] = [];
    }
  },
  UPDATE_SELLERS_QUESTION_ANSWER(state, payload) {
    state.property.data.sellers_questions.answers[payload.key] = payload.text;
    store.dispatch("setTouched", true);
  },
  UPDATE_SELLERS_QUESTION_FILE(state, payload) {
    state.property.data.sellers_questions.files[payload.key] = payload.val;
    store.dispatch("setTouched", true);
  },
  UPDATE_SELLERS_QUESTION_COMPLETE(state) {
    let complete = true;
    for (let i = 0; i < state.property.data.sellers_questions.answers.length; i++) {
      if (state.property.data.sellers_questions.answers[i] == "") {
        complete = false;
        break;
      }
    }
    state.property.data.sellers_questions.complete = complete;
  },
  COMPLETE_CONVEYANCING_ENQUIRIES(state) {
    if (state.property.data.sellers_questions.complete) {
      state.property.data.sellers_questions.accepted = true;
    }
  },
};

export const actions = {
  initSellersQuestionsAnswers({ commit }) {
    commit("INIT_SELLERS_QUESTIONS_ANSWERS");
  },
  initSellersQuestionsFiles({ commit }) {
    commit("INIT_SELLERS_QUESTIONS_FILES");
  },
  updateSellersQuestionAnswer({ commit, dispatch }, payload) {
    commit("UPDATE_SELLERS_QUESTION_ANSWER", payload);
    dispatch("updateSellersQuestionsProgress", payload);
    dispatch("savePropertyData");
  },
  updateSellersQuestionFile({ commit, dispatch }, payload) {
    commit("UPDATE_SELLERS_QUESTION_FILE", payload);
    dispatch("updateSellersQuestionsProgress", payload);
    dispatch("savePropertyData");
  },
  completeConveyancingEnquiries({ commit, dispatch, state }) {
    commit("UPDATE_LOADING", true);
    commit("COMPLETE_CONVEYANCING_ENQUIRIES");
    commit("UPDATE_FORMS_COMPLETION");
    const touched = store.getters.getTouched;
    store.dispatch("setTouched", false);
    HopService.updateProperty(state.property.id, state.property.data)
      .then((response) => {
        commit("UPDATE_PROPERTY", response.data);
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", false);
        router.push({ name: "dashboard" });
        store.dispatch("showAlert", "Conveyancing enquiries complete!");
      })
      .catch(() => {
        commit("UPDATE_LOADING", false);
        commit("UPDATE_CONNECTION_PROBLEM", true);
        store.dispatch("setTouched", touched);
      });
  },
  updateSellersQuestionsProgress: _debounce(({ commit }, payload) => {
    commit("UPDATE_SELLERS_QUESTION_COMPLETE", payload);
  }, 1000),
};

export const state = {
  accepted: false,
};
