import { AxiosResponse } from "axios";

export default {
  openDownloadInNewTab(response: AxiosResponse, name: string): void {
    let extension = "";

    switch (response.headers["content-type"]) {
      case "application/pdf":
        extension = ".pdf";
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        extension = ".docx";
        break;
    }

    if (extension == "") {
      return;
    }

    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window.navigator as any).msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement("a");
    link.href = data;

    // Add extension if not already in filename
    const splitName = name.split(".");
    if ("." + splitName[splitName.length - 1] != extension) {
      link.download = name + extension;
    } else {
      link.download = name;
    }
    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof link.download === "undefined") {
      link.setAttribute("target", "_blank");
    }

    link.click();
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  },
  getBase64(response: AxiosResponse): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      let base64 = "";
      reader.onloadend = () => {
        base64 = reader.result as string;
        resolve(base64);
      };
      reader.readAsDataURL(response.data);
    });
  },
};
