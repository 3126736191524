export const state = {
  options: {
    checklist_items: {
      sellers_information: {
        cost: 0,
        time: 5,
      },
      fittings_contents: {
        cost: 0,
        time: 20,
      },
      property_info: {
        cost: 0,
        time: 20,
      },
      preliminary_information: {
        time: 20,
        cost: 0,
      },
      leasehold_information: {
        time: 20,
        cost: 0,
      },
      rewards: {
        time: 5,
        cost: 0,
      },
      conveyancing_enquiries: {
        cost: 0,
        time: 5,
      },
    },
    fittings_contents: {
      select_options: {
        general: [
          {
            label: "Included",
            value: "1",
          },
          {
            label: "Excluded",
            value: "2",
          },
          {
            label: "None",
            value: "3",
          },
        ],
        kitchen_included: [
          {
            label: "Included",
            value: "1",
          },
          {
            label: "Excluded",
            value: "2",
          },
        ],
        kitchen_fitted: [
          {
            label: "Fitted",
            value: "4",
          },
          {
            label: "Free-standing",
            value: "5",
          },

          {
            label: "None",
            value: "3",
          },
        ],
      },
      basic: {
        max_other: 4,
        other_label: "Other items (please specify)",
        items: {
          boiler_immersion: "Boiler/immersion heater",
          radiators_wall: "Radiators/wall heaters",
          night_storage: "Night-storage heaters",
          free_standing: "Free-standing heaters",
          gas_fires: "Gas fires (with surround)",
          electric_fires: "Electric fires (with surround)",
          light_switches: "Light switches",
          roof_insulation: "Roof insulation",
          window_fittings: "Window fittings",
          window_shutters: "Window shutters/grilles",
          int_door_fittings: "Internal door fittings",
          ext_door_fittings: "External door fittings",
          doorbell: "Doorbell/chime",
          sockets: "Electric sockets",
          burglar_alarm: "Burglar alarm",
        },
      },
      kitchen: {
        max_other: 4,
        other_label: "Other items (please specify)",
        items: {
          hob: "Hob",
          extractor_hood: "Extractor hood",
          oven: "Oven/grill",
          cooker: "Cooker",
          microwave: "Microwave",
          fridge_freezer: "Refrigerator/fridge-freezer",
          freezer: "Freezer",

          dishwasher: "Dishwasher",
          tumble_dryer: "Tumble-dryer",
          washing_machine: "Washing machine",
        },
      },
      bathroom: {
        max_other: 0,
        items: {
          bath: "Bath",
          shower_fitting: "Shower fitting for bath",
          shower_curtain: "Shower curtain",
          bathroom_cabinet: "Bathroom cabinet",
          taps: "Taps",
          separate_shower: "Separate shower and fittings",
          towel_rail: "Towel rail",
          soap_toothbrush_holders: "Soap/toothbrush holders",
          toilet_roll_holders: "Toilet roll holders",
          bathroom_mirror: "Bathroom mirror",
        },
      },
      carpets: {
        max_other: 4,
        other_label: "Other rooms (please specify)",
        items: {
          hall_stairs_landing: "Hall, stairs and landing",
          living_room: "Living room",
          dining_room: "Dining room",
          kitchen: "Kitchen",
          bedroom_1: "Bedroom 1",
          bedroom_2: "Bedroom 2",
          bedroom_3: "Bedroom 3",
        },
      },
      curtain_rails_poles_pelmets: {
        max_other: 4,
        other_label: "Other rooms (please specify)",
        items: {
          hall_stairs_landing: "Hall, stairs and landing",
          living_room: "Living room",
          dining_room: "Dining room",
          kitchen: "Kitchen",
          bedroom_1: "Bedroom 1",
          bedroom_2: "Bedroom 2",
          bedroom_3: "Bedroom 3",
        },
      },
      curtains_blinds: {
        max_other: 4,
        other_label: "Other rooms (please specify)",
        items: {
          hall_stairs_landing: "Hall, stairs and landing",
          living_room: "Living room",
          dining_room: "Dining room",
          kitchen: "Kitchen",
          bedroom_1: "Bedroom 1",
          bedroom_2: "Bedroom 2",
          bedroom_3: "Bedroom 3",
        },
      },
      light_fittings: {
        max_other: 4,
        other_label: "Other rooms (please specify)",
        items: {
          hall_stairs_landing: "Hall, stairs and landing",
          living_room: "Living room",
          dining_room: "Dining room",
          kitchen: "Kitchen",
          bedroom_1: "Bedroom 1",
          bedroom_2: "Bedroom 2",
          bedroom_3: "Bedroom 3",
        },
      },
      fitted_units: {
        max_other: 4,
        other_label: "Other rooms (please specify)",
        items: {
          hall_stairs_landing: "Hall, stairs and landing",
          living_room: "Living room",
          dining_room: "Dining room",
          kitchen: "Kitchen",
          bedroom_1: "Bedroom 1",
          bedroom_2: "Bedroom 2",
          bedroom_3: "Bedroom 3",
        },
      },
      outdoor_area: {
        max_other: 5,
        other_label: "Other items (please specify)",
        items: {
          garden_furniture: "Garden furniture",
          garden_ornaments: "Garden ornaments",
          trees_plants_shrubs: "Trees, plants, shrubs",
          barbecue: "Barbecue",
          dustbins: "Dustbins",
          garden_shed: "Garden shed",
          greenhouse: "Greenhouse",
          outdoor_heater: "Outdoor heater",
          outside_lights: "Outside lights",
          water_butt: "Water butt",
          clothes_line: "Clothes line",
          rotary_line: "Rotary line",
        },
      },
      television_telephone: {
        max_other: 0,
        items: {
          telephone_receivers: "Telephone receivers",
          television_aerial: "Television aerial",
          radio_aerial: "Radio aerial",
          satellite_dish: "Satellite dish",
        },
      },
      fuel: {
        max_other: 0,
        items: {
          oil: "Oil",
          wood: "Wood",
          lpg: "Liquefied Petroleum Gas (LPG)",
        },
      },
      other: {
        max_other: 4,
        other_label: "Other items (please specify)",
        items: {},
      },
    },
    form_submit: [
      {
        label:
          "I confirm that I am the seller of the property and where there are two or more sellers I am authorised to complete this form on their behalf",
        value: 1,
      },
      {
        label:
          "I confirm that I am the seller of the property but there are other sellers who will also need to review and approve this form",
        value: 2,
      },
    ],
    property_information: {
      options: {
        heating: [
          "Mains Gas",
          "Electric central or room heating",
          "Community heating systems (heat networks and community / district heating system(s))",
          "LPG/oil central heating (tanks or bottles)",
          "Wood burner/open fire",
          "Biomass boiler",
          "Solar panels and related technology",
          "Ground or air source heat pumps",
          "Other (please specify)",
        ],
        paid_free: {
          1: "Paid",
          2: "Free",
        },
        yes_no_flat: {
          1: "Yes",
          2: "No",
          11: "Not a flat",
        },
        yes_rights: {
          9: "Yes, a right of way",
          10: "Yes, another right or arrangement",
          2: "No",
        },
        yes_no_mortgage: {
          1: "Yes",
          2: "No",
          8: "No mortgage",
        },
        yes_no_unknown: {
          1: "Yes",
          2: "No",
          5: "Not known",
        },
        yes_no_upload: {
          1: "Yes",
          2: "No",
          3: "Upload",
          4: "To follow",
        },
        yes_no_upload_unknown: {
          1: "Yes",
          2: "No",
          5: "Not known",
          3: "Upload",
          4: "To follow",
        },
        upload_follow_no: {
          2: "No",
          3: "Upload",
          4: "To follow",
        },
        upload_follow_unknown: {
          3: "Upload",
          4: "To follow",
          5: "Not known",
        },
        upload_follow_unknown_unavailable: {
          3: "Upload",
          4: "To follow",
          5: "Not known",
          7: "Not available",
        },
        broadband_types: {
          12: "ADSL (phone line)",
          13: "Cable",
          14: "Fibre to the premises",
          15: "Fibre to the cabinet",
          16: "Don't know",
        },
        building_projects: [
          { value: "extension", label: "Extension" },
          { value: "other", label: "Other" },
          { value: "porch", label: "Porch" },
        ],
        flooding: [
          {
            value: "a",
            label: "Ground water",
          },
          {
            value: "b",
            label: "Sewer flooding",
          },
          {
            value: "c",
            label: "Surface water",
          },
          {
            value: "d",
            label: "Coastal flooding",
          },
          {
            value: "e",
            label: "River flooding",
          },
          {
            value: "f",
            label: "Other (please state)",
          },
        ],
        insurance: [
          {
            value: "a",
            label: "Escaping water",
          },
          {
            value: "b",
            label: "Storm",
          },
          {
            value: "c",
            label: "Accidental damage",
          },
          {
            value: "d",
            label: "Theft",
          },
          {
            value: "e",
            label: "Fire",
          },
          {
            value: "f",
            label: "Subsidence",
          },
          {
            value: "g",
            label: "Flood",
          },
          {
            value: "h",
            label: "Malicious damage",
          },
          {
            value: "i",
            label: "Impact damage",
          },
          {
            value: "j",
            label: "Freeze",
          },
          {
            value: "k",
            label: "Other",
          },
        ],
        "12_2_a": [
          { value: "BS7671", label: "BS7671 Electrical Safety Certificate" },
          { value: "brcc", label: "Building Regulations Compliance Certificate" },
          { value: "bccc", label: "Building Control Completion Certificate" },
        ],
        "1_1": {
          seller: "Seller",
          shared: "Shared",
          neighbour: "Neighbour",
          not_known: "Not known",
        },
      },
      questions: {
        "1_1":
          "Looking towards the property from the road, who owns or accepts responsibility to maintain or repair the boundary features:",
        "1_2": "Are the boundaries irregular? If Yes, please indicate ownership by written description.",
        "1_3": "Is the seller aware of any boundary feature having been moved in the last 20 years? If Yes, please give details:",
        "1_4":
          "During the seller’s ownership, has any land previously forming part of the property been sold or has any adjacent property been purchased? If Yes, please give details:",
        "1_5":
          "Does any part of the property or any building on the property overhang, or project under, the boundary of the neighbouring property or road? If Yes, please give details:",
        "1_6":
          "Has any notice been received under the Party Wall Act 1996 in respect of any shared/party boundaries? If Yes, please supply a copy, and give details of any works carried out or agreed:",
        "1_7":
          "It can be helpful to provide photographs of the boundaries and any overhangs, particularly if they are unusual. If you think it would be helpful, please upload photos",
        "2_1":
          "Have there been any disputes or complaints regarding this property or a property nearby? If Yes, please give details:",
        "2_2":
          "Is the seller aware of anything which might lead to a dispute about the property or a property nearby? If Yes, please give details:",
        "3_1":
          "Have any notices or correspondence been received or sent (e.g. from or to a neighbour, council or government department), or any negotiations or discussions taken place, which affect the property or a property nearby? If Yes, please give details:",
        "3_2":
          "Is the seller aware of any proposals to develop property or land nearby, or of any proposals to make alterations to buildings nearby? If Yes, please give details:",
        "4_1": "Have any of the following changes been made to the whole or any part of the property (including the garden)?",
        "4_1_a":
          "Building works (e.g. extension, loft or garage conversion, removal of internal walls). If Yes, please give details including dates of all work undertaken:",
        "4_1_b": "Change of use (e.g. from an office to a residence)",
        "4_1_c": "Installation of replacement windows, roof windows, roof lights, glazed doors since 1 April 2002",
        "4_1_d": "Addition of a conservatory",
        "4_2":
          "If Yes to any of the questions in 4.1 and if the work was undertaken during the seller’s ownership of the property:",
        "4_2_a": "Can you supply copies of the planning permissions, Building Regulations approvals and Completion Certificates?",
        "4_2_b":
          "If none were required, please explain why these were not required – e.g. permitted development rights applied or the work was exempt from Building Regulations:",
        "4_3": "Are any of the works disclosed in 4.1 above unfinished? If Yes, please give details:",
        "4_4":
          "Is the seller aware of any breaches of planning permission conditions or Building Regulations consent conditions, unfinished work or work that does not have all necessary consents? If Yes, please give details:",
        "4_5": "Are there any planning or building control issues to resolve? If Yes, please give details:",
        "4_6": "Have solar panels been installed?",
        "4_6_a": "In what year were the solar panels installed?",
        "4_6_b": "Are the solar panels owned outright?",
        "4_6_c":
          "Has a long lease of the roof/air space been granted to a solar panel provider? If Yes, please supply copies of the relevant documents.",
        "4_7": "Is the property or any part of it:",
        "4_7_a": "a listed building?",
        "4_7_b": "in a conservation area?",
        "4_7_c": "If Yes, please supply copies of any relevant documents.",
        "4_8": "Are any of the trees on the property subject to a Tree Preservation Order?",
        "4_8_a": "Have the terms of the Order been complied with?",
        "4_8_b": "Please supply a copy of any relevant documents.",
        "4_9":
          "It can be helpful to provide photographs of any alterations which have been made to the property. If you think it would be helpful, please upload photos",
        "5_1": "Does the property benefit from any of the following guarantees or warranties? If Yes, please supply a copy.",
        "5_1_a": "New home warranty (e.g. NHBC or similar)",
        "5_1_b": "Damp proofing",
        "5_1_c": "Timber treatment",
        "5_1_d": "Windows, roof lights, roof windows or glazed doors",
        "5_1_e": "Electrical work",
        "5_1_f": "Roofing",
        "5_1_g": "Central heating",
        "5_1_h": "Underpinning",
        "5_1_i": "Other (please state):",
        "5_2": "Have any claims been made under any of these guarantees or warranties? If Yes, please give details:",
        "6_1": "Does the seller insure the property? If not, why not?",
        "6_3": "If the property is a flat, does the landlord insure the building?",
        "6_4": "Has any buildings insurance taken out by the seller ever been:",
        "6_4_a": "subject to an abnormal rise in premiums?",
        "6_4_b": "subject to high excesses?",
        "6_4_c": "subject to unusual conditions?",
        "6_4_d": "refused?",
        "6_4_e": "If Yes, please give details:",
        "6_5": "Has the seller made any buildings insurance claims? If Yes, please give details:",
        "7_1": "Has any part of the property (whether buildings or surrounding garden or land) ever been flooded?",
        "7_2": "Please state when the flooding occurred and identify the parts that flooded:",
        "7_3": "Has a Flood Risk Report been prepared? If Yes, please supply a copy.",
        "7_4": "Has a Radon test been carried out on the property? If so, please supply a copy of the report",
        "7_4_b": "was the test result below the ‘recommended action level’?",
        "7_5": "Were any remedial measures undertaken on construction to reduce Radon gas levels in the property?",
        "7_6": "Please supply a copy of the EPC for the property.",
        "7_7":
          "Have any installations in the property been financed under the Green Deal scheme? If Yes, please give details of all installations and supply a copy of your last electricity bill.",
        "7_8": "Is the property affected by Japanese knotweed?",
        "7_8_a":
          "If Yes, please state whether there is a Japanese knotweed management and treatment plan in place and supply a copy with any insurance cover linked to the plan.",
        "8_1":
          "Does ownership of the property carry a responsibility to contribute towards the cost of any jointly used services, such as maintenance of a private road, a shared driveway, a boundary or drain? If Yes, please give details:",
        "8_2":
          "Does the property benefit from any rights or arrangements over any neighbouring property? If Yes, please give details:",
        "8_3":
          "Has anyone taken steps to prevent access to the property, or to complain about or demand payment for access to the property? If Yes, please give details:",
        "8_4": "Does the seller know if any of the following rights benefit the property:",
        "8_4_a": "Rights of light",
        "8_4_b": "Rights of support from adjoining properties",
        "8_4_c": "Customary rights (e.g. rights deriving from local traditions)",
        "8_5": "Does the seller know if any of the following arrangements affect the property:",
        "8_5_a": "Other people’s rights to mines and minerals under the land",
        "8_5_b": "Chancel repair liability",
        "8_5_c": "Other people’s rights to take things from the land (such as timber, hay or fish)",
        "8_6": "Are there any other rights or arrangements affecting the property? If Yes, please give details:",
        "8_7": "Do any drains, pipes or wires serving the property cross any neighbour’s property?",
        "8_8": "Do any drains, pipes or wires leading to any neighbour’s property cross the property?",
        "8_9": "Is there any agreement or arrangement about drains, pipes or wires?",
        "8_9_a": "If Yes, please supply a copy:",
        "9_1":
          "What are the parking arrangements at the property? Please include information about any charges you have to pay for parking.",
        "9_1_b": "Is the resident's permit paid or free?",
        "9_1_c": "Is there an EV charging point?",
        "9_2": "Is the property in a controlled parking zone or within a local authority parking scheme?",
        "10_1":
          "Does the seller have to pay any charges relating to the property (excluding any payments such as council tax, utility charges, etc.), for example payments to a management company? If Yes, please give details:",
        "11_1": "Does the seller live at the property?",
        "11_2": "Does anyone else, aged 17 or over, live at the property?",
        "11_3": "Please give the full names of any occupiers (other than the sellers) aged 17 or over:",
        "11_4": "Are any of the occupiers (other than the sellers), aged 17 or over, tenants or lodgers?",
        "11_5": "Is the property being sold with vacant possession?",
        "11_5_a": "Have all the occupiers aged 17 or over agreed to leave prior to completion?",
        "11_5_b":
          "Have all the occupiers aged 17 or over agreed to sign the sale contract? If No, please supply other evidence that the property will be vacant on completion.",
        "12_1": "Has the whole or any part of the electrical installation been tested by a qualified and registered electrician?",
        "12_1_a": "If Yes, please state the year it was tested and provide a copy of the test certificate.",
        "12_2": "Has the property been rewired or had any electrical installation work carried out since 1 January 2005?",
        "12_2_a":
          "If Yes, please supply one of the following: a copy of the signed BS7671 Electrical Safety Certificate, the installer’s Building Regulations Compliance Certificate, or the Building Control Completion Certificate.",
        "12_3": "Does the property have a central heating system?",
        "12_3_a":
          "What type of system is it? Please select all that apply <b style='color: red'>(at least one must be selected).</b>",
        "12_3_b":
          "When was the heating system installed? If on or after 1 April 2005 please supply a copy of the ‘completion certificate’ (e.g. CORGI or Gas Safe Register) or the ‘exceptional circumstances’ form.",
        "12_3_c": "Is the heating system in good working order?",
        "12_3_d": "In what year was the heating system last serviced/maintained? Please supply a copy of the inspection report.",
        "12_4": "Is the property connected to mains:",
        "12_4_a": "foul water drainage?",
        "12_4_b": "surface water drainage?",
        "12_5": "Is sewerage for the property provided by:",
        "12_5_a": "a septic tank?",
        "12_5_a_i": "When was the septic tank last replaced or upgraded?",
        "12_5_b": "a sewage treatment plant?",
        "12_5_c": "cesspool?",
        "12_6":
          "Is the use of the septic tank, sewage treatment plant or cesspool shared with other properties? If Yes, how many properties share the system?",
        "12_7": "When was the system last emptied?",
        "12_8": "If the property is served by a sewage treatment plant, when was the treatment plant last serviced?",
        "12_9": "When was the system installed?",
        "12_10":
          "Is any part of the septic tank, sewage treatment plant (including any soakaway or outfall) or cesspool, or the access to it, outside the boundary of the property? If Yes, please supply a plan showing the location of the system and how access is obtained.",
        "13_1_i":
          "Please answer which of the following utilities and services are connected to the property and give details of any providers.",
        "13_1": "Mains electricity",
        "13_1_a": "Provider’s name",
        "13_1_b": "Location of meter",
        "13_2": "Mains water",
        "13_2_a": "Provider’s name",
        "13_2_b": "Location of stopcock",
        "13_2_c": "Location of meter",
        "13_2_d": "Is the water supply metered?",
        "13_3": "Telephone",
        "13_3_a": "Provider’s name",
        "13_4": "Mains gas",
        "13_4_a": "Provider’s name",
        "13_4_b": "Location of meter",
        "13_5": "Mains sewerage",
        "13_5_a": "Provider’s name",
        "13_6": "Cable",
        "13_6_a": "Provider’s name",
        "13_7": "Broadband",
        "13_7_a": "Type of connection",
        "14_1": " Is this sale dependent on the seller completing the purchase of another property on the same day?",
        "14_2": "Does the seller have any special requirements about a moving date? If Yes, please give details:",
        "14_3": "Does the sale price exceed the amount necessary to repay all mortgages and charges secured on the property?",
        "14_4": "Will the seller ensure that:",
        "14_4_a":
          "all rubbish is removed from the property (including from the loft, garden, outbuildings, garages and sheds) and that the property will be left in a clean and tidy condition?",
        "14_4_b": "if light fittings are removed, the fittings will be replaced with ceiling rose, flex, bulb holder and bulb?",
        "14_4_c": "reasonable care will be taken when removing any other fittings or contents?",
        "14_4_d":
          "keys to all windows and doors and details of alarm codes will be left at the property or with the estate agent?",
      },
    },
    preliminary_information: {
      questions: {
        "1_1":
          "This is the address of the property as shown in the title deeds. Please confirm it is correct, or if necessary amend.",
        "3_1": "Is your property freehold or leasehold?",
        "3_2":
          "You’ll need a copy of your lease in order to answer these questions. If you do not have a copy of your lease, ask your solicitor for help. Estate agents need to be able to share the following information with the buyer.",
        "3_2_1": "How many years will be left on the lease after the end of the current year?",
        "3_2_2_1": "How much ground rent do you pay each year?",
        "3_2_2_2": "Does your lease say when the rent is likely to be increased?",
        "3_2_2_3": "Are the rent increases fixed, or variable?",
        "3_2_3_1": "Have you paid service charges?",
        "3_2_3_2": "How much was the service charge last year (between 1 January and 31 December)?",
        "3_2_3_3": "Is there a budget or known amount for the service charge this year?",
        "3_2_3_3_i": "Please upload any relevant documents, such as a service charge bill.",
        "3_2_3_4": "When are the payments due?",
        "3_2_3_5": "Who are payments made to?",
        "3_2_4": "Have you applied to the landlord for an extension of the lease or to buy the freehold?",
        "3_2_4_i": "Please provide any details of when you applied and whether the application has been accepted:",
        "4_1": "Were you the first person to occupy the property after it was built or converted?",
        "5_1": "Does the proposed sale depend on you buying another property?",
        "5_1_i": "Where are you in that transaction?",
        "5_1_ii": "Have there have been any hold-ups?",
        "5_1_iii": "What is your expected completion date?",
        "5_2": "Are there other factors that might affect the timing of your move?",
        "5_2_i":
          "Select any that may affect your ability to move by a certain date and/or affect others if you’re in a property chain.",
        "6_1":
          "Have any of the following changes been made to the property or any part of it (including the garden), during your ownership?",
        "6_2_1": "Is all of this work completed?",
        "6_2_2": "Did you have to get planning consent?",
        "6_2_2_1":
          "You’ll need to upload copies of the planning documents and consents. If you are not sure which documents are relevant, ask your solicitor for help.",
        "6_2_2_2":
          "If no planning consents were required, explain why. For instance, if the work was exempt from building regulations or permitted development rights applied.",
        "7_1": "Are you aware of any the following?",
        "8_1": "Have solar panels been installed at the property?",
        "8_1_1": "Which year were the solar panels installed?",
        "8_1_2": "Do you own the solar panels outright?",
        "8_1_3":
          "Has a long lease of the roof / air space been granted to a solar panel provider? (A typical long lease may last 20 to 25 years.)",
        "8_1_3_i":
          "Please supply copies of the relevant documents, such as copies of electricity bills for feed-in tariffs (payments made for supplying electricity to the main grid).",
        "9_1": "Is the property (or any part of it) listed in the National Heritage List for England?",
        "9_1_i":
          "Please supply a copy of any relevant documents eg notice of listing, letter from local authority confirming listing.",
        "9_2": "Is the property (or any part of it) in a conservation area?",
        "9_2_i": "Please supply a copy of any relevant documents.",
        "10_1": "Are any trees on your property subject to a Tree Preservation Order?",
        "10_1_1": "Have the terms of the order been complied with?",
        "10_1_2": "Supply a copy of any relevant documents.",
        "11_1": "Do you have consent for any matters that need permission?",
        "11_1_i":
          "Check with your solicitor that all necessary consents have been received. If you are not sure if permission is needed, ask your solicitor as soon as it is practical.",
        "12_1": "Do you have to pay any charges relating to the property (apart from council tax, utility charges, and so on)?",
        "12_1_i":
          "If yes, give details. For example, if your property is freehold, charges might include payments to a management company or residents’ association, or for a private drainage system. If your property is leasehold, you’ll need to give details of lease expenses (such as service charges and ground rent) on the separate TA7 Leasehold information form.",
        "13_1": "Do you have to pay anything towards the costs of maintaining roads, footpaths or other facilities?",
        "13_1_i": "If yes, give details of any payments (how much, and who payments are made to).",
        "13_2": "Is your house on a private road or is the road that gives access to or adjoins the property a private road?",
        "13_3": "Are the roads leading to your property maintained at public expense?",
        "14_1": "Which of the following services are connected to the property?",
        "15_1":
          "Are there any areas or facilities shared with neighbours (excluding common parts of a leasehold block or estate)?",
        "16_1": "What are the parking arrangements at the property?",
        "16_2": "Is the property in a controlled parking zone or within a local authority parking scheme?",
        "17_1": "Do you live at the property?",
        "17_2": "Does anyone else, aged 17 or over, live at the property?",
        "17_2_1": "Supply full names of any occupiers (other than yourself) aged 17 or over.",
        "17_2_2": "Are any of the occupiers who are aged 17 or over (other than yourself), tenants or lodgers?",
        "17_3": "Is the property being sold with vacant possession?",
        "17_3_i":
          "When buying or selling a property, 'vacant possession' means that the property is empty and emptied of anything not contracted to remain on the day of completion eg chattels, rubbish.",
        "17_3_1": "Have all the occupiers aged 17 or over agreed to leave before completion?",
        "17_3_2": "Have all the occupiers aged 17 or over agreed to sign the sale contract?",
        "17_4":
          "If the property is not being sold with vacant possession, you’ll need to supply evidence that the property will be empty on the day of completion.",
        "18_1": "Has any part of the property (buildings, surrounding garden or land) ever been flooded?",
        "18_1_1": "When did the flooding take place?",
        "18_1_2": "Which parts flooded?",
        "18_1_3": "What type of flooding took place?",
        "18_2": "Has a flood risk report been created for the property?",
        "19_1":
          "Have you been told about plans for any building or developments that might affect someone’s ability to peacefully use and enjoy the property?",
        "19_1_i": "If yes, give details eg what the plans are and when they are expected to take place",
        "20_1": "Have there been any disputes or complaints about your property or a property nearby?",
        "20_1_i": "If yes, give details such as when this took place and who was involved.",
        "20_2": "Are you aware of anything that might lead to a dispute about your property or a property nearby?",
        "21_1": "Is the property affected by Japanese Knotweed?",
        "21_2": "Is the property in a mining area?",
        "21_3":
          "Has something happened in the property that a buyer would want to know about or might influence their decision to purchase? You may provide more detail in question 21.5.",
        "21_4": "Do any neighbours or members of the public have the right to enter your property?",
        "21_5":
          "Is there anything else you think a buyer would want to know, or that might influence their decision to buy or that you remember about the property when you bought it?",
        "21_5_i": "For example, why are you moving? If you are not sure if you should disclose information, ask your solicitor.",
      },
      options: {
        ownership_type: {
          1: "Freehold",
          2: "Leasehold",
        },
        "3_2_2_3": {
          1: "Fixed",
          2: "Variable",
          3: "Neither",
        },
        "3_2_4": { 1: "The application has been accepted", 2: "The application has not been accepted" },
        "5_1": {
          1: "Still looking for a property",
          2: "Have seen a property and am interested in buying",
          3: "Have had an offer accepted",
          4: "Solicitors are instructed",
          5: "Other",
        },
        "5_2": [
          "End of school term",
          "Medical (including pregnancy)",
          "Upcoming holiday",
          "Giving notice to tenant in occupation",
          "Job move",
          "Retirement",
          "Redundancy",
          "Proposed completion date, if property is a new build",
          "Other",
        ],
        "6_1": [
          "Part of the property is not used for residential occupation eg commercial use",
          "Installing replacement windows, roof windows, roof lights, glazed doors since 1 April 2002",
          "Adding an extension",
          "Adding a conservatory",
          "Loft conversion",
          "Garage conversion",
          "Removal of internal walls",
          "Other building works or changes to the property",
        ],
        "7_1": [
          "Breaches of planning permission conditions",
          "Breaches of building regulations consent conditions",
          "Unfinished work",
          "Work that does not have all necessary consents",
          "None of the above",
        ],
        "12_1": [
          "Leasehold rent",
          "Leasehold service charge",
          "Freehold management or estate charges",
          "Payment to a residents' association",
          "Other",
        ],
        "13_1": [
          "Payment towards driveway / road costs",
          "Payment towards shared / private drains",
          "Contribution to boundary upkeep",
          "Payment towards footpath costs",
        ],
        "14_1": [
          "Mains drainage",
          "Electricity",
          "Broadband",
          "Telephone landline",
          "Ground and air heat pumps",
          "Water supply",
          "Gas",
          "Sewage plant",
          "Solar panels",
          "Other",
        ],
        "15_1": ["Driveway", "Road", "Footpath", "Garden", "Drains", "Other"],
      },
    },
    shared: {
      options: {
        yes_no: {
          1: "Yes",
          2: "No",
        },
        yes_no_not_known: {
          1: "Yes",
          2: "No",
          3: "Not known",
        },
        upload_follow_supplied: {
          3: "Upload",
          4: "To follow",
          6: "Already supplied",
        },
        upload_follow: {
          3: "Upload",
          4: "To follow",
        },
        upload_lost: {
          3: "Upload",
          4: "To follow",
          5: "Lost",
        },
        frequency: {
          1: "Yearly",
          2: "Monthly",
          3: "Weekly",
          4: "Other",
        },
      },
    },
  },
};
