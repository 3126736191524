<template>
  <div class="footer">
    <p v-if="theme == 'wiggywam'">Wiggywam’s Move Ready app is powered by <span class="hop">hop™</span></p>
    <p v-if="theme == 'canopy'">canopy's Move Ready app is powered by <span class="hop">hop™</span></p>
    <p v-if="theme == 'knightfrank'">Knight Frank's Move Ready app is powered by <span class="hop">hop™</span></p>
    <p><span class="hop">hop™</span> is powered by Teal Legal’s property categorisation tool which is patent pending.</p>
    <p>
      <span class="hop">hop™</span> is provided by Teal Legal Limited, Registered Office, The Glades Festival Way, Festival Park,
      Stoke-On-Trent, Staffordshire, England, ST1 5SQ, Company Registration Number 11148669. Teal Legal Ltd is registered in
      England and Wales. Teal Legal Ltd can be contacted at <a href="mailto:hello@teallegal.com">hello@teallegal.com</a>.
    </p>
    <p>Teal Legal Ltd is not a law firm and does not provide legal services or advice.</p>
    <p>
      <a href="https://www.homeownerspassport.com/privacy">Terms and Conditions/Privacy Notice</a>
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({
    theme: (state) => state.ui.theme,
  }),
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: var(--colour-primary);
  padding: $container-spacing-vertical $container-spacing-horizontal;

  font-size: 11px;

  p {
    color: var(--colour-white);
    text-align: left;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  a {
    color: var(--colour-secondary);
    text-decoration: none;
  }

  .hop {
    font-weight: bold;
  }
}
</style>
