<template>
  <div>
    <FormSection title="16. Parking" :complete="model.preliminary_information.progress[16]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">16.1</span>
              <span> {{ options.preliminary_information.questions["16_1"] }}</span>
            </p>

            <div class="question-content">
              <BaseSelect
                initial="Type"
                class="select"
                :options="parkingTypes"
                @input="$emit('updateModelLevel2', '16_1', 'details', $event)"
                :value="model.preliminary_information.questions['16_1']['details']"
              />
              <BaseTextArea
                v-if="
                  model.preliminary_information.questions['16_1']['details'] != 'None' &&
                  model.preliminary_information.questions['16_1']['details'] != ''
                "
                rows="5"
                @update:modelValue="$emit('updateModelLevel2', '16_1', 'other', $event)"
                :modelValue="model.preliminary_information.questions['16_1']['other']"
                placeholder="Enter details here"
              />
            </div>
          </div>
          <div class="question">
            <p class="question-title">
              <span class="important">16.2</span>
              <span> {{ options.preliminary_information.questions["16_2"] }}</span>
            </p>
            <MultipleChoiceQuestion
              questionKey="16_2"
              @input="$emit('updateModelLevel2', '16_2', 'yes', $event)"
              :options="shared_options.yes_no_not_known"
              :model="model.preliminary_information.questions['16_2']['yes']"
            />
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";
import OptionsService from "@/services/OptionsService";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
  data() {
    return {
      parkingTypes: OptionsService.parkingTypes(),
    };
  },
});
</script>
