import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-33f05059"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "value", "checked", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (option, key) => {
      return (_openBlock(), _createElementBlock("label", {
        key: key,
        for: _ctx.id + key,
        class: _normalizeClass({ checked: _ctx.modelValue.includes(key) })
      }, [
        _createTextVNode(_toDisplayString(option) + " ", 1),
        _createElementVNode("input", {
          id: _ctx.id + key,
          type: "checkbox",
          value: _ctx.modelValue.includes(key),
          checked: _ctx.modelValue.includes(key),
          onInput: _withModifiers(($event: any) => (_ctx.mark(key, $event)), ["stop"])
        }, null, 40, _hoisted_3)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}