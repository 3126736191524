import store from "@/store";
import permissionsValues from "@/helpers/permissionsValues";
// import { getAuth0IsLoading, getAuth0IsAuthenticated } from "./services/HopService.js";

// permissionsValues.
export default class Permissions {
  hasPermissionTo = function (action, role = null) {
    if (role == null) {
      role = store.state.user.user.role;
    }
    if (role == -1) {
      // User not fetched yet, deny
      return false;
    }

    if (role == permissionsValues.USER_TYPE_ADMIN) {
      return true;
    }

    var permissions = {
      [permissionsValues.ACTION_VIEW_PROPERTY_SIGNATURES]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ADMIN_ONLY]: {},
      [permissionsValues.ACTION_SET_GLOBAL_ADMIN_OPTIONS]: {},
      [permissionsValues.ACTION_UPLOAD_FILES_TO_PROPERTY]: {},
      [permissionsValues.ACTION_VIEW_AGENCIES_CREDITS]: {},
      [permissionsValues.ACTION_VIEW_ALL_AGENCIES]: {},
      [permissionsValues.ACTION_VIEW_BRANDS]: {},
      [permissionsValues.ACTION_CREATE_SIMPLE_AGENT]: {},
      [permissionsValues.ACTION_VIEW_ALL_MOVERS]: {},
      [permissionsValues.ACTION_VIEW_STATS]: {},
      [permissionsValues.ACTION_VIEW_ALL_AGENTS]: {},
      [permissionsValues.ACTION_VIEW_ALL_SOLICITORS]: {},
      [permissionsValues.REPORT_PROP_ATTRIBUTES]: {},
      [permissionsValues.ACTION_VIEW_SOLICITORS]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_CREATE_PROP]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_SIMPLE]: true,
      },
      [permissionsValues.ACTION_CREATE_PROPERTY_WITH_OTHER_PARENT]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.EDIT_TA6_TRIAL]: {},
      [permissionsValues.ACTION_LIST_PROPS]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_VIEW_ADMIN]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_SIMPLE]: true,
      },
      [permissionsValues.ACTION_EDIT_FIXTURES_FITTINGS]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_CREATE_AGENCY]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_VIEW_AGENCIES]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_VIEW_BRAND]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_CREATE_AGENT]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_VIEW_MOVERS]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_EDIT_PRELIMINARY_INFO]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_PROP_META]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_VIEW_PROP_COMPLETION]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_AGENCIES_UNDER_BRAND]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_VIEW_CONVEYANCING_ENQUIRIES]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_INVITE_SELLER]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_INVITE_SOLICITOR]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_INVITE_AGENT]: {
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_BRAND]: true,
      },
      [permissionsValues.ACTION_INVITE_TO_OTHER_ORGANISATIONS_PROPERTY]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
      },
      [permissionsValues.EDIT_PROP_INFO]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_EDIT_LEASEHOLD_INFO]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.EDIT_PROP_INFO_SERVICES]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
      },
      [permissionsValues.EDIT_PROP_INFO_UTILS_SERVICES]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
      },
      [permissionsValues.VIEW_PROP_INFO]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
      },
      [permissionsValues.ACTION_VIEW_MATERIAL_INFO]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_MOVEREADY_SCORE]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_ACTION_PLAN]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_SELLERS_CERTIFICATE]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_CONV_ENQUIRIES]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_ABOUT_MY_HOME]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_EDIT_CONV_ENQUIRIES]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_EDIT_ABOUT_MY_HOME]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_TA6]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_TA7]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_TA10]: {
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_VIEW_DEED_ENTRIES_DETAILS]: {
        [permissionsValues.USER_TYPE_SIMPLE]: true,
        [permissionsValues.USER_TYPE_BRAND]: true,
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
        [permissionsValues.USER_TYPE_MOVER]: true,
      },
      [permissionsValues.ACTION_REQUEST_SEARCHES]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
        [permissionsValues.USER_TYPE_SOLICITOR]: true,
      },
      [permissionsValues.ACTION_READ_ONLY_FORM_ACCESS]: {
        [permissionsValues.USER_TYPE_AGENT]: true,
      },
    };
    if (permissions[action] && permissions[action][role]) {
      return true;
    }
    return false;
  };

  hasNoPermissionTo = function (action) {
    return !this.hasPermissionTo(action);
  };
}
