<template>
  <div :class="'theme-' + theme">
    <Header />
    <router-view v-if="appLoaded" />
    <!-- <pre>{{ user }}</pre> -->
    <Footer />
    <div v-if="showConnectionError" class="connectionProblem"></div>
    <div v-if="showConnectionError" class="connectionProblemContent">
      We're having some problems connecting to {{ hop }}. Please make sure you have a stable connection and try again in
      a few
      minutes. Check our
      <a href="https://status.homeownerspassport.com/" target="_blank" class="status-link">status page<span
          class="new-tab-indicator">↗</span>
      </a>
    </div>
    <BaseLoader :opaque="opaqueLoader" />
    <BaseAlert />
    <Firework />
  </div>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import { mapState } from "vuex";
import store from "./store";
import router from "@/router";
import * as authHelpers from "./helpers/authentication.js";
import { addAuth0 } from "@/services/HopService";
import Firework from "@/components/Firework.vue";
import Config from "./services/Config";
// if (process.env.VUE_APP_THEME && process.env.VUE_APP_THEME == "lawsociety") {
//   import("@/styles/themes/" + process.env.VUE_APP_THEME + "/_app.scss");
// }

export default {
  components: {
    Header,
    Footer,
    Firework,
  },
  data() {
    return {
      // This adds specificity to theme styling
      appName: "hop",
      theme: "hop",
    };
  },
  methods: {
    checkEntryHostname() {
      const configs = [
        {
          condition: () =>
            store.state.user.user.organisation.parent_name === "Knight Frank" &&
            Config.getTheme() !== "knightfrank" &&
            process.env.NODE_ENV === "production",
          action: () => {
            window.location.href = "https://knightfrank.homeownerspassport.com";
          },
        },
        // Implement other white labels as needed
        // Example:
        // {
        //   condition: () => someOtherCondition,
        //   action: () => {
        //     // Redirect to correct white label
        //   },
        // },
      ];

      configs.forEach((config) => {
        if (config.condition()) {
          config.action();
        }
      });
    },
    init() {
      this.appName = Config.getAppName();
      this.theme = Config.getTheme();
      this.$store.commit("UPDATE_THEME", this.theme);
      document.title = this.appName;

      const favicon = document.getElementById("favicon");
      favicon.setAttribute("href", Config.getFavicon());

      switch (this.theme) {
        case "canopy":
          import("@/styles/themes/canopy/_app.scss");
          break;
        case "knightfrank":
          import("@/styles/themes/knightfrank/_app.scss");
          break;
        default:
      }

      this.$store.dispatch("updateLoading", true);
      addAuth0(this.$auth0);
      if (this.$auth0.isLoading.value) {
        setTimeout(() => {
          this.init();
        }, 10);
      } else {
        if (this.$auth0.isAuthenticated.value) {
          const newUser = {
            name: this.$auth0.user.value.name,
            email: this.$auth0.user.value.email,
          };
          store.dispatch("updateUser", {
            user: newUser,
          }).then((user) => {
            this.checkEntryHostname();
          });

          if (router.currentRoute.value.name == "auth" || router.currentRoute.value.name == "root") {
            this.$router.push({ name: "properties" });
          }
          if (!router.currentRoute.value.params.id) {
            if (authHelpers.checkRoutePermissions(router.currentRoute.value.name, this.$store.getters.getUserRole)) {
              store.dispatch("appIsLoaded", true);
            } else {
              this.$router.push({ name: "properties" });
            }
          }
        } else {
          if (router.currentRoute.value.name != "auth") {
            router.push({ name: "auth" });
            this.$store.dispatch("updateLoading", false);
          } else {
            // We're on auth
            store.dispatch("appIsLoaded", true);
            store.dispatch("updateLoading", false);
          }
        }
      }
    },
  },
  computed: {
    showConnectionError() {
      if (router.currentRoute.name != "auth") {
        return this.connection;
      } else {
        return false;
      }
    },
    hop() {
      return this.appName;
    },
    ...mapState({
      menuOpen: (state) => state.ui.menuOpen,
      user: (state) => state.user.user,
      connection: (state) => state.ui.connectionProblem,
      appLoaded: (state) => state.ui.appLoaded,
      opaqueLoader: (state) => state.ui.opaqueLoader,
    }),
  },
  async mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
// CSS applied once at the top level can live here
@import "@/styles/global/_reset.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
@import "@/styles/global/_common.scss";
@import "@/styles/global/_loader.scss";
@import "@/styles/global/_admin.scss";

body {
  margin: 0 auto;
  font-family: $font-primary;
  font-weight: 300;

  * {
    box-sizing: border-box;
  }

  .connectionProblem {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $colour-black;
    opacity: 0.5;
    z-index: 1;
    height: 80px;
  }

  .connectionProblemContent {
    padding: 20px 10px;
    text-align: center;
    color: var(--colour-white);
    z-index: 2;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
  }

  .status-link {
    color: var(--colour-white);
  }

  .new-tab-indicator {
    margin-left: 4px;
    font-size: 0.85em;
  }
}
</style>
