<template>
  <div>
    <BaseInput
      :type="modifiedType"
      :suffix="suffix"
      placeholder=""
      :modelValue="answer"
      @update:modelValue="$emit('input', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    answer: String,
    type: String,
  },
  computed: {
    modifiedType(): string | undefined {
      if (this.type === "percentage") {
        return "number";
      }
      return this.type;
    },
    suffix(): string | undefined {
      if (this.type === "percentage") {
        return "%";
      }
      return undefined;
    },
  },
});
</script>

<style scoped></style>
