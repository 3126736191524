<template>
  <div class="header">
    <router-link v-if="user.email" :to="{ name: 'properties' }">
      <div class="header__logo">
        <Logo />
      </div>
    </router-link>
    <a href="/" v-if="!user.email">
      <div class="header__logo">
        <Logo />
      </div>
    </a>
    <div class="header__bar">
      <!-- <div v-if="['auth'].indexOf($route.name) == -1 && user.displayName" class="header__bar__user">
        Hi {{ user.displayName }}, nice to see you
      </div> -->
      <div class="header__nav">
        <router-link class="header__nav__item" v-if="canViewAdmin" :to="{ name: 'admin' }"> Admin </router-link>
        <router-link class="header__nav__item" v-if="['properties', 'auth', ''].indexOf($route.name) == -1"
          :to="{ name: 'properties' }">
          Switch property
        </router-link>
        <router-link class="header__nav__item" v-if="$route.path.match(/^\/properties\/\d+\/.+$/)"
          :to="{ name: 'dashboard', params: { id: $route.params.id } }">
          Back to dashboard
        </router-link>

        <router-link class="header__nav__item" v-if="canInviteSeller" :to="{ name: 'invite-seller' }">
          Invite seller
        </router-link>
        <router-link class="header__nav__item" v-if="canInviteSolicitor" :to="{ name: 'invite-solicitor' }">
          Invite solicitor
        </router-link>
        <router-link class="header__nav__item" v-if="canInviteAgent" :to="{ name: 'invite-agent' }"> Invite agent
        </router-link>
        <div @click="logOut" class="header__nav__item" v-if="$route.name == 'properties'">Log out</div>
        <template v-if="canSearch">
          <BaseInput v-model="searchTerm" placeholder="Search..." @keyup.enter="submitSearch" noMargin
            class="search-input" />
          <div @click="submitSearch" class="search-button header__nav__item">Search</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Logo from "@/components/Widgets/Logo.vue";
import Permissions from "@/helpers/permissions";
import permissionsValues from "@/helpers/permissionsValues";
// if (process.env.VUE_APP_THEME) {
//   import("@/styles/themes/" + process.env.VUE_APP_THEME + "/_header.scss");
// }

export default {
  components: {
    Logo,
  },
  name: "Header",
  data() {
    return {
      showSearch: false,
      searchTerm: '',
    };
  },
  computed: {
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    canViewAdmin() {
      return this.hasPermissionTo(permissionsValues.ACTION_VIEW_ADMIN);
    },
    canInviteAgent() {
      return this.canInvite() && this.hasPermissionTo(permissionsValues.ACTION_INVITE_AGENT);
    },
    canInviteSeller() {
      return this.canInvite() && this.hasPermissionTo(permissionsValues.ACTION_INVITE_SELLER);
    },
    canInviteSolicitor() {
      return this.canInvite() && this.hasPermissionTo(permissionsValues.ACTION_INVITE_SOLICITOR);
    },
    ...mapState({
      user: (state) => state.user.user,
      owner_id: (state) => state.property.property.owner_id,
    }),
    canSearch() {
      return this.hasPermissionTo(permissionsValues.ACTION_CREATE_PROP);
    },
  },
  methods: {
    logOut() {
      this.$auth0.logout({
        logoutParams: {
          returnTo: window.location.origin + "/auth",
        },
      });
    },
    canInvite() {
      return (
        this.$route.params.id &&
        (this.user.organisation.id == this.owner_id ||
          this.hasPermissionTo(this.permissions.ACTION_INVITE_TO_OTHER_ORGANISATIONS_PROPERTY))
      );
    },
    submitSearch() {
      this.$router.push({ name: 'listproperties', query: { search: this.searchTerm } });
      this.showSearch = false;
      this.searchTerm = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .header__logo {
    background-color: var(--colour-primary);
    padding: $container-spacing-vertical $container-spacing-horizontal 0;

    .header__logo__svg {
      max-width: 50%;
      height: auto;
    }
  }

  .header__bar {
    .header__bar__user {
      padding: math.div($container-spacing-vertical, 1.5) $container-spacing-horizontal;
      color: var(--colour-secondary);
      background-color: var(--colour-primary);
    }

    .header__nav {
      background-color: var(--colour-secondary);
      padding: 9px 9px 3px 9px;
      display: flex;
      text-align: center;
      flex-wrap: wrap;

      .header__nav__item {
        border-radius: 8px;
        padding: 8px;
        background-color: var(--colour-primary);
        color: var(--colour-secondary);
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 6px;

        &:not(:last-child) {
          margin-right: 6px;
        }
      }
    }
  }

  .dashboard {
    position: absolute;
    right: $container-spacing-horizontal;
  }

  .search-input {
    flex: 1;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
  }

  .search-button {
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 4px 4px 0;
    font-size: 14px;
    cursor: pointer;

    /* Flex to centre text if the button is taller */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>