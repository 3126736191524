<template>
  <div>
    <FormSection title="15. Shared facilities" :complete="model.preliminary_information.progress[15]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">15.1</span>
              <span> {{ options.preliminary_information.questions["15_1"] }}</span>
            </p>
            <MultipleChoiceQuestion
              @input="$emit('updateModelLevel2', '15_1', 'yes', $event)"
              :options="shared_options.yes_no"
              :model="model.preliminary_information.questions['15_1']['yes']"
            />
            <div class="question-content" v-if="model.preliminary_information.questions['15_1']['yes'] == 1">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['15_1']['items']"
                :options="options.preliminary_information.options['15_1']"
                @update:modelValue="$emit('updateModelLevel2', '15_1', 'items', $event)"
              />
            </div>
            <div
              class="question-content"
              v-if="
                model.preliminary_information.questions['15_1']['yes'] == 1 &&
                model.preliminary_information.questions['15_1']['items'].includes(5)
              "
            >
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                :modelValue="model.preliminary_information.questions['15_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '15_1', 'details', $event)"
                type="text"
                placeholder="Enter details"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import MultipleChoiceQuestion from "@/components/Forms/MultipleChoiceQuestion.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
    MultipleChoiceQuestion,
  },
});
</script>
