import { StoreOptions, createStore } from "vuex";
import * as user from "@/store/modules/user.js";
import * as options from "@/store/modules/options.js";
import * as property from "@/store/modules/property";
import { RootState } from "./types";

const themeIds = {
  hop: "h",
  wiggywam: "w",
  lawsociety: "h",
  canopy: "c",
  knightfrank: "k",
};

const store: StoreOptions<RootState> = {
  strict: true,
  modules: {
    user,
    options,
    property,
  },
  state: {
    ui: {
      loading: false,
      backgroundLoading: false,
      connectionProblem: false,
      touched: false,
      appLoaded: false,
      opaqueLoader: true,
      showAlert: false,
      alertText: "",
      theme: "hop",
      themeId: "h",
      whiteLabel: false,
      fireworks: {
        show: false,
        present: false,
      },
    },
  },
  mutations: {
    UPDATE_SHOW_ALERT(state, payload: { show: boolean; text: string | undefined }) {
      state.ui.showAlert = payload.show;
      if (payload.text) {
        state.ui.alertText = payload.text;
      }
    },
    UPDATE_LOADING(state, payload) {
      state.ui.loading = payload;
    },
    UPDATE_BACKGROUND_LOADING(state, payload) {
      state.ui.backgroundLoading = payload;
    },
    UPDATE_CONNECTION_PROBLEM(state, payload) {
      state.ui.connectionProblem = payload;
    },
    UPDATE_TOUCHED(state, payload) {
      state.ui.touched = payload;
    },
    UPDATE_APP_LOADED(state, payload) {
      state.ui.appLoaded = payload;
    },
    UPDATE_OPAQUE_LOADER(state, payload) {
      state.ui.opaqueLoader = payload;
    },
    UPDATE_FIREWORKS_SHOW(state, payload) {
      state.ui.fireworks.show = payload;
    },
    UPDATE_FIREWORKS_PRESENT(state, payload) {
      state.ui.fireworks.present = payload;
    },
    UPDATE_THEME(state, payload) {
      state.ui.theme = payload;
      state.ui.themeId = themeIds[state.ui.theme];
      if (state.ui.theme != "hop") {
        state.ui.whiteLabel = true;
      }
    },
  },
  actions: {
    showAlert({ commit }, payload) {
      commit("UPDATE_SHOW_ALERT", { show: false });
      commit("UPDATE_SHOW_ALERT", { show: true, text: payload });
      setTimeout(() => {
        commit("UPDATE_SHOW_ALERT", { show: false });
      }, 3000);
    },
    appIsLoaded({ commit }, payload) {
      commit("UPDATE_APP_LOADED", payload);
      if (payload) {
        setTimeout(() => {
          commit("UPDATE_OPAQUE_LOADER", false);
        }, 500);
      }
    },
    updateLoading({ commit }, payload) {
      if (payload) {
        commit("UPDATE_LOADING", true);
      } else {
        commit("UPDATE_LOADING", false);
      }
    },
    updateBackgroundLoading({ commit }, payload) {
      if (payload) {
        commit("UPDATE_BACKGROUND_LOADING", true);
      } else {
        setTimeout(() => {
          commit("UPDATE_BACKGROUND_LOADING", false);
        }, 1000);
      }
    },
    connectionProblem({ commit }, payload) {
      if (payload) {
        commit("UPDATE_CONNECTION_PROBLEM", true);
      } else {
        commit("UPDATE_CONNECTION_PROBLEM", false);
      }
    },
    setTouched({ commit }, payload) {
      if (payload) {
        commit("UPDATE_TOUCHED", true);
      } else {
        commit("UPDATE_TOUCHED", false);
      }
    },
    showFireworks({ commit }) {
      commit("UPDATE_FIREWORKS_SHOW", true);
      commit("UPDATE_FIREWORKS_PRESENT", true);
      setTimeout(() => {
        commit("UPDATE_FIREWORKS_SHOW", false);
        setTimeout(() => {
          commit("UPDATE_FIREWORKS_PRESENT", false);
        }, 1100);
      }, 3000);
    },
  },
  getters: {
    getTouched(state) {
      return state.ui.touched;
    },
  },
};

export default createStore(store);
