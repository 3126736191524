<template>
  <div>
    <h1>{{ title() }}</h1>
    <p class="note">
      {{ explanation() }}
    </p>
    <div v-if="showSuccess" class="note success">Message sent successfully</div>
    <div v-if="showFailure" class="note failure">Message sending failed.</div>
    <BaseTextArea v-if="!showSuccess" rows="8" v-model="message" />
    <BaseButton v-if="!showSuccess" @click="sendEnquiry"> Send </BaseButton>
    <BaseButton @click="$router.push({ name: 'dashboard' })"> Return to Control Panel </BaseButton>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";

export default {
  data() {
    return {
      message: "",
      showSuccess: false,
      showFailure: false,
    };
  },
  methods: {
    title() {
      if (this.$router.currentRoute.value.name == "enquiry") {
        return "Find out more";
      } else if (this.$router.currentRoute.value.name == "help") {
        return "Help";
      }
    },
    explanation() {
      if (this.$router.currentRoute.value.name == "enquiry") {
        return "Enter your enquiry below, and we will get in touch with you as soon as we can.";
      } else if (this.$router.currentRoute.value.name == "help") {
        return "If you have a question or a problem, drop us a line.";
      }
    },
    sendEnquiry() {
      this.$store.dispatch("updateLoading", true);
      HopService.sendEnquiry(this.$store.state["property"].property.id, this.$store.state["user"].user.displayName, this.message)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          // console.log(error);
          this.showFailure = true;
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
  mounted() {
    this.$store.dispatch("updateCurrentProperty", this.$router.currentRoute.value.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
