export const mutations = {
  SET_UPLOADS(state, payload) {
    state.property.data.uploads[payload.category] = payload.value;
  },
  ADD_UPLOAD(state, payload) {
    if (!state.property.data.uploads[payload.category]) {
      state.property.data.uploads[payload.category] = [];
    }
    state.property.data.uploads[payload.category].push({
      fileName: payload.file.fileName,
      name: payload.file.originalFileName,
    });
  },
  REMOVE_UPLOAD(state, payload) {
    state.property.data.uploads[payload.category].splice(payload.index, 1);
  },
  // ADD_UPLOAD(state, payload) {
  //   if (payload.section == "property_information" || payload.section == "preliminary_information") {
  //     if (state.property.data.conveyancing_forms[payload.section].questions[payload.question].files == undefined) {
  //       state.property.data.conveyancing_forms[payload.section].questions[payload.question].files = [];
  //     }
  //     state.property.data.conveyancing_forms[payload.section].questions[payload.question].files.push({
  //       fileName: payload.fileName,
  //       originalFileName: payload.originalFileName,
  //     });
  //   } else if (payload.section == "sellers_questions") {
  //     state.property.data.sellers_questions.files[payload.question].push({
  //       fileName: payload.fileName,
  //       originalFileName: payload.originalFileName,
  //     });
  //   }
  //   store.dispatch("setTouched", true);
  // },
  // REMOVE_UPLOAD(state, payload) {
  //   let model = [];
  //   if (payload.section == "property_information" || payload.section == "preliminary_information") {
  //     model = state.property.data.conveyancing_forms[payload.section].questions[payload.question].files;
  //   } else if (payload.section == "sellers_questions") {
  //     model = state.property.data.sellers_questions.files[payload.question];
  //   } else if (payload.section == "fittings_contents") {
  //     model =
  //       state.property.data.conveyancing_forms[payload.section].questions[payload.category].questions[payload.question].files;
  //   }
  //   let index = -1;
  //   _forEach(model, function (file, fileIndex) {
  //     if (file.fileName == payload.file) {
  //       index = fileIndex;
  //     }
  //   });
  //   if (index > -1) {
  //     model.splice(index, 1);
  //   }
  // },
};
export const actions = {
  // removeUpload({ commit, dispatch, state }, payload) {
  //   commit("UPDATE_LOADING", true);
  //   commit("REMOVE_UPLOAD", payload);
  //   // search property data for filename, halt if found elsewhere
  //   const data = JSON.stringify(state.property.data);
  //   const count = data.split(payload.file).length - 1;
  //   if (count > 0) {
  //     // The same file is elsewhere in the data, don't actually delete it
  //     commit("UPDATE_LOADING", false);
  //   } else {
  //     // It's the only one, delete it from the filesystem
  //     HopService.removeUpload(state.property.id, payload)
  //       .then(() => {
  //         commit("UPDATE_CONNECTION_PROBLEM", false);
  //         dispatch("savePropertyData");
  //         commit("UPDATE_LOADING", false);
  //       })
  //       .catch(() => {
  //         commit("UPDATE_LOADING", false);
  //         commit("UPDATE_CONNECTION_PROBLEM", true);
  //       });
  //   }
  //   if (payload.section == "property_information") {
  //     commit("UPDATE_PROPERTY_INFORMATION_PROGRESS", {
  //       questionKey: payload.question,
  //     });
  //   } else if (payload.section == "preliminary_information") {
  //     commit("UPDATE_PRELIMINARY_INFORMATION_PROGRESS", {
  //       questionKey: payload.question,
  //     });
  //   }
  // },
  // addUpload({ commit, dispatch }, payload) {
  //   commit("ADD_UPLOAD", payload);
  //   if (payload.section == "property_information") {
  //     commit("UPDATE_PROPERTY_INFORMATION_PROGRESS", {
  //       questionKey: payload.question,
  //     });
  //   } else if (payload.section == "sellers_questions") {
  //     dispatch("updateSellersQuestionsProgress");
  //   } else if (payload.section == "preliminary_information") {
  //     commit("UPDATE_PRELIMINARY_INFORMATION_PROGRESS", {
  //       questionKey: payload.question,
  //     });
  //   }
  // },
  setUploads({ commit }, payload) {
    // This is for property.data.uploads.*
    commit("SET_UPLOADS", payload);
  },
  addUpload({ commit, dispatch }, payload) {
    commit("ADD_UPLOAD", payload);
    dispatch("savePropertyDataImmediately");
  },
  removeUpload({ commit, dispatch }, payload) {
    commit("REMOVE_UPLOAD", payload);
    dispatch("savePropertyDataImmediately");
  },
};
export const state = {};
