<template>
  <div class="reports">
    <h1>
      <span v-if="hasNoPermissionTo(permissions.ACTION_VIEW_ALL_SOLICITORS)">{{ user.organisation.name }} </span>Individual
      Solicitors
    </h1>

    <table>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Firm</th>
        <th>Date registered</th>
        <th>Last activity</th>
      </tr>
      <tr v-for="solicitor in solicitors" :key="solicitor.id">
        <td>{{ solicitor.name }}</td>
        <td>{{ solicitor.email }}</td>
        <td>{{ solicitor.organisation_name }}</td>
        <td>{{ solicitor.date_registered }}</td>
        <td>
          {{
            solicitor.last_activity && new Date(solicitor.last_activity).getFullYear() != -1
              ? new Date(solicitor.last_activity)
              : "never"
          }}
        </td>
      </tr>
    </table>

    <div class="add">
      <BaseInput :small="true" type="Text" placeholder="Name" v-model="newName" />
      <BaseInput :small="true" type="Text" placeholder="Email" v-model="newEmail" />
      <BaseSelect initial="Firm" class="select" :options="firms" :value="selectedFirm" @input="updateSelectedFirm($event)" />
      <div v-if="showSuccess" class="note success">Added successfully.</div>
      <div v-if="showFailure" class="note failure">
        {{ failureMessage }}
      </div>
      <BaseButton @click="addSolicitor">Add new</BaseButton>
    </div>
  </div>
</template>

<script>
import Permissions from "@/helpers/permissions";
import HopService from "@/services/HopService.js";
import { defineComponent } from "vue";
import permissionsValues from "@/helpers/permissionsValues";

export default defineComponent({
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
    permissions() {
      return permissionsValues;
    },
    hasPermissionTo() {
      return new Permissions().hasPermissionTo;
    },
    hasNoPermissionTo() {
      return new Permissions().hasNoPermissionTo;
    },
  },
  data() {
    return {
      newEmail: "",
      newName: "",
      solicitors: [],
      firms: [],
      selectedFirm: 0,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$store.dispatch("updateLoading", true);
      var ctx = this;

      HopService.getSolicitors()
        .then((response) => {
          this.solicitors = response.data;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.failureMessage = "Failed to fetch firms";
          this.$store.dispatch("updateLoading", false);
        });

      // Get the firms list
      HopService.getSolicitorsFirms()
        .then((response) => {
          ctx.firms = [];
          response.data.forEach((element) => {
            ctx.firms.push({
              value: element.id,
              label: element.name,
            });
          });
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage = "Failed to fetch firms";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
    updateSelectedFirm(value) {
      this.selectedFirm = value;
    },
    addSolicitor() {
      this.$store.dispatch("updateLoading", true);
      HopService.addSolicitor(this.newName, this.newEmail, this.selectedFirm)
        .then(() => {
          this.showSuccess = true;
          this.showFailure = false;
          this.updateData();
          this.newEmail = "";
          this.newName = "";
        })
        .catch(() => {
          this.showFailure = true;
          this.failureMessage =
            "Failed to add new solicitor. Make sure to enter a valid email address and name, and select a firm to assign the solicitor to.";
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.reports {
  table {
    tr {
      &.brand {
        background-color: $colour-light-green;
      }

      &.admin {
        background-color: $colour-light-blue;
      }

      &.simple {
        background-color: $colour-error-red-border;
      }
    }
  }
}
</style>
