<template>
  <div>
    <FormSection title="14. Services" :complete="model.preliminary_information.progress[14]" :bottomBorder="true">
      <form class="questions">
        <Carousel ref="carousel">
          <div class="question">
            <p class="question-title">
              <span class="important">14.1</span>
              <span> {{ options.preliminary_information.questions["14_1"] }}</span>
            </p>
            <div class="question-content">
              <BaseMultiSelect
                :modelValue="model.preliminary_information.questions['14_1']['items']"
                :options="options.preliminary_information.options['14_1']"
                @update:modelValue="$emit('updateModelLevel2', '14_1', 'items', $event)"
              />
            </div>
            <div class="question-content" v-if="model.preliminary_information.questions['14_1']['items'].includes(9)">
              <BaseTextArea
                rows="4"
                :maxlength="5000"
                placeholder="More details"
                :modelValue="model.preliminary_information.questions['14_1']['details']"
                @update:modelValue="$emit('updateModelLevel2', '14_1', 'details', $event)"
              />
            </div>
          </div>
        </Carousel>
      </form>
    </FormSection>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import FormSection from "@/components/FormSection.vue";
import Carousel from "@/components/Carousel.vue";
import ConveyancingFormQuestionBase from "@/mixins/ConveyancingFormQuestionBase";

export default defineComponent({
  mixins: [ConveyancingFormQuestionBase],
  components: {
    FormSection,
    Carousel,
  },
});
</script>
