<template>
  <div class="reports">
    <h1>Brands</h1>

    <table>
      <tr>
        <th>Name</th>
        <th>Number of agencies</th>
        <th>Credits remaining</th>
      </tr>
      <tr v-for="brand in brands" :key="brand.id">
        <td>{{ brand.name }}</td>
        <td>{{ brand.num_agencies }}</td>
        <td>{{ brand.credits == "-1" ? "n/a" : brand.credits }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import HopService from "@/services/HopService.js";

export default {
  components: {},
  computed: {
    user() {
      return this.$store.state["user"].user;
    },
  },
  data() {
    return {
      newName: "",
      brands: [],
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      this.$store.dispatch("updateLoading", true);

      HopService.getBrands()
        .then((response) => {
          this.brands = response.data;
          this.showSuccess = true;
          this.showFailure = false;
          this.$store.dispatch("updateLoading", false);
        })
        .catch(() => {
          this.showFailure = true;
          this.showSuccess = false;
          this.$store.dispatch("updateLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
