import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2bf051dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "answer-heading" }
const _hoisted_2 = { class: "important" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_Question = _resolveComponent("Question")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answersList, (answers, answersKey) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "answer",
        key: answersKey
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(answersKey + 1) + ". ", 1),
          (_ctx.answersList.length != 1)
            ? (_openBlock(), _createBlock(_component_BaseButton, {
                key: 0,
                small: true,
                onClick: ($event: any) => (_ctx.removeAnswer(answersKey))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Delete ")
                ]),
                _: 2
              }, 1032, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (question, questionKey) => {
          return (_openBlock(), _createBlock(_component_Question, {
            propertyId: 4,
            key: answersKey + questionKey,
            answer: answers[questionKey],
            question: question,
            onInput: (event) => _ctx.updateAnswer(answersKey, questionKey, event)
          }, null, 8, ["answer", "question", "onInput"]))
        }), 128))
      ]))
    }), 128)),
    _createVNode(_component_BaseButton, {
      small: true,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addAnswer()))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add another ")
      ]),
      _: 1
    })
  ]))
}